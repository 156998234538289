import { useTranslation } from 'react-i18next';

import { Button } from '@/component-library/primitives/Button/Button';

const SubscriptionPreferencesSection = () => {
  const { t } = useTranslation();
  return (
    <div
      className="flex flex-col justify-center gap-y-5 lg:p-8 3xl:p-10 w-full rounded-[10px] bg-cover"
      style={{ backgroundImage: `url('/images/subscriptionPreferenceGradient.png')` }}
    >
      <div className="flex flex-col justify-center gap-2.5">
        <h2 className="text-base font-semibold text-white text-center tracking-[0.32px]">
          {`${t('MemberProfilePage.subscriptionPreferencesTitle')} 🚀`}
        </h2>
        <p className="text-xs font-normal text-white text-center tracking-[0.24px]">
          {t('MemberProfilePage.subscriptionPreferencesDescription')}
        </p>
      </div>
      <Button className="w-full">{t('MemberProfilePage.buttonSubscriptionCenter')}</Button>
    </div>
  );
};

export default SubscriptionPreferencesSection;
