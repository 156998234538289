import Chip from '@/component-library/primitives/Chip/Chip';

interface FormSelectorProps {
  text: string;
  image: string;
  type: string;
  isSelected: boolean;
  onSelect?: (option: string) => void;
}

const FormSelector: React.FC<FormSelectorProps> = ({ text, image, isSelected, onSelect, type }) => {
  const handleSelect = () => {
    if (onSelect) {
      onSelect(type);
    }
  };

  return (
    <label
      onClick={handleSelect}
      className={`flex items-center transition duration-300 rounded-lg cursor-pointer ${
        isSelected ? 'border-solid border !border-primary-400 shadow-[0px_0px_13px_0px_#82E6B8]' : ''
      }`}
    >
      <img className="w-9" src={image} alt="apple" />
      <Chip label={text} className="bg-gray-700 h-9" />
    </label>
  );
};

export default FormSelector;
