import { SVGProps } from 'react';

interface SettingsFutureIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

function SettingsFutureIcon({ color = '#9BAFD0', ...props }: SettingsFutureIconProps) {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.33382 16.5634L13.884 13.9363C14.3698 13.6559 14.6122 13.5156 14.7888 13.3195C14.9451 13.1459 15.0632 12.9415 15.1354 12.7193C15.2168 12.4689 15.2168 12.189 15.2168 11.6307V6.36891C15.2168 5.81066 15.2168 5.53079 15.1354 5.28033C15.0632 5.05819 14.9451 4.85358 14.7888 4.68001C14.6129 4.48468 14.3711 4.34505 13.8895 4.06703L9.33301 1.43633C8.84727 1.15589 8.60489 1.01596 8.34668 0.961073C8.11822 0.912512 7.88202 0.912512 7.65356 0.961073C7.39536 1.01596 7.15216 1.15589 6.66642 1.43633L2.11536 4.06389C1.63019 4.344 1.38779 4.48395 1.21126 4.68001C1.05498 4.85358 0.936951 5.05819 0.864776 5.28033C0.783203 5.53138 0.783203 5.81197 0.783203 6.37286V11.627C0.783203 12.1879 0.783203 12.4683 0.864776 12.7193C0.936951 12.9415 1.05498 13.1459 1.21126 13.3195C1.3879 13.5156 1.63047 13.6559 2.11621 13.9363L6.66642 16.5634C7.15215 16.8438 7.39536 16.9838 7.65356 17.0387C7.88202 17.0873 8.11822 17.0873 8.34668 17.0387C8.60488 16.9838 8.84809 16.8438 9.33382 16.5634Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 8.99981C5.5 10.3805 6.61929 11.4998 8 11.4998C9.38071 11.4998 10.5 10.3805 10.5 8.99981C10.5 7.6191 9.38071 6.49981 8 6.49981C6.61929 6.49981 5.5 7.6191 5.5 8.99981Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SettingsFutureIcon;
