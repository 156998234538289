const BatteryIcon = () => {
  return (
    <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 5C24.5523 5 25 5.44772 25 6V8C25 8.55228 24.5523 9 24 9V5Z" fill="black" fillOpacity="0.4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 1H20C21.6569 1 23 2.34315 23 4V10C23 11.6569 21.6569 13 20 13H3C1.34315 13 0 11.6569 0 10V4C0 2.34315 1.34315 1 3 1ZM3 2C1.89543 2 1 2.89543 1 4V10C1 11.1046 1.89543 12 3 12H20C21.1046 12 22 11.1046 22 10V4C22 2.89543 21.1046 2 20 2H3Z"
        fill="black"
        fillOpacity="0.4"
      />
      <rect x="2" y="3" width="19" height="8" rx="1" fill="#272933" />
    </svg>
  );
};

export default BatteryIcon;
