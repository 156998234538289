import { SVGProps } from 'react';

interface FilterIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const FilterIcon: React.FC<FilterIconProps> = ({ color = '#F5F7FC', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.6668 4.66674C16.6668 4.20003 16.6665 3.9665 16.5757 3.78824C16.4958 3.63144 16.3688 3.50405 16.212 3.42416C16.0337 3.33333 15.7999 3.33333 15.3332 3.33333H4.6665C4.19979 3.33333 3.96667 3.33333 3.78841 3.42416C3.63161 3.50405 3.50422 3.63144 3.42432 3.78824C3.3335 3.9665 3.3335 4.20003 3.3335 4.66674V5.28112C3.3335 5.48495 3.3335 5.58694 3.35652 5.68285C3.37694 5.76788 3.41069 5.8491 3.45638 5.92366C3.5079 6.00773 3.58009 6.07992 3.72412 6.22395L7.94307 10.4429C8.08719 10.587 8.15886 10.6587 8.21039 10.7428C8.25609 10.8173 8.2903 10.8989 8.31071 10.9839C8.3335 11.0788 8.3335 11.1796 8.3335 11.3793V15.3425C8.3335 16.0568 8.3335 16.4142 8.48395 16.6293C8.61532 16.8172 8.818 16.9425 9.04476 16.976C9.30445 17.0144 9.62409 16.8548 10.263 16.5353L10.9297 16.202C11.1972 16.0682 11.3307 16.0011 11.4284 15.9013C11.5149 15.813 11.581 15.7071 11.6213 15.5903C11.6668 15.4582 11.6668 15.3083 11.6668 15.0092V11.3855C11.6668 11.1817 11.6668 11.0798 11.6899 10.9839C11.7103 10.8989 11.744 10.8173 11.7897 10.7428C11.8409 10.6593 11.9124 10.5878 12.0546 10.4456L12.0575 10.4429L16.2764 6.22395C16.4205 6.07983 16.4922 6.00776 16.5437 5.92366C16.5894 5.8491 16.6236 5.76788 16.644 5.68285C16.6668 5.58792 16.6668 5.48703 16.6668 5.28733V4.66674Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterIcon;
