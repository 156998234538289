import { useMutation } from 'react-query';
import axios from 'axios';

import { CreateDomainRequest } from '@/models/Domain';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function createDomain(data: CreateDomainRequest, token: string | undefined) {
  const response = await axios.post<CreateDomainRequest>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/workspaces/${data.workspaceId}/domains`,
    data,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useCreateDomain() {
  const { user } = useAuth();
  return useMutation<CreateDomainRequest, Error, CreateDomainRequest>((data) =>
    createDomain(data, user?.signInUserSession.idToken.jwtToken),
  );
}
