import React, { ChangeEventHandler, forwardRef, useEffect, useState } from 'react';

import CircleWarningIcon from '@/component-library/primitives/Icons/CircleWarningIcon/CircleWarningIcon';
import CloudUploadIcon from '@/component-library/primitives/Icons/CloudUploadIcon/CloudUploadIcon';
import UploadIcon from '@/component-library/primitives/Icons/UploadIcon/UploadIcon';

interface SimpleFileUploadProps {
  label?: string;
  fileUploadLabel: string;
  accept: string;
  error?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  defaultValue?: File | null;
}

const SimpleFileUpload = forwardRef<HTMLInputElement, SimpleFileUploadProps>((props, ref) => {
  const { label, fileUploadLabel, accept, error, onChange, defaultValue, ...rest } = props;
  const [selectedFile, setSelectedFile] = useState<File | null>(defaultValue || null);
  const [previewURL, setPreviewURL] = useState<string | null>(null);

  useEffect(() => {
    if (defaultValue instanceof FileList && defaultValue.length > 0) {
      const file = defaultValue[0];
      setSelectedFile(file || null);

      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setPreviewURL(reader.result as string);
        };
      }
    }
  }, [defaultValue]);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setPreviewURL(reader.result as string);
        };
      }
    }

    if (file && onChange) {
      onChange(e);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setPreviewURL(reader.result as string);
        };
      }
    }
  };

  return (
    <div>
      <span className="text-gray-100 text-xs font-medium mb-2">Upload file</span>
      <label
        htmlFor="fileInput"
        className={`border-[1.5px] border-transparent h-40 ${
          error
            ? 'border-errorRed-500'
            : 'bg-importMembersField bg-import-field-size bg-import-field-position bg-no-repeat'
        } flex items-center justify-center gap-2 relative bg-darkBlue rounded-md py-2 px-4 text-custom-aliceBlue font-light focus:outline-none focus:ring-blue-500 cursor-pointer`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {previewURL ? (
          <>
            <img src={previewURL} alt="Selected Preview" className="max-h-40 mb-2" />
            <p className="text-center">{selectedFile?.name}</p>
          </>
        ) : (
          <>
            {selectedFile && selectedFile instanceof File ? (
              <p className="text-center">{selectedFile.name}</p>
            ) : (
              <div className="flex flex-col w-full items-center gap-y-2.5">
                <CloudUploadIcon />
                <p className="text-sm font-normal tracking-[0.28px] text-gray-500 mt-2">{fileUploadLabel}</p>
                <div className="flex flex-row gap-5 items-center">
                  <hr className="w-[7.875rem] border-gray-600" />
                  <span className="text-xs font-medium tracking-[0.24px] text-gray-600">or</span>
                  <hr className="w-[7.875rem] border-gray-600" />
                </div>
                <div className="flex flex-row items-center gap-3">
                  <UploadIcon color="#7898FB" />
                  <span className="text-sm font-medium tracking-[0.28px] text-secondary-400">Browse files</span>
                </div>
              </div>
            )}
          </>
        )}
        <input
          type="file"
          id="fileInput"
          accept={accept}
          className="hidden"
          ref={ref}
          onChange={handleFileSelect}
          {...rest}
        />
      </label>
      {error && (
        <p className="text-errorRed-500 mt-2 text-xs font-medium flex flex-row items-center gap-2">
          <CircleWarningIcon /> {error}
        </p>
      )}
    </div>
  );
});
export default SimpleFileUpload;
