import { SVGProps } from 'react';

interface ArrowSubDownRightIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const ArrowSubDownRightIcon: React.FC<ArrowSubDownRightIconProps> = ({ color = '#9BAFD0', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M13 11L18 16M18 16L13 21M18 16H10.1969C9.07899 16 8.5192 16 8.0918 15.7822C7.71547 15.5905 7.40973 15.2839 7.21799 14.9076C7 14.4798 7 13.9201 7 12.8V3"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowSubDownRightIcon;
