import { SVGProps } from 'react';

interface LoadingSpinnerProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

function LoadingSpinnerIcon({ color = '#2F6A4F', ...props }: LoadingSpinnerProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className="animate-spin"
    >
      <path
        d="M20.5 12C20.5 15.8343 17.8026 19.0385 14.2018 19.8186C13.2765 20.0191 12.5 20.7675 12.5 21.7143C12.5 22.9767 13.533 24.0227 14.7728 23.7851C20.3134 22.7229 24.5 17.8503 24.5 12C24.5 5.37258 19.1274 0 12.5 0C5.87258 0 0.5 5.37258 0.5 12H4.5C4.5 7.58172 8.08172 4 12.5 4C16.9183 4 20.5 7.58172 20.5 12Z"
        fill="url(#paint0_angular_519_4412)"
      />
      <defs>
        <radialGradient
          id="paint0_angular_519_4412"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.5 12) rotate(90) scale(12)"
        >
          <stop offset="0.244792" stopOpacity="0" />
          <stop offset="1" stopColor={color} />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default LoadingSpinnerIcon;
