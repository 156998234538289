import { useMutation } from 'react-query';
import axios from 'axios';

import { CreatePushServiceDetails } from '@/models/PushNotificationDetails';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function createPushNotificationConfig(data: CreatePushServiceDetails, token: string | undefined) {
  const response = await axios.post<CreatePushServiceDetails>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/notifications/push/config`,
    data,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useCreatePushNotificationConfig() {
  const { user } = useAuth();
  return useMutation<CreatePushServiceDetails, Error, CreatePushServiceDetails>((data) =>
    createPushNotificationConfig(data, user?.signInUserSession.idToken.jwtToken),
  );
}
