import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useCreateInquiry } from '@/api/contact/useCreateInquiry';
import { Button } from '@/component-library/primitives/Button/Button';
import CircleInfoIcon from '@/component-library/primitives/Icons/CircleInfoIcon/CircleInfoIcon';
import FacebookIcon from '@/component-library/primitives/Icons/FacebookIcon/FacebookIcon';
import InstagramIcon from '@/component-library/primitives/Icons/InstagramIcon/InstagramIcon';
import LinkedinIcon from '@/component-library/primitives/Icons/LinkedinIcon/LinkedinIcon';
import LogoIcon from '@/component-library/primitives/Icons/LogoIcon/LogoIcon';
import { Textarea } from '@/component-library/primitives/Textarea/Textarea';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import { Input } from '@/component-library/widgets/Input/Input';
import SelectField from '@/component-library/widgets/SelectField/SelectField';
import { CreateInquiry, CreateInquiryRequest } from '@/models/Inquiry';

export const ContactPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const createInquiry = useCreateInquiry();
  const [isRegisterReasonSelected, setIsRegisterReasonSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const schema = useMemo(() => {
    return yup.object().shape({
      firstName: yup.string().required(t('CommonErrors.firstNameRequired')),
      lastName: yup.string().required(t('CommonErrors.lastNameRequired')),
      email: yup.string().email(t('CommonErrors.emailFormatValidation')).required(t('CommonErrors.emailRequired')),
      reason: yup.object().required(t('CommonErrors.pleaseSpecifyReason')),
      text: yup.string(),
    });
  }, []);

  const optionsForHandlingEmptyValues = [
    { label: 'I want to register', value: 'Registration' },
    { label: 'I want to book a demonstration', value: 'Demo' },
    { label: 'I have questions', value: 'Question' },
  ];

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<CreateInquiry>({
    resolver: yupResolver(schema),
  });

  const watchReason = watch('reason');

  useEffect(() => {
    if (watchReason?.label === 'I want to register') {
      setIsRegisterReasonSelected(true);
    } else {
      setIsRegisterReasonSelected(false);
    }
  }, [watchReason]);

  useEffect(() => {
    let timerBeforeNavigation: NodeJS.Timeout;
    if (toastInfo.toastType === 'success') {
      timerBeforeNavigation = setTimeout(() => navigate('/'), 2000);
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, toastInfo]);

  const onSubmit = (data: CreateInquiry) => {
    const inquiryData = {
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      email: data.email.trim(),
      reason: data.reason.value,
      text: data.text,
    };
    createInquiry.mutate(inquiryData as CreateInquiryRequest, {
      onSuccess: () => {
        setToastInfo({ message: t('ContactPage.toastSuccess'), toastType: 'success' });
        setOpen(true);
      },
      onError: () => {
        setToastInfo({ message: t('ContactPage.toastError'), toastType: 'error' });
        setOpen(true);
      },
    });
  };

  return (
    <div className="flex flex-col items-center h-screen bg-gray-900 overflow-y-auto">
      <div className="cursor-pointer mt-10 mb-10" onClick={() => navigate('/')}>
        <LogoIcon withText />
      </div>
      <h1 className="text-gray-50 text-3xl font-semibold ">{t('ContactPage.subtitle')}</h1>
      <div className="block sm:flex px-8 py-12 max-w-full sm:max-w-[75%] m-auto lg:mt-2 3xl:mt-12 gap-x-24">
        <div className="w-full sm:w-1/2 px-4 mt-10">
          <form onSubmit={handleSubmit(onSubmit)} className="w-[510px]">
            <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
                <Input
                  {...register('firstName')}
                  error={errors.firstName?.message as string}
                  placeholder={t('ContactPage.firstNamePlaceholder')}
                  label={t('ContactPage.firstNameLabel')}
                />
              </div>
              <div className="w-1/2">
                <Input
                  {...register('lastName')}
                  error={errors.lastName?.message as string}
                  placeholder={t('ContactPage.lastNamePlaceholder')}
                  label={t('ContactPage.lastNameLabel')}
                />
              </div>
            </div>
            <Input
              {...register('email')}
              error={errors.email?.message as string}
              placeholder={t('ContactPage.emailPlaceholder')}
              label={t('ContactPage.emailLabel')}
            />
            <div className="mt-4 mb-4">
              <SelectField
                name="reason"
                label={t('ContactPage.whyContactUsLabel')}
                control={control}
                options={optionsForHandlingEmptyValues}
                errorMsg={errors.reason?.message as string}
                className={'mb-2'}
                isSearchable={false}
              />
              {isRegisterReasonSelected && (
                <span className="flex space-x-[6px] items-start">
                  <CircleInfoIcon color="#BFB804" />
                  <span className={`text-xs text-yellow-500 font-normal tracking-[0.24px]`}>
                    {t('ContactPage.sameEmailHasToBeUsedMsg')}{' '}
                  </span>
                </span>
              )}
            </div>
            <div className="mb-8">
              <Textarea
                {...register('text')}
                placeholder={t('ContactPage.anythingElsePlaceholder')}
                label={t('ContactPage.anythingElseLabel')}
                optional={true}
              />
            </div>
            <Button className="w-full" isLoading={createInquiry.isLoading || createInquiry.isSuccess}>
              {t('ContactPage.buttonSubmit')}
            </Button>
          </form>
        </div>
        <div className="w-full sm:w-1/2 px-4 mt-10">
          <p className="text-gray-50 w-[90%] font-normal mb-6 text-xl">{t('ContactPage.descriptionFirstPart')}</p>
          <p className="text-gray-50 w-[90%] font-normal mb-6 text-xl">{t('ContactPage.descriptionSecondPart')}</p>
          <div className="bg-[#272933] py-12 px-12 rounded-2xl h-fit w-fit text-white">
            <p className="text-xl font-semibold mb-6 capitalize">{t('ContactPage.title')}</p>
            <div className="space-y-2">
              <p>Blackfield Labs doo</p>
              <p>Petra Drapsina 36</p>
              <p>21000 Novi Sad</p>
              <p>
                <a
                  className="cursor-pointer no-underline text-secondary-300"
                  href="mailto:marketing@blackfieldlabs.com"
                >
                  marketing@blackfieldlabs.com
                </a>
              </p>
            </div>
            <div className="flex flex-row items-center gap-x-4 mt-8">
              <a
                href="https://www.linkedin.com/products/blackfield-labs-cyberravenio/"
                target="_blank"
                className="group"
                rel="noreferrer"
              >
                <span className="group-hover:[&>*_path]:fill-secondary-300">
                  <LinkedinIcon />
                </span>
              </a>
              <a href="https://www.instagram.com/cyberraven.io/" target="_blank" className="group" rel="noreferrer">
                <span className="group-hover:[&>*_path]:fill-secondary-300">
                  <InstagramIcon />
                </span>
              </a>
              <a href="https://www.facebook.com/cyberraven.io" target="_blank" className="group" rel="noreferrer">
                <span className="group-hover:[&>*_path]:fill-secondary-300">
                  <FacebookIcon />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <footer className="text-gray-300 text-center mb-4">{t('HomePage.copyright')}</footer>
      <ProvideToasts>
        <Toast
          isOpen={open}
          setOpen={setOpen}
          message={toastInfo.message}
          toastType={toastInfo.toastType as ToastType}
        />
      </ProvideToasts>
    </div>
  );
};
