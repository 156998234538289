import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useGetSegments } from '@/api/segments/useGetSegments';
import { Button } from '@/component-library/primitives/Button/Button';
import Chip from '@/component-library/primitives/Chip/Chip';
import ChevronIcon from '@/component-library/primitives/Icons/ChevronIcon/ChevronIcon';
import Link from '@/component-library/primitives/Link/Link';
import OptionPicker from '@/component-library/primitives/OptionPicker/OptionPicker';
import Stepper from '@/component-library/primitives/Stepper/Stepper';
import { Textarea } from '@/component-library/primitives/Textarea/Textarea';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import Diagram from '@/component-library/widgets/Diagram/Diagram';
import { Input } from '@/component-library/widgets/Input/Input';
import SelectField from '@/component-library/widgets/SelectField/SelectField';
import { CampaignDetails } from '@/models/Campaigns';
import CreateCampaignLastStep from '@/modules/Dashboard/Campaigns/CreateCampaign/CreateCampaignLastStep';
import { AppRoutes } from '@/utils/routes/router';

import 'reactflow/dist/style.css';

const CreateCampaign = () => {
  const { data: segmentData } = useGetSegments();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const [activeStep, setActiveStep] = useState(0);
  const [showConfigureTrigger, setShowConfigureTrigger] = useState(false);
  const [allSubscribedPeopleIsActive, setAllSubscribedPeopleIsActive] = useState(true);
  const [allSubscribedAndUnsubscribedPeopleIsActive, setAllSubscribedAndUnsubscribedPeopleIsActive] = useState(false);
  const [goalIsActive, setGoalIsActive] = useState(true);
  const [noGoalIsActive, setNoGoalIsActive] = useState(false);
  const [entersSegmentIsActive, setEntersSegmentIsActive] = useState(true);
  const [leavesSegmentIsActive, setLeavesSegmentIsActive] = useState(false);
  const [achieveGoalIsAactive, setAchieveTheGoalIsActive] = useState(true);
  const [stopMatchingTriggerIsActive, setStopMatchingTriggerIsActive] = useState(false);
  const [achieveGoalOrStopMatchingTriggerIsActive, setAchieveGoalOrStopMatchingTriggerIsActive] = useState(false);
  const [peopleDontExitEarlyIsActive, setPeopleDontExitEarlyIsActive] = useState(false);

  const timeOptions = [
    { text: t('CreateCampaignPage.daysTimeOption') },
    { text: t('CreateCampaignPage.weeksTimeOption') },
    { text: t('CreateCampaignPage.monthsTimeOption') },
    { text: t('CreateCampaignPage.yearsTimeOption') },
  ];
  const [selectedTimeOption, setSelectedTimeOption] = useState(timeOptions[0]);

  const actionOptions = [
    { text: t('CreateCampaignPage.openingActionOption') },
    { text: t('CreateCampaignPage.receivingActionOption') },
    { text: t('CreateCampaignPage.trackedLinkActionOption') },
  ];
  const [selectedActionOption, setSelectedActionOption] = useState(actionOptions[0]);

  const steps = [
    t('CreateCampaignPage.infoStep'),
    t('CreateCampaignPage.triggersStep'),
    t('CreateCampaignPage.settingsStep'),
    t('CreateCampaignPage.goalAndExitStep'),
    t('CreateCampaignPage.workflowStep'),
    t('CreateCampaignPage.reviewStep'),
  ];

  const triggers = [
    {
      triggerTitle: t('CreateCampaignPage.whenSomeonePerformsOnEventTriggerTitle'),
      triggerShortDescription: t('CreateCampaignPage.whenSomeonePerformsOnEventDescription'),
      triggerLongDescriptionTitle: t('CreateCampaignPage.eventTriggeredCampaignsLabel'),
      triggerLongDescriptionText: t('CreateCampaignPage.eventTriggeredCampaignsDescription'),
    },
  ];

  const [selectedTrigger, setSelectedTrigger] = useState(triggers[0]);

  const availableSegments = useMemo(
    () =>
      segmentData?.map((segment) => {
        return {
          label: segment.name,
          value: segment.id,
        };
      }),
    [segmentData],
  );

  const messageNodes = [
    t('CreateCampaignPage.emailNodeLabel'),
    t('CreateCampaignPage.pushNotificationNodeLabel'),
    t('CreateCampaignPage.smsNodeLabel'),
  ];

  const dataNodes = [
    t('CreateCampaignPage.sendEventNodeLabel'),
    t('CreateCampaignPage.batchUpdateNodeLabel'),
    t('CreateCampaignPage.manualSegmentUpdateNodeLabel'),
    t('CreateCampaignPage.createOrUpdatePersonNodeLabel'),
    t('CreateCampaignPage.queryCollectionNodeLabel'),
  ];

  const delaysNodes = [
    t('CreateCampaignPage.waitUntilNodeLabel'),
    t('CreateCampaignPage.timeDelayNodeLabel'),
    t('CreateCampaignPage.timeWindowNodeLabel'),
  ];

  const flowControlNodes = [
    t('CreateCampaignPage.trueFalseBranchNodeLabel'),
    t('CreateCampaignPage.multiSplitBranchNodeLabel'),
    t('CreateCampaignPage.exitNodeLabel'),
  ];

  const onDragStart = (event: any, nodeName: string, color: string, emoji: string) => {
    event.dataTransfer.setData('reactflow-nodeName', nodeName);
    event.dataTransfer.setData('reactflow-color', color);
    event.dataTransfer.setData('reactflow-emoji', emoji);
    event.dataTransfer.effectAllowed = 'move';
  };

  useEffect(() => {
    let timerBeforeNavigation: NodeJS.Timeout;
    if (toastInfo.toastType === 'success') {
      timerBeforeNavigation = setTimeout(() => navigate(AppRoutes.campaigns), 2000);
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, toastInfo]);

  const schema = useMemo(() => {
    return [
      yup.object({
        name: yup
          .string()
          .trim()
          .required(t('CommonErrors.nameRequired'))
          .min(1, t('CreateCampaignPage.nameTooShort', { minLength: 1 }))
          .max(50, t('CreateCampaignPage.nameTooLong', { maxLength: 50 })),
        description: yup
          .string()
          .trim()
          .required(t('CommonErrors.descriptionRequired'))
          .min(1, t('CreateCampaignPage.descriptionTooShort', { minLength: 1 }))
          .max(250, t('CreateCampaignPage.descriptionTooLong', { maxLength: 250 })),
      }),
      yup.object({
        eventName:
          selectedTrigger.triggerTitle === t('CreateCampaignPage.whenSomeonePerformsOnEventTriggerTitle')
            ? yup.string().trim().required(t('CreateCampaignPage.eventNameRequired'))
            : yup.string(),
        shouldReceiveSegments: yup
          .array()
          .required(t('CreateCampaignPage.shouldReceiveSegmentsRequired'))
          .nullable()
          .min(1, t('CreateCampaignPage.shouldReceiveSegmentsRequired')),
      }),
      yup.object({}),
      yup.object({
        conversionDuration: goalIsActive
          ? yup
              .number()
              .min(1, t('CreateCampaignPage.minDurationError'))
              .max(99, t('CreateCampaignPage.maxDurationError'))
              .transform((value) => (isNaN(value) ? undefined : value))
              .required(t('CreateCampaignPage.durationRequired'))
          : yup.string(),
      }),
      yup.object({}),
      yup.object({}),
    ];
  }, [t, selectedTrigger, goalIsActive]);

  const currentValidationSchema = useMemo(() => schema[activeStep], [schema, activeStep]);

  const methods = useForm<CampaignDetails>({
    shouldUnregister: false,
    resolver: yupResolver(currentValidationSchema),
    mode: 'onChange',
  });

  const {
    control,
    trigger,
    register,
    formState: { errors },
  } = methods;

  const exitCondition = () => {
    if (achieveGoalIsAactive) {
      return t('CreateCampaignPage.theyAchieveTheGoalOption');
    } else if (stopMatchingTriggerIsActive) {
      return t('CreateCampaignPage.theyStopMatchingTriggerOption');
    } else if (achieveGoalOrStopMatchingTriggerIsActive) {
      return t('CreateCampaignPage.theyAchieveTheGoalOrStopMatchingTrigger');
    } else if (peopleDontExitEarlyIsActive) {
      return t('CreateCampaignPage.peopleDontExitEarly');
    }
  };

  const onBack = () => {
    activeStep === 0 ? navigate(AppRoutes.campaigns) : setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <>
      <div className="flex flex-col items-center mt-12">
        <div>
          <div className="position: relative mb-8">
            <div className="position: absolute top-0.5 -left-10 cursor-pointer" onClick={onBack}>
              <ChevronIcon type={'left'} width={'24'} height={'24'} viewBox={'0 0 24 24'} />
            </div>
            <h1 className="text-custom-aliceBlue font-semibold text-xl mb-3">{t('CreateCampaignPage.title')}</h1>
            <span className="text-custom-aliceBlue">{t('CreateCampaignPage.subtitle')}</span>
          </div>
          <div className="flex justify-center">
            <Stepper steps={steps} activeStep={activeStep} />
          </div>
          <FormProvider {...methods}>
            <form className="min-w-[50rem] mx-auto">
              {/* Info step */}
              {activeStep === 0 && (
                <div className="space-y-6">
                  <section className="space-y-4 rounded-lg text-custom-aliceBlue">
                    <Input
                      className="text-custom-aliceBlue border-gray-700 placeholder-custom-aliceBlue placeholder:font-semibold"
                      placeholder={t('CreateCampaignPage.namePlaceholder')}
                      {...register('name')}
                      error={errors.name?.message as string}
                    />
                  </section>
                  <section className="pr-6 pl-6 pb-8 pt-6 space-y-4 shadow rounded-lg bg-custom-gray text-custom-aliceBlue">
                    <h2 className="text-base font-semibold text-custom-aliceBlue flex items-center mb-8">
                      {t('CreateCampaignPage.descriptionLabel')}
                    </h2>
                    <Textarea
                      {...register('description')}
                      placeholder={t('CreateCampaignPage.descriptionPlaceholder')}
                      error={errors.description?.message}
                      className="overflow-hidden placeholder:font-normal"
                    />
                  </section>
                  <div className="flex justify-end">
                    <Button variant="cancel" onClick={() => navigate(AppRoutes.campaigns)}>
                      {t('CreateCampaignPage.linkCancel')}
                    </Button>
                    <Button type="button" className="ml-8" onClick={onNext}>
                      {t('CreateCampaignPage.buttonNext')}
                    </Button>
                  </div>
                </div>
              )}
              {/* Triggers step */}
              {activeStep === 1 && !showConfigureTrigger && (
                <div className="lg:w-[70rem] 3xl:w-[90rem] ">
                  <h1 className="text-custom-aliceBlue font-semibold text-xl mb-8">
                    {t('CreateCampaignPage.whatTriggersCampaignLabel')}
                  </h1>
                  <div className="flex flex-row lg:gap-x-28 3xl:gap-x-40">
                    <div className="flex flex-col flex-wrap gap-y-4">
                      {triggers.map((trigger: any, index: number) => (
                        <React.Fragment key={index}>
                          <section
                            className={`lg:w-[30rem] 3xl:w-[35rem] min-h-[6.43rem] px-3 pt-3 pb-4 shadow rounded-lg bg-custom-gray text-gray-200 cursor-pointer border border-transparent
                hover:!border-primary-400 hover:bg-settingsCard ${
                  selectedTrigger?.triggerTitle === trigger.triggerTitle
                    ? ' !border-primary-400 shadow-[0px_0px_13px_0px_#82E6B8]'
                    : ''
                }`}
                            onClick={() => {
                              setSelectedTrigger(trigger);
                            }}
                          >
                            <div className="flex flex-row items-start gap-4">
                              <div className="min-w-[4rem] w-16 h-16"></div>
                              <div className="flex flex-col">
                                <h2 className="text-base flex items-center mb-4 font-semibold text-custom-aliceBlue">
                                  {trigger.triggerTitle}
                                </h2>
                                <p className="leading-5">{trigger.triggerShortDescription}</p>
                              </div>
                            </div>
                          </section>
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="flex flex-col flex-wrap gap-10">
                      <div className="space-y-6 lg:w-[35rem] 3xl:w-[41rem]">
                        <div className="flex flex-col">
                          <h1 className="font-semibold text-xl mb-8 text-custom-aliceBlue">
                            {selectedTrigger.triggerLongDescriptionTitle}
                          </h1>
                          <p className="leading-5 text-gray-200">{selectedTrigger.triggerLongDescriptionText}</p>
                          <p className="text-custom-aliceBlue font-semibold mt-6 mb-10">
                            {t('CreateCampaignPage.bestForThingsLikeLabel')}:
                          </p>
                          <div className="flex flex-row justify-start gap-14">
                            <div className="w-[10.563rem] h-[8.75rem] border rounded-2xl border-custom-aliceBlue"></div>
                            <div className="w-[10.563rem] h-[8.75rem] border rounded-2xl border-custom-aliceBlue"></div>
                            <div className="w-[10.563rem] h-[8.75rem] border rounded-2xl border-custom-aliceBlue"></div>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <Button type="button" variant="transparent">
                          {t('CreateCampaignPage.linkLearnMore')}
                        </Button>
                        <Button type="button" className="ml-8" onClick={() => setShowConfigureTrigger(true)}>
                          {t('CreateCampaignPage.configureTrigger')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeStep === 1 && showConfigureTrigger && (
                <div className="space-y-6">
                  <div>
                    <h1 className="text-custom-aliceBlue font-semibold text-xl mb-6">
                      {t('CreateCampaignPage.whatTriggersCampaignLabel')}
                    </h1>
                  </div>
                  <div>
                    <section className="p-6 space-y-4 shadow rounded-lg bg-custom-gray text-custom-aliceBlue">
                      <h2 className="text-base font-semibold flex items-center mb-4">{selectedTrigger.triggerTitle}</h2>
                      <p className="text-gray-200">{selectedTrigger.triggerShortDescription}</p>
                      {selectedTrigger.triggerTitle ===
                        t('CreateCampaignPage.whenSomeonePerformsOnEventTriggerTitle') && (
                        <Input
                          placeholder={t('CreateCampaignPage.eventNamePlaceholder')}
                          {...register('eventName')}
                          error={errors.eventName?.message as string}
                          className="border-gray-700"
                        />
                      )}
                    </section>
                    <div className="flex justify-end">
                      <Button variant="transparent" onClick={() => setShowConfigureTrigger(false)}>
                        {t('CreateCampaignPage.linkEditType')}
                      </Button>
                    </div>
                    <section className="px-6 pt-6 pb-8 space-y-4 shadow rounded-lg bg-custom-gray text-custom-aliceBlue">
                      <h2 className="text-base font-semibold flex items-center mb-4">
                        {t('CreateCampaignPage.segmentsLabel')}
                      </h2>
                      <SelectField
                        name="shouldReceiveSegments"
                        label={
                          <Trans
                            i18nKey="CreateCampaignPage.segmentsIncludedLabel"
                            components={{
                              primary: (
                                <span
                                  className={`${
                                    errors.shouldReceiveSegments?.message ? 'text-errorRed-500' : 'text-yellow-200'
                                  }`}
                                />
                              ),
                            }}
                          />
                        }
                        control={control}
                        options={availableSegments}
                        errorMsg={errors.shouldReceiveSegments?.message}
                        className={'mb-2'}
                        isMulti={true}
                      />
                      <div className="flex justify-end mt-4">
                        <Link to={AppRoutes.segments} label={`⚙️ ${t('CreateCampaignPage.linkManageConditions')}`} />
                      </div>
                    </section>
                  </div>
                  <div className="flex justify-end">
                    <Button variant="cancel" onClick={() => navigate(AppRoutes.campaigns)}>
                      {t('CreateCampaignPage.linkCancel')}
                    </Button>
                    <Button type="button" className="ml-8" onClick={onNext}>
                      {t('CreateCampaignPage.buttonNext')}
                    </Button>
                  </div>
                </div>
              )}
              {/* Settings step */}
              {activeStep === 2 && (
                <div className="space-y-6">
                  <div>
                    <h1 className="text-custom-aliceBlue font-semibold text-xl mb-4">
                      {t('CreateCampaignPage.messageSettingsLabel')}
                    </h1>
                    <p className="text-custom-aliceBlue mt-4 w-[85%]">
                      {t('CreateCampaignPage.chooseDefaultSettingsForMessagesLabel')}:
                    </p>
                  </div>
                  <section className="px-6 pt-6 pb-8 space-y-4 shadow rounded-lg bg-custom-gray text-custom-aliceBlue">
                    <h2 className="text-base font-semibold flex items-center">
                      {t('CreateCampaignPage.subscriptionPreferenceLabel')}
                    </h2>
                    <p className="text-custom-aliceBlue">{t('CreateCampaignPage.chooseSubscriptionPreference')}:</p>
                    <div className="flex flex-row gap-4">
                      <Chip
                        label={t('CreateCampaignPage.allSubscribedPeopleOption')}
                        variant={allSubscribedPeopleIsActive ? 'dark' : 'gray'}
                        className="hover:cursor-pointer"
                        onClick={() => {
                          setAllSubscribedPeopleIsActive(true);
                          setAllSubscribedAndUnsubscribedPeopleIsActive(false);
                        }}
                      />
                      <Chip
                        label={t('CreateCampaignPage.allSubscribedUnsubscribedPeopleOption')}
                        variant={allSubscribedAndUnsubscribedPeopleIsActive ? 'dark' : 'gray'}
                        className="hover:cursor-pointer"
                        onClick={() => {
                          setAllSubscribedAndUnsubscribedPeopleIsActive(true);
                          setAllSubscribedPeopleIsActive(false);
                        }}
                      />
                    </div>
                  </section>
                  <div className="flex justify-end">
                    <Button variant="cancel" onClick={() => navigate(AppRoutes.campaigns)}>
                      {t('CreateCampaignPage.linkCancel')}
                    </Button>
                    <Button type="button" className="ml-8" onClick={onNext}>
                      {t('CreateCampaignPage.buttonNext')}
                    </Button>
                  </div>
                </div>
              )}
              {/* Goal & Exit step */}
              {activeStep === 3 && (
                <div className="space-y-6 max-w-[50rem]">
                  <div>
                    <h1 className="text-custom-aliceBlue font-semibold text-xl mb-4">
                      {t('CreateCampaignPage.setGoalAndConversionCriteriaLabel')}
                    </h1>
                    <p className="text-custom-aliceBlue mt-4 w-[85%]">
                      {t('CreateCampaignPage.goalsAndConversionsDescription')}:
                    </p>
                  </div>
                  <section className="px-6 pt-6 pb-8 space-y-8 shadow rounded-lg bg-custom-gray text-custom-aliceBlue">
                    <div className="flex flex-row gap-4">
                      <Chip
                        label={t('CreateCampaignPage.setGoalOption')}
                        variant={goalIsActive ? 'dark' : 'gray'}
                        className="hover:cursor-pointer"
                        onClick={() => {
                          setGoalIsActive(true);
                          setNoGoalIsActive(false);
                        }}
                      />
                      <Chip
                        label={t('CreateCampaignPage.noGoalOption')}
                        variant={noGoalIsActive ? 'dark' : 'gray'}
                        className="hover:cursor-pointer"
                        onClick={() => {
                          setNoGoalIsActive(true);
                          setGoalIsActive(false);
                        }}
                      />
                    </div>
                    {goalIsActive && (
                      <>
                        <div>
                          <p className="text-custom-aliceBlue font-sem">{t('CreateCampaignPage.customerLabel')}</p>
                          <div className="flex flex-row gap-4 mt-4">
                            <Chip
                              label={t('CreateCampaignPage.entersSegmentOption')}
                              variant={entersSegmentIsActive ? 'dark' : 'gray'}
                              className="hover:cursor-pointer"
                              onClick={() => {
                                setEntersSegmentIsActive(true);
                                setLeavesSegmentIsActive(false);
                              }}
                            />
                            <Chip
                              label={t('CreateCampaignPage.leavesSegmentOption')}
                              variant={leavesSegmentIsActive ? 'dark' : 'gray'}
                              className="hover:cursor-pointer"
                              onClick={() => {
                                setLeavesSegmentIsActive(true);
                                setEntersSegmentIsActive(false);
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          <p className="mb-4">
                            <Trans
                              i18nKey="CreateCampaignPage.conversionCountLabel"
                              components={{
                                primary: <span className="font-semibold" />,
                              }}
                            />
                            :
                          </p>
                          <div className="flex flex-row items-baseline gap-x-4">
                            <Input
                              placeholder={t('CreateCampaignPage.durationPlaceholder')}
                              {...register('conversionDuration')}
                              error={errors.conversionDuration?.message as string}
                              className="border-gray-700 w-36"
                              type="number"
                            />
                            <OptionPicker
                              options={timeOptions}
                              selectedOption={selectedTimeOption}
                              setSelectedOption={setSelectedTimeOption}
                            />
                            <p>{t('CreateCampaignPage.ofLabel')}</p>
                            <OptionPicker
                              options={actionOptions}
                              selectedOption={selectedActionOption}
                              setSelectedOption={setSelectedActionOption}
                            />
                            <p>{t('CreateCampaignPage.anyDeliveryFromCampaignLabel')}</p>
                          </div>
                        </div>
                      </>
                    )}
                  </section>
                  <section className="px-6 pt-6 pb-8 space-y-8 shadow rounded-lg bg-custom-gray">
                    <p className="text-custom-aliceBlue font-semibold">
                      {t('CreateCampaignPage.peopleExitCampaignLabel')}:
                    </p>
                    <div className="flex flex-row flex-wrap gap-x-4 gap-y-2">
                      <Chip
                        label={t('CreateCampaignPage.theyAchieveTheGoalOption')}
                        variant={achieveGoalIsAactive ? 'dark' : 'gray'}
                        className="hover:cursor-pointer"
                        onClick={() => {
                          setAchieveTheGoalIsActive(
                            stopMatchingTriggerIsActive === false &&
                              peopleDontExitEarlyIsActive === false &&
                              achieveGoalOrStopMatchingTriggerIsActive === false &&
                              achieveGoalIsAactive === false
                              ? false
                              : true,
                          );
                          setAchieveGoalOrStopMatchingTriggerIsActive(false);
                          setStopMatchingTriggerIsActive(false);
                          setPeopleDontExitEarlyIsActive(false);
                        }}
                      />
                      <Chip
                        label={t('CreateCampaignPage.theyStopMatchingTriggerOption')}
                        variant={stopMatchingTriggerIsActive ? 'dark' : 'gray'}
                        className="hover:cursor-pointer"
                        onClick={() => {
                          setAchieveTheGoalIsActive(false);
                          setAchieveGoalOrStopMatchingTriggerIsActive(false);
                          setStopMatchingTriggerIsActive(
                            achieveGoalIsAactive === false &&
                              peopleDontExitEarlyIsActive === false &&
                              achieveGoalOrStopMatchingTriggerIsActive === false &&
                              stopMatchingTriggerIsActive === false
                              ? false
                              : true,
                          );
                          setPeopleDontExitEarlyIsActive(false);
                        }}
                      />
                      <Chip
                        label={t('CreateCampaignPage.theyAchieveTheGoalOrStopMatchingTrigger')}
                        variant={achieveGoalOrStopMatchingTriggerIsActive ? 'dark' : 'gray'}
                        className="hover:cursor-pointer"
                        onClick={() => {
                          setAchieveTheGoalIsActive(false);
                          setAchieveGoalOrStopMatchingTriggerIsActive(
                            achieveGoalIsAactive === false &&
                              peopleDontExitEarlyIsActive === false &&
                              stopMatchingTriggerIsActive === false &&
                              achieveGoalOrStopMatchingTriggerIsActive === false
                              ? false
                              : true,
                          );
                          setStopMatchingTriggerIsActive(false);
                          setPeopleDontExitEarlyIsActive(false);
                        }}
                      />
                      <Chip
                        label={t('CreateCampaignPage.peopleDontExitEarly')}
                        variant={peopleDontExitEarlyIsActive ? 'dark' : 'gray'}
                        className="hover:cursor-pointer"
                        onClick={() => {
                          setAchieveTheGoalIsActive(false);
                          setAchieveGoalOrStopMatchingTriggerIsActive(false);
                          setStopMatchingTriggerIsActive(false);
                          setPeopleDontExitEarlyIsActive(
                            achieveGoalIsAactive === false &&
                              achieveGoalOrStopMatchingTriggerIsActive === false &&
                              stopMatchingTriggerIsActive === false &&
                              peopleDontExitEarlyIsActive === false
                              ? false
                              : true,
                          );
                        }}
                      />
                    </div>
                  </section>
                  <div className="flex justify-end">
                    <Button variant="cancel" onClick={() => navigate(AppRoutes.campaigns)}>
                      {t('CreateCampaignPage.linkCancel')}
                    </Button>
                    <Button type="button" className="ml-8" onClick={onNext}>
                      {t('CreateCampaignPage.buttonNext')}
                    </Button>
                  </div>
                </div>
              )}
              {/* Workflow step */}
              {activeStep === 4 && (
                <div className="lg:w-[70rem] 3xl:w-[90rem]">
                  <div className="flex flex-row gap-4">
                    <ReactFlowProvider>
                      <section className="h-fit lg:w-[18.5rem] 3xl:w-[23.25rem] pl-3 pr-6 pt-6 pb-8 space-y-6 shadow rounded-lg bg-custom-gray text-custom-aliceBlue">
                        <h1 className="text-custom-aliceBlue font-semibold text-xl">
                          🚧 {t('CreateCampaignPage.buildLabel')}
                        </h1>
                        <section className="space-y-8">
                          <div>
                            <div className="flex flex-row gap-2 items-center mb-4">
                              <Chip label="" className="!h-4 !w-8 !bg-secondary-400" />
                              <span className="text-custom-aliceBlue text-base">
                                {t('CreateCampaignPage.messagesLabel')}
                              </span>
                            </div>
                            <div className="flex flex-row flex-wrap gap-x-4 gap-y-2">
                              {messageNodes.map((item, id) => {
                                return (
                                  <div
                                    className="dndnode"
                                    key={id}
                                    onDragStart={(event) => onDragStart(event, item, 'bg-secondary-400', '☎️')}
                                    draggable
                                  >
                                    <Chip
                                      label={item}
                                      className="text-gray-900 hover:cursor-pointer !bg-secondary-400"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div>
                            <div className="flex flex-row gap-2 items-center mb-4">
                              <Chip label="" className="!h-4 !w-8 !bg-yellow-200" />
                              <span className="text-custom-aliceBlue text-base">
                                {t('CreateCampaignPage.dataLabel')}
                              </span>
                            </div>
                            <div className="flex flex-row flex-wrap gap-x-4 gap-y-2">
                              {dataNodes.map((item, id) => {
                                return (
                                  <div
                                    className="dndnode"
                                    key={id}
                                    onDragStart={(event) => onDragStart(event, item, 'bg-yellow-200', '🧱')}
                                    draggable
                                  >
                                    <Chip label={item} className="text-gray-900 hover:cursor-pointer !bg-yellow-200" />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div>
                            <div className="flex flex-row gap-2 items-center mb-4">
                              <Chip label="" className="!h-4 !w-8 !bg-red-400" />
                              <span className="text-custom-aliceBlue text-base">
                                {t('CreateCampaignPage.delaysLabel')}
                              </span>
                            </div>
                            <div className="flex flex-row flex-wrap gap-x-4 gap-y-2">
                              {delaysNodes.map((item, id) => {
                                return (
                                  <div
                                    className="dndnode"
                                    key={id}
                                    onDragStart={(event) => onDragStart(event, item, 'bg-red-400', '🕗')}
                                    draggable
                                  >
                                    <Chip label={item} className="text-gray-900 hover:cursor-pointer !bg-red-400" />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div>
                            <div className="flex flex-row gap-2 items-center mb-4">
                              <Chip label="" className="!h-4 !w-8 !bg-primary-400" />
                              <span className="text-custom-aliceBlue text-base">
                                {t('CreateCampaignPage.flowControlLabel')}
                              </span>
                            </div>
                            <div className="flex flex-row flex-wrap gap-x-4 gap-y-2">
                              {flowControlNodes.map((item, id) => {
                                return (
                                  <div
                                    className="dndnode"
                                    key={id}
                                    onDragStart={(event) => onDragStart(event, item, 'bg-primary-400', '👀')}
                                    draggable
                                  >
                                    <Chip label={item} className="text-gray-900 hover:cursor-pointer !bg-primary-400" />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </section>
                      </section>
                      <div className="space-y-6 lg:w-[50rem] 3xl:w-[66.75rem]">
                        <Diagram />
                        <div className="flex justify-end">
                          <Button variant="cancel" onClick={() => navigate(AppRoutes.campaigns)}>
                            {t('CreateCampaignPage.linkCancel')}
                          </Button>
                          <Button type="button" className="ml-8" onClick={onNext}>
                            {t('CreateCampaignPage.buttonNext')}
                          </Button>
                        </div>
                      </div>
                    </ReactFlowProvider>
                  </div>
                </div>
              )}
              {/* Review step */}
              {activeStep === 5 && (
                <CreateCampaignLastStep
                  selectedTriggerTitle={selectedTrigger.triggerTitle}
                  allSubscribedPeopleIsActive={allSubscribedPeopleIsActive}
                  goalIsActive={goalIsActive}
                  entersSegmentIsActive={entersSegmentIsActive}
                  selectedTimeOptionText={selectedTimeOption.text}
                  selectedActionOptionText={selectedActionOption.text}
                  exitCondition={exitCondition}
                  segmentData={segmentData}
                  setActiveStep={setActiveStep}
                  setToastInfo={setToastInfo}
                  setOpen={setOpen}
                />
              )}
            </form>
          </FormProvider>
        </div>
        <ProvideToasts>
          <Toast
            isOpen={open}
            setOpen={setOpen}
            message={toastInfo.message}
            toastType={toastInfo.toastType as ToastType}
          />
        </ProvideToasts>
      </div>
    </>
  );
};

export default CreateCampaign;
