import { useTranslation } from 'react-i18next';

import Avatar from '@/component-library/primitives/Avatar/Avatar';
import BellRingIcon from '@/component-library/primitives/Icons/BellRingIcon/BellRingIcon';
import { MemberOverview } from '@/models/AudienceByWorkspace';

interface MemberProfileSideMenuProps {
  memberData: MemberOverview | undefined;
}

const MemberProfileSideMenu: React.FC<MemberProfileSideMenuProps> = ({ memberData }) => {
  const { t } = useTranslation();

  return (
    <div className="absolute top-0 left-[11rem] bg-memberProfileSideMenuBG w-[16.688rem] h-full py-11 px-6">
      <div className="flex flex-row gap-3 items-center">
        <Avatar initials={memberData?.name} size={'medium'} />
        <div className="flex flex-col gap-y-1">
          <div className="text-gray-100 text-base font-medium tracking-[0.32px] max-w-[150px] overflow-hidden">
            {memberData?.name}
          </div>
          <div className="flex flex-row gap-2 items-center bg-gray-900 rounded-[100px] px-5 py-2">
            <BellRingIcon />
            <span className="text-yellow-400 text-sm font-normal tracking-[0.28px]">
              {t('MemberProfilePage.subscribedLabel')}
            </span>
          </div>
        </div>
      </div>
      <div className="3xl:mt-13 lg:mt-2">
        <h2 className="text-xs font-semibold text-secondary-400 tracking-[0.24px] mb-7">
          {t('MemberProfilePage.detailsLabel')}
        </h2>
        <section className="space-y-6 mb-6">
          <div className="flex flex-col gap-y-1">
            <span className="text-white text-xs font-normal tracking-[0.24px]">{t('MemberProfilePage.idLabel')}</span>
            <span className="text-gray-400 text-sm font-normal">{memberData?.id}</span>
          </div>
          <div className="flex flex-col gap-y-1">
            <span className="text-white text-xs font-normal tracking-[0.24px]">
              {t('MemberProfilePage.emailLabel')}
            </span>
            <span className="text-gray-400 text-sm font-normal truncate">{memberData?.email}</span>
          </div>
          <div className="flex flex-col gap-y-1">
            <span className="text-white text-xs font-normal tracking-[0.24px]">
              {t('MemberProfilePage.phoneLabel')}
            </span>
            <span className="text-gray-400 text-sm font-normal">{memberData?.phoneNumber || '???'}</span>
          </div>
          <div className="flex flex-col gap-y-1">
            <span className="text-white text-xs font-normal tracking-[0.24px]">
              {t('MemberProfilePage.lastVisitedLabel')}
            </span>
            <span className="text-gray-400 text-sm font-normal">Jun 20, 2023 - 1:30 AM</span>
          </div>
          <div className="flex flex-col gap-y-1">
            <span className="text-white text-xs font-normal tracking-[0.24px]">
              {t('MemberProfilePage.signedUpLabel')}
            </span>
            <span className="text-gray-400 text-sm font-normal">Feb 15, 2023 - 1:30 AM</span>
          </div>
          <div className="flex flex-col gap-y-1">
            <span className="text-white text-xs font-normal tracking-[0.24px]">
              {t('MemberProfilePage.lastMessagedLabel')}
            </span>
            <span className="text-gray-400 text-sm font-normal">???</span>
          </div>
        </section>
        <hr className="w-full border-gray-800 border-[1.5px]" />
        <section className="space-y-6 mt-6">
          <div className="flex flex-col gap-y-1">
            <span className="text-white text-xs font-normal tracking-[0.24px]">
              {t('MemberProfilePage.createdByLabel')}
            </span>
            <span className="text-gray-400 text-sm font-normal truncate">{memberData?.createdByName}</span>
          </div>
          <div className="flex flex-col gap-y-1">
            <span className="text-white text-xs font-normal tracking-[0.24px]">
              {t('MemberProfilePage.createdAtLabel')}
            </span>
            <div className="text-gray-400 text-sm font-normal flex flex-row gap-x-1">
              <span>
                {new Date(memberData?.createdAt as string).toLocaleDateString('en-us', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </span>
              <span>-</span>
              <span className="text-sm">
                {new Date(memberData?.createdAt as string).toLocaleTimeString('en-us', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </span>
            </div>
          </div>
        </section>
      </div>
      <div></div>
    </div>
  );
};

export default MemberProfileSideMenu;
