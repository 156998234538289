/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionMeta, MultiValue } from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import parse from 'html-react-parser';
import html2canvas from 'html2canvas';
import * as yup from 'yup';

import { useSendEmail } from '@/api/messages/useSendEmail';
import { useGetSegments } from '@/api/segments/useGetSegments';
import { useGetTemplatesByTenant } from '@/api/templates/useGetTemplatesByTenant';
import { useAppSelector } from '@/app/hooks';
import { Button } from '@/component-library/primitives/Button/Button';
import Chip from '@/component-library/primitives/Chip/Chip';
import AddPlusCircleIcon from '@/component-library/primitives/Icons/AddPlusCircleIcon/AddPlusCircleIcon';
import ChevronNewIcon from '@/component-library/primitives/Icons/ChevronNewIcon/ChevronNewIcon';
import CircleHelpIcon from '@/component-library/primitives/Icons/CircleHelpIcon/CircleHelpIcon';
import DragAndDropIcon from '@/component-library/primitives/Icons/DragAndDropIcon/DragAndDropIcon';
import GearIcon from '@/component-library/primitives/Icons/GearIcon/GearIcon';
import HtmlEditorIcon from '@/component-library/primitives/Icons/HtmlEditorIcon/HtmlEditorIcon';
import MoreIcon from '@/component-library/primitives/Icons/MoreIcon/MoreIcon';
import RefreshIcon from '@/component-library/primitives/Icons/RefreshIcon/RefreshIcon';
import TemplateIcon from '@/component-library/primitives/Icons/TemplateIcon/TemplateIcon';
import Loader from '@/component-library/primitives/Loader/Loader';
import { RadioButton } from '@/component-library/primitives/RadioButton/RadioButton';
import Stepper from '@/component-library/primitives/Stepper/Stepper';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import Tooltip from '@/component-library/primitives/Tooltip/Tooltip';
import HtmlEditor from '@/component-library/widgets/HtmlEditor/HtmlEditor';
import { Input } from '@/component-library/widgets/Input/Input';
import OptionSelector from '@/component-library/widgets/OptionSelector/OptionSelector';
import SearchField from '@/component-library/widgets/SearchField/SearchField';
import SelectField, { OptionType } from '@/component-library/widgets/SelectField/SelectField';
import { ToggleSwitch } from '@/component-library/widgets/ToggleSwitch/ToggleSwitch';
import NewEmail, { Audience } from '@/models/NewEmail';
import { Segment } from '@/models/Segments';
import { Template } from '@/models/TemplatesByTenant';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { HttpMethodsEnum, PermissionsEnum } from '@/modules/shared/enums/enums';
import usePermissionChecker from '@/modules/shared/hooks/usePermissionChecker';
import { AppRoutes } from '@/utils/routes/router';

import EmailTemplateModal from './EmailTemplateModal';
import SingleTemplate from './SingleTemplate';

const NewEmailPage = () => {
  const sendEmailMutation = useSendEmail();
  const { data: segmentData, isFetched } = useGetSegments();
  const workspace = useAppSelector(selectCurrentWorkspace);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const [activeStep, setActiveStep] = useState(0);
  const divWhereToScroll = useRef<HTMLDivElement>(null);
  const [settings, setSettings] = useState(t('NewEmailPage.htmlEditorOption') as string);
  const [deliveryScheduleType, setDeliveryScheduleType] = useState('Immediately');
  const [isEveryoneShouldReceiveMsgActive, setIsEveryoneShouldReceiveMsgActive] = useState(false);
  const [isSegmentsIconHovered, setIsSegmentsIconHovered] = useState(false);
  const [isAdvancedSettingsOptionSelected, setIsAdvancedSettingsOptionSelected] = useState(true);
  const [isDeliveryScheduleIconHovered, setIsIDeliveryScheduleconHovered] = useState(false);
  const { data } = useGetTemplatesByTenant();
  const [selectedTemplate, setSelectedTemplate] = useState<Template>();
  const [templateInUse, setTemplateInUse] = useState<Template>();
  const [sharedOptionsState, setSharedOptionsState] = useState<OptionType[] | undefined>([]);
  const [showEmailTemplateModal, setShowEmailTemplateModal] = useState(false);
  const isActionAllowed = usePermissionChecker(PermissionsEnum.NOTIFICATIONS, HttpMethodsEnum.POST);

  const handleEmailTemplateModal = (state: boolean) => {
    // @ts-ignore
    if (!document.startViewTransition) {
      setShowEmailTemplateModal(state);
    } else {
      // @ts-ignore
      document.startViewTransition(() => {
        flushSync(() => {
          setShowEmailTemplateModal(state);
        });
      });
    }
  };

  const steps = [t('NewEmailPage.messageInfoStep'), t('NewEmailPage.messageEditorStep'), t('NewEmailPage.previewStep')];

  settings === t('NewEmailPage.templateOption') && steps.splice(1, 0, 'Template');

  const availableSegments = segmentData?.map((segment) => {
    return {
      label: segment.name,
      value: segment.id,
    };
  });

  useEffect(() => {
    isFetched && setSharedOptionsState(availableSegments);
  }, [isFetched]);

  const sharedOptions = useRef<OptionType[]>([]);

  useEffect(() => {
    let timerBeforeNavigation: NodeJS.Timeout;
    if (toastInfo.toastType === 'success') {
      timerBeforeNavigation = setTimeout(() => navigate(AppRoutes.delivery), 2000);
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, toastInfo]);

  const schema = useMemo(() => {
    if (settings === t('NewEmailPage.templateOption')) {
      return [
        yup.object({
          messageTitle: yup.string().trim().required(t('CommonErrors.messageTitleRequired')),
          segmentsShouldInclude: !isEveryoneShouldReceiveMsgActive
            ? yup
                .array()
                .required(t('NewEmailPage.atLeastOneSegmentToReceiveMsgRequired'))
                .nullable()
                .min(1, t('NewEmailPage.atLeastOneSegmentToReceiveMsgRequired'))
            : yup.array(),
          scheduleTime:
            deliveryScheduleType === 'Specific Time'
              ? yup
                  .date()
                  .nullable()
                  .transform((curr, orig) => (orig === '' ? null : curr))
                  .required(t('NewEmailPage.scheduleDateTimeRequired'))
                  .min(new Date(new Date().getTime() + 15 * 60000), t('NewEmailPage.scheduleDateTimeInFutureRequired'))
              : yup.string(),
          eventBasedTrigger:
            deliveryScheduleType === 'Event Based'
              ? yup.string().trim().required(t('NewEmailPage.eventNameRequired'))
              : yup.string(),
        }),
        yup.object({}),
        yup.object({
          subject: yup.string().trim().required(t('CommonErrors.subjectRequired')),
          fromName: yup.string().trim().required(t('CommonErrors.fromNameRequired')),
          fromEmail: yup.string().trim().email().required(t('CommonErrors.fromEmailRequired')),
          replyEmail: yup.string().trim().email().required(t('CommonErrors.replyEmailRequired')),
        }),
        yup.object({}),
      ];
    } else {
      return [
        yup.object({
          messageTitle: yup.string().trim().required(t('CommonErrors.messageTitleRequired')),
          segmentsShouldInclude: !isEveryoneShouldReceiveMsgActive
            ? yup
                .array()
                .required(t('NewEmailPage.atLeastOneSegmentToReceiveMsgRequired'))
                .nullable()
                .min(1, t('NewEmailPage.atLeastOneSegmentToReceiveMsgRequired'))
            : yup.array(),
          scheduleTime:
            deliveryScheduleType === 'Specific Time'
              ? yup
                  .date()
                  .nullable()
                  .transform((curr, orig) => (orig === '' ? null : curr))
                  .required(t('NewEmailPage.scheduleDateTimeRequired'))
                  .min(new Date(new Date().getTime() + 15 * 60000), t('NewEmailPage.scheduleDateTimeInFutureRequired'))
              : yup.string(),
          eventBasedTrigger:
            deliveryScheduleType === 'Event Based'
              ? yup.string().trim().required(t('NewEmailPage.eventNameRequired'))
              : yup.string(),
        }),

        yup.object({
          subject: yup.string().trim().required(t('CommonErrors.subjectRequired')),
          fromName: yup.string().trim().required(t('CommonErrors.fromNameRequired')),
          fromEmail: yup.string().trim().email().required(t('CommonErrors.fromEmailRequired')),
          replyEmail: yup.string().trim().email().required(t('CommonErrors.replyEmailRequired')),
        }),
        yup.object({}),
      ];
    }
  }, [deliveryScheduleType, isEveryoneShouldReceiveMsgActive, settings, t]);

  const currentValidationSchema = schema[activeStep];

  const methods = useForm<NewEmail>({
    shouldUnregister: false,
    resolver: yupResolver(currentValidationSchema),
    mode: 'onChange',
    defaultValues: {
      deliveryScheduleType: 'Immediately',
      editingType: 'html-editor',
    },
  });

  const {
    control,
    handleSubmit,
    trigger,
    register,
    setValue,
    getValues,
    clearErrors,
    watch,
    formState: { errors },
  } = methods;

  const watchSubject = watch('subject');
  const watchFromName = watch('fromName');
  const watchFromEmail = watch('fromEmail');
  const watchReplyToEmail = watch('replyEmail');

  const [selectedTemplateHtmlContent, setSelectedTemplateHtmlContent] = useState('');
  const [htmlContent, setHtmlContent] = useState('<!DOCTYPE html>');

  useEffect(() => {
    setValue('deliveryScheduleType', deliveryScheduleType);
  }, [deliveryScheduleType]);

  const onBack = () => {
    activeStep === 0 ? navigate(AppRoutes.delivery) : setActiveStep((prevActiveStep) => prevActiveStep - 1);
    divWhereToScroll.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onNext = async () => {
    if (!isActionAllowed) {
      setToastInfo({ message: t('CommonErrors.actionNotAllowed'), toastType: 'error' });
      setOpen(true);
      return;
    }
    const isStepValid = await trigger();
    if (isStepValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      divWhereToScroll.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const formatSegmentObject = (segmentData: Segment[] | undefined, selectedOptions: Audience[] | undefined) => {
    const arrayData = [];

    if (segmentData && selectedOptions) {
      for (let i = 0; i < segmentData.length; i++) {
        for (let j = 0; j < selectedOptions.length; j++) {
          if (segmentData[i].id === selectedOptions[j].value) {
            arrayData.push(segmentData[i]);
          }
        }
      }
    }
    return arrayData.map((item) => {
      return {
        id: item.id,
        name: item.name,
        PK: item.PK,
        SK: item.SK,
      };
    });
  };

  const onSubmit = (data: NewEmail) => {
    const emailData = {
      workspaceId: workspace?.id ?? '',
      title: data.messageTitle.trim(),
      segmentsShouldInclude: isEveryoneShouldReceiveMsgActive
        ? []
        : formatSegmentObject(segmentData, data.segmentsShouldInclude),
      segmentsShouldNotInclude: isEveryoneShouldReceiveMsgActive
        ? []
        : formatSegmentObject(segmentData, data.segmentsShouldNotInclude),
      fromName: data.fromName.trim(),
      fromEmail: data.fromEmail.trim(),
      replyToEmail: data.replyEmail.trim(),
      subject: data.subject.trim(),
      specificTime:
        deliveryScheduleType === 'Specific Time' ? new Date(getValues('scheduleTime') as string).toISOString() : '',
      eventBasedTrigger: deliveryScheduleType === 'Event Based' ? data.eventBasedTrigger : '',
      templateBody: htmlContent,
      deliveryScheduleType: data.deliveryScheduleType,
    };

    sendEmailMutation.mutate(emailData, {
      onSuccess: () => {
        deliveryScheduleType === 'Specific Time'
          ? setToastInfo({ message: t('NewEmailPage.toastScheduledSuccessfully'), toastType: 'success' })
          : setToastInfo({ message: t('NewEmailPage.toastSentSuccessfully'), toastType: 'success' });
        setOpen(true);
      },
      onError: () => {
        setToastInfo({ message: t('NewEmailPage.toastError'), toastType: 'error' });
        setOpen(true);
      },
    });
  };

  const [editorSanitizedHtml, setEditorSanitizedHtml] = useState(false);

  const handleHTMLChange = (newHtml: string | undefined) => {
    if (!editorSanitizedHtml) setEditorSanitizedHtml(true);
    setHtmlContent(newHtml as string);
  };

  const handleSettingsSelect = (option: string) => {
    setSettings(option);
    if (option === t('NewEmailPage.dragAndDropEditorOption')) {
      setValue('editingType', 'drag-and-drop');
    } else if (option === t('NewEmailPage.htmlEditorOption')) {
      setValue('editingType', 'html-editor');
      if (htmlContent.length > 0) setHtmlContent('<!DOCTYPE html>');
    } else {
      setValue('editingType', 'template');
      if (htmlContent.length > 0 && templateInUse) setHtmlContent('<!DOCTYPE html>');
      setTemplateInUse(undefined);
    }
  };

  const handleShouldEveryoneReceiveMsgToggle = (value: boolean) => {
    setIsEveryoneShouldReceiveMsgActive(value);
    if (!isEveryoneShouldReceiveMsgActive) {
      setValue('segmentsShouldInclude', []);
      setValue('segmentsShouldNotInclude', []);
      setSharedOptionsState(availableSegments);
      clearErrors('segmentsShouldInclude');
    }
  };

  const convertHtmlToPicture = useCallback(() => {
    let i = 0;
    const domElements = [];
    if (data?.length) {
      while (i < data?.length) {
        domElements.push(document.getElementById(`html-template-${i}`));
        i++;
      }
      domElements.map((el, i) => {
        html2canvas(el as HTMLElement, {
          onclone: function (clonedDoc) {
            clonedDoc.getElementById(`html-template-${i}`)!.style.display = 'block';
          },
          logging: false,
          allowTaint: false,
          useCORS: true,
        }).then(function (canvas) {
          const url = canvas.toDataURL('image/jpg');
          const image = new Image();
          image.src = url;
          image.style.cssText += 'width:100%;max-height:465px;object-fit:cover;object-position:top;margin-bottom:0;';
          document.getElementById(`image-${i}`)?.appendChild(image);
        });
      });
    }
  }, [data]);

  useEffect(() => {
    if (settings === t('NewEmailPage.templateOption') && activeStep === 1) {
      convertHtmlToPicture();
    }
  }, [activeStep, settings, convertHtmlToPicture, t]);

  const updateAvailableSegmentOptions = (multiValue: MultiValue<Audience>, action: ActionMeta<Audience>) => {
    if (sharedOptionsState) {
      if (action.removedValue) {
        const filteredSelectedOptions = sharedOptions.current.filter((i) => i.value !== action.removedValue?.value);
        sharedOptions.current = filteredSelectedOptions;
        setSharedOptionsState(availableSegments);
      } else if (action.removedValues) {
        for (let i = 0; i < sharedOptions.current.length; i++) {
          for (let j = 0; j < action.removedValues.length; j++) {
            if (sharedOptions.current[i].value === action.removedValues[j].value) {
              sharedOptions.current.splice(i, 1);
            }
          }
        }
        setSharedOptionsState(availableSegments);
      } else {
        sharedOptions.current.push(multiValue[multiValue.length - 1]);
        setSharedOptionsState(availableSegments);
      }
      for (const x of sharedOptions.current) {
        setSharedOptionsState((prev: OptionType[] | undefined) =>
          prev?.filter((item: OptionType) => item.value !== x.value),
        );
      }
    }
  };

  const resetCodeValues = () => {
    setValue('subject', '');
    setValue('fromName', '');
    setValue('fromEmail', '');
    setValue('replyEmail', '');
  };

  return (
    <div className={`${sendEmailMutation.isLoading ? 'relative' : 'static'} mb-[150px]`}>
      {sendEmailMutation.isLoading && (
        <div className="absolute w-full h-[80vh] z-50">
          <Loader />
        </div>
      )}
      <div className="flex flex-col items-center mt-4">
        <div
          className={`${
            (settings === t('NewEmailPage.templateOption') && activeStep === 2) ||
            (settings === t('NewEmailPage.htmlEditorOption') && activeStep === 1)
              ? 'hidden'
              : 'flex'
          } min-w-[40rem] items-start -ml-6`}
          ref={divWhereToScroll}
        >
          <Stepper steps={steps} activeStep={activeStep} />
        </div>
        <div className="w-[40rem]">
          <FormProvider {...methods}>
            <form className="space-y-6 min-w-[40rem] w-1/2">
              {/* Message info step */}
              {activeStep === 0 && (
                <div>
                  <div className="mb-7">
                    <h1 className="text-white font-medium text-xl">{t('NewEmailPage.emailSetUpLabel')}</h1>
                  </div>
                  <div className="space-y-7">
                    <Input
                      placeholder={t('NewEmailPage.messageTitlePlaceholder')}
                      label={t('NewEmailPage.messageTitleLabel')}
                      {...register('messageTitle')}
                      error={errors.messageTitle?.message as string}
                    />

                    {/* Segments Section */}
                    <section>
                      <div className="flex flex-row items-center justify-between mb-3">
                        <h2 className="text-xs font-semibold text-yellow-500">{t('NewEmailPage.segmentsLabel')}</h2>
                        <Tooltip
                          side="right"
                          content={
                            <div className="text-gray-100 text-xs font-light">{t('NewEmailPage.segmentsTooltip')}</div>
                          }
                          open={isSegmentsIconHovered}
                          onOpenChange={(open: boolean) => {
                            if (open) {
                              setIsSegmentsIconHovered(true);
                            } else {
                              setIsSegmentsIconHovered(false);
                            }
                          }}
                          contentClassName="bg-custom-darkBlue p-4 rounded-lg z-50 w-44 text-center"
                          arrowClassName="fill-custom-darkBlue w-5 h-2"
                        >
                          <button type="button">
                            <CircleHelpIcon />
                          </button>
                        </Tooltip>
                      </div>
                      <div className="flex flex-row items-center gap-x-2 mb-7">
                        <span className="font-normal text-sm leading-[24px] text-gray-200">
                          {t('NewEmailPage.everyoneShouldReceiveThisMessageLabel')}
                        </span>
                        <ToggleSwitch
                          value={isEveryoneShouldReceiveMsgActive}
                          onChange={handleShouldEveryoneReceiveMsgToggle}
                        />
                      </div>
                      <div
                        className={`space-y-3 ${
                          isEveryoneShouldReceiveMsgActive ? 'opacity-50 pointer-events-none' : ''
                        }`}
                      >
                        <SelectField
                          name="segmentsShouldInclude"
                          label={
                            <Trans
                              i18nKey="NewEmailPage.segmentsReceivingMessageLabel"
                              components={{
                                primary: (
                                  <span
                                    className={`${
                                      errors.segmentsShouldInclude?.message ? 'text-errorRed-500' : 'text-primary-400'
                                    }`}
                                  />
                                ),
                              }}
                            />
                          }
                          control={control}
                          options={sharedOptionsState}
                          errorMsg={errors.segmentsShouldInclude?.message}
                          className={'mb-2'}
                          isMulti={true}
                          customOnChange={updateAvailableSegmentOptions}
                          placeholder={t('NewEmailPage.writeOrSelectLabel')}
                        />
                        <SelectField
                          name="segmentsShouldNotInclude"
                          label={
                            <Trans
                              i18nKey="NewEmailPage.segmentsNotReceivingMessageLabel"
                              components={{
                                primary: (
                                  <span
                                    className={`${
                                      errors.segmentsShouldNotInclude?.message ? 'text-errorRed-500' : 'text-red-400'
                                    }`}
                                  />
                                ),
                              }}
                            />
                          }
                          control={control}
                          options={sharedOptionsState}
                          errorMsg={errors.segmentsShouldNotInclude?.message}
                          className={'mb-2'}
                          isMulti={true}
                          multiValueChipColor="red"
                          customOnChange={updateAvailableSegmentOptions}
                          placeholder={t('NewEmailPage.writeOrSelectLabel')}
                        />
                      </div>
                      <div className="flex justify-start mt-5">
                        <Button
                          type="button"
                          variant="transparent"
                          iconLeft={
                            <span className="group-active:[&>*_path]:stroke-white">
                              <GearIcon color="#7898FB" />
                            </span>
                          }
                          className="group px-2"
                          onClick={() => navigate(AppRoutes.segments)}
                        >
                          {t('NewEmailPage.linkManageSegments')}
                        </Button>
                      </div>
                    </section>

                    {/* Delivery schedule */}
                    <section>
                      <div className="flex flex-row items-center justify-between mb-3">
                        <h2 className="text-xs font-semibold text-yellow-500">
                          {t('NewEmailPage.deliveryScheduleLabel')}
                        </h2>
                        <Tooltip
                          side="right"
                          content={
                            <div className="text-gray-100 text-xs font-light">{t('NewEmailPage.deliveryTooltip')}</div>
                          }
                          open={isDeliveryScheduleIconHovered}
                          onOpenChange={(open: boolean) => {
                            if (open) {
                              setIsIDeliveryScheduleconHovered(true);
                            } else {
                              setIsIDeliveryScheduleconHovered(false);
                            }
                          }}
                          contentClassName="bg-custom-darkBlue p-4 rounded-lg z-50 w-44 text-center"
                          arrowClassName="fill-custom-darkBlue w-5 h-2"
                        >
                          <button type="button">
                            <CircleHelpIcon />
                          </button>
                        </Tooltip>
                      </div>
                      <div className={`flex flex-col`}>
                        <div
                          className="w-fit flex items-center h-12"
                          onClick={() => setDeliveryScheduleType('Immediately')}
                        >
                          <RadioButton
                            label={t('NewEmailPage.sendNowLabel')}
                            {...register('deliveryScheduleType')}
                            value="Immediately"
                            className="w-fit"
                            checked={deliveryScheduleType === 'Immediately'}
                          />
                        </div>
                        <div className="flex flex-row gap-x-5 items-center h-12">
                          <div className="w-fit" onClick={() => setDeliveryScheduleType('Specific Time')}>
                            <RadioButton
                              label={t('NewEmailPage.scheduleDateAndTimeLabel')}
                              {...register('deliveryScheduleType')}
                              value="Specific Time"
                              className="w-fit"
                              checked={deliveryScheduleType === 'Specific Time'}
                            />
                          </div>
                          {deliveryScheduleType === 'Specific Time' && (
                            <Input
                              type="datetime-local"
                              className="!w-[15.25rem]"
                              {...register('scheduleTime')}
                              error={errors.scheduleTime?.message as string}
                            />
                          )}
                        </div>
                        <div className={`flex flex-row gap-x-5 items-center h-12`}>
                          <div className="w-fit" onClick={() => setDeliveryScheduleType('Event Based')}>
                            <RadioButton
                              label={t('NewEmailPage.eventLabel')}
                              {...register('deliveryScheduleType')}
                              value="Event Based"
                              className="w-fit"
                              checked={deliveryScheduleType === 'Event Based'}
                            />
                          </div>
                          {deliveryScheduleType === 'Event Based' && (
                            <Input
                              placeholder={t('NewEmailPage.eventNamePlaceholder')}
                              className="!w-[15.25rem]"
                              {...register('eventBasedTrigger')}
                              error={errors.eventBasedTrigger?.message as string}
                            />
                          )}
                        </div>
                      </div>
                    </section>

                    {/* Message Section */}
                    <section>
                      <div className="flex flex-row items-center justify-between mb-4">
                        <h2 className="text-xs font-semibold text-yellow-500">
                          {t('NewEmailPage.selectEditingExperienceLabel')}
                        </h2>
                      </div>
                      <div className="flex justify-between space-x-4">
                        <OptionSelector
                          title={t('NewEmailPage.dragAndDropEditorOption')}
                          content={t('NewEmailPage.dragAndDropEditorOptionDescription')}
                          gradientOption="1"
                          isSelected={settings === t('NewEmailPage.dragAndDropEditorOption')}
                          onSelect={handleSettingsSelect}
                          register={register}
                          radioBtnValue="drag-and-drop"
                          optionImage={
                            <span className="mb-3">
                              <DragAndDropIcon />
                            </span>
                          }
                          disabled={true}
                        />
                        <OptionSelector
                          title={t('NewEmailPage.htmlEditorOption')}
                          content={t('NewEmailPage.htmlEditorOptionDescription')}
                          gradientOption="2"
                          isSelected={settings === t('NewEmailPage.htmlEditorOption')}
                          onSelect={handleSettingsSelect}
                          register={register}
                          radioBtnValue="html-editor"
                          optionImage={
                            <span className="mb-3">
                              <HtmlEditorIcon />
                            </span>
                          }
                        />
                        <OptionSelector
                          title={t('NewEmailPage.templateOption')}
                          content={t('NewEmailPage.templateOptionDescription')}
                          gradientOption="3"
                          isSelected={settings === t('NewEmailPage.templateOption')}
                          onSelect={handleSettingsSelect}
                          register={register}
                          radioBtnValue="template"
                          optionImage={
                            <span className="mb-3">
                              <TemplateIcon />
                            </span>
                          }
                        />
                      </div>
                    </section>
                  </div>
                  <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                    <div className="flex items-center justify-between min-w-[40rem]">
                      <Button variant="cancel" onClick={() => navigate(AppRoutes.delivery)}>
                        {t('NewEmailPage.linkCancel')}
                      </Button>
                      <div className="flex flex-row gap-2">
                        <Button
                          type="button"
                          className="group pl-4 !w-[8.125rem]"
                          onClick={onNext}
                          iconRight={
                            <span className="startFromScratchLabel group-active:[&>*_path]:stroke-white">
                              <ChevronNewIcon type="right" color="#1C222A" width="20" height="20" />
                            </span>
                          }
                        >
                          {t('NewEmailPage.buttonNext')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Template step */}
              {settings === t('NewEmailPage.templateOption') && activeStep === 1 && (
                <>
                  <div className="mb-8">
                    <h1 className="text-white font-medium text-xl mb-2">{t('NewEmailPage.chooseTemplateLabe')}</h1>
                    <span className="text-gray-300 text-sm">{t('NewEmailPage.templateStepDescriptionLabel')}</span>
                  </div>
                  <div
                    className={`flex w-[200%] ${
                      data && data.length <= 2
                        ? 'ml-0'
                        : data && data.length < 5
                        ? '3xl:-ml-[25%] lg:-ml-[20%]'
                        : '3xl:-ml-[50%] lg:-ml-[20%]'
                    }`}
                  >
                    <div className="3xl:columns-6 lg:columns-4 gap-4 min-h-full">
                      <>
                        <div
                          className="group mb-4 break-inside-avoid w-[12.375rem] h-[14.375rem] rounded-xl cursor-pointer bg-importMembersField bg-import-field-size bg-import-field-position bg-no-repeat bg-newTemplateBG flex items-center justify-center"
                          onClick={() => {
                            onNext();
                            setSelectedTemplate(undefined);
                            setTemplateInUse(undefined);
                            setHtmlContent('<!DOCTYPE html>');
                            setSelectedTemplateHtmlContent('');
                          }}
                        >
                          <div className="flex flex-col gap-y-2 items-center">
                            <span className="group-hover:[&>*_path]:stroke-gray-100 group-active:[&>*_path]:stroke-gray-100">
                              <AddPlusCircleIcon color="#7898FB" />
                            </span>
                            <span className="font-normal text-sm leading-4 tracking-[0.02em] text-secondary-400 group-hover:text-gray-100 group-active:text-gray-100">
                              {t('NewEmailPage.startFromScratchLabel')}
                            </span>
                          </div>
                        </div>
                        {data?.map((template: Template, i: number) => (
                          <React.Fragment key={i}>
                            <SingleTemplate
                              id={i}
                              template={template}
                              templateInUse={templateInUse}
                              setTemplateInUse={setTemplateInUse}
                              setHtmlContent={setHtmlContent}
                              setSelectedTemplate={setSelectedTemplate}
                              setSelectedTemplateHtmlContent={setSelectedTemplateHtmlContent}
                              setShowEmailTemplateModal={handleEmailTemplateModal}
                            />
                          </React.Fragment>
                        ))}
                      </>
                    </div>
                    <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                      <div className="flex items-center justify-between min-w-[40rem]">
                        <Button variant="cancel" onClick={() => navigate(AppRoutes.delivery)}>
                          {t('NewEmailPage.linkCancel')}
                        </Button>
                        <div className="flex flex-row gap-2">
                          <Button
                            type="button"
                            variant="previous"
                            className="pr-4 !w-[8.125rem]"
                            onClick={onBack}
                            iconLeft={<ChevronNewIcon type="left" color="#F5F7FC" width="20" height="20" />}
                          >
                            {t('NewEmailPage.buttonPrevious')}
                          </Button>
                          <Button
                            type="button"
                            className="group pl-4 !w-[8.125rem]"
                            onClick={onNext}
                            iconRight={
                              <span className="group-hover:[&>*_path]:stroke-gray-900 group-active:[&>*_path]:stroke-white">
                                <ChevronNewIcon type="right" color="#1C222A" width="20" height="20" />
                              </span>
                            }
                          >
                            {t('NewEmailPage.buttonNext')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* Message editor step */}
              {(settings === t('NewEmailPage.templateOption') ? activeStep === 2 : activeStep === 1) && (
                <div className="flex justify-center">
                  {/* Message info and editor */}
                  <div className="flex flex-col items-center -ml-64">
                    <Stepper steps={steps} activeStep={activeStep} />
                    <div className="flex flex-row gap-x-2 -mt-6">
                      <div className="3xl:w-[31.063rem] lg:w-[25rem] flex flex-col">
                        <div className="bg-htmlEditor rounded-lg p-4 flex flex-col">
                          <div className="flex flex-row items-center justify-between">
                            <h2 className="text-xs font-semibold text-yellow-500">{t('NewEmailPage.codeLabel')}</h2>
                            <div className="flex flex-row items-center gap-x-1">
                              <button
                                type="button"
                                className="bg-htmlEditorBtnBG p-1 h-6 rounded-[5px] flex items-center justify-center group"
                              >
                                <span className="text-xs font-normal leading-[14px] tracking-[0.02em] text-secondary-400 group-hover:text-gray-100 group-active:text-gray-100">
                                  {t('NewEmailPage.buttonBcc')}
                                </span>
                              </button>
                              <button
                                type="button"
                                className="bg-htmlEditorBtnBG p-1 h-6 rounded-[5px] flex items-center justify-center group"
                              >
                                <span className="text-xs font-normal leading-[14px] tracking-[0.02em] text-secondary-400 group-hover:text-gray-100 group-active:text-gray-100">
                                  {t('NewEmailPage.buttonCC')}
                                </span>
                              </button>
                            </div>
                          </div>
                          <section className="mt-2">
                            <Input
                              placeholder={t('NewEmailPage.subjectPlaceholder')}
                              {...register('subject')}
                              error={errors.subject?.message as string}
                              className="!bg-transparent !border-l-0 !border-t-0 !border-r-0 !rounded-[0px] !pl-0 autofill:!shadow-[inset_0_0_0px_1000px_#0c0d11]"
                              withBorder={true}
                            />
                            <Input
                              placeholder={t('NewEmailPage.fromNamePlaceholder')}
                              {...register('fromName')}
                              error={errors.fromName?.message as string}
                              className="!bg-transparent !border-l-0 !border-t-0 !border-r-0 !rounded-[0px] !pl-0 autofill:!shadow-[inset_0_0_0px_1000px_#0c0d11]"
                              withBorder={true}
                            />
                            <Input
                              placeholder={t('NewEmailPage.fromEmailPlaceholder')}
                              {...register('fromEmail')}
                              error={errors.fromEmail?.message as string}
                              className="!bg-transparent !border-l-0 !border-t-0 !border-r-0 !rounded-[0px] !pl-0 autofill:!shadow-[inset_0_0_0px_1000px_#0c0d11]"
                              withBorder={true}
                            />
                            <Input
                              placeholder={t('NewEmailPage.replyToEmailPlaceholder')}
                              {...register('replyEmail')}
                              error={errors.replyEmail?.message as string}
                              className="!bg-transparent !border-l-0 !border-t-0 !border-r-0 !rounded-[0px] !pl-0 autofill:!shadow-[inset_0_0_0px_1000px_#0c0d11]"
                              withBorder={true}
                            />
                          </section>
                        </div>
                      </div>
                      <div className="3xl:w-[31.063rem] lg:w-[25rem] flex flex-col">
                        <div className="h-full bg-darkBlue rounded-lg p-4 flex flex-col">
                          <div className="flex flex-row items-center justify-between">
                            <h2 className="text-xs font-semibold text-yellow-500">
                              {t('NewEmailPage.emailTemplateLabel')}
                            </h2>
                            <div className="flex flex-row items-center gap-x-1">
                              <button
                                type="button"
                                className="bg-htmlEditorBtnBG p-1 h-6 rounded-[5px] flex items-center justify-center group"
                                onClick={resetCodeValues}
                              >
                                <span className="group-hover:[&>*_path]:stroke-gray-100">
                                  <RefreshIcon />
                                </span>
                              </button>
                              <button
                                type="button"
                                className="bg-transparent p-1 h-6 flex items-center justify-center group"
                              >
                                <span className="group-hover:[&>*_path]:stroke-gray-100">
                                  <MoreIcon />
                                </span>
                              </button>
                            </div>
                          </div>
                          <section className="mt-6 space-y-9">
                            <p className="text-base font-medium tracking-[0.02em] leading-[16px] text-gray-300">
                              {watchSubject}
                            </p>
                            <p className="text-xs font-normal tracking-[0.02em] leading-[14px] text-gray-300">
                              {watchFromName}
                            </p>
                            <p className="text-xs font-normal tracking-[0.02em] leading-[14px] text-gray-300">
                              {watchFromEmail}
                            </p>
                            <p className="text-xs font-normal tracking-[0.02em] leading-[14px] text-gray-300">
                              {watchReplyToEmail}
                            </p>
                          </section>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-x-2 mt-2">
                      <div className="3xl:w-[31.063rem] lg:w-[25rem] 3xl:h-[39.5rem] lg:h-[32rem] flex flex-col">
                        <HtmlEditor handleHTMLChange={handleHTMLChange} htmlContentInit={htmlContent} />
                      </div>
                      <div className="3xl:w-[31.063rem] lg:w-[25rem] 3xl:h-[39.5rem] lg:h-[32rem] flex flex-col">
                        <div className="h-full overflow-auto bg-darkBlue rounded-lg p-4 flex flex-col items-center [&::-webkit-scrollbar]:h-1 [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-track]:bg-gray-200 [&::-webkit-scrollbar-thumb]:bg-gray-600">
                          <div className="w-full min-w-fit mt-6 3xl:scale-x-[0.75] lg:scale-x-[0.61] scale-y-[1] editor-preview text-gray-300">
                            {editorSanitizedHtml && parse(htmlContent)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="absolute top-[8.25rem] right-0 px-8 h-attributesSection 3xl:w-[23rem] lg:w-[20rem] border-l border-gray-800 overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
                    <h2 className="text-xs font-semibold text-yellow-500 mb-4">
                      {t('NewEmailPage.advancedSettingsLabel')}
                    </h2>
                    <div className="mb-3">
                      <div className="bg-darkBlue w-fit p-1 rounded-xl flex flex-row gap-x-2 relative">
                        <Button
                          type="button"
                          variant={isAdvancedSettingsOptionSelected ? 'primary' : 'transparent'}
                          className="!h-10 3xl:!w-[9.656rem] lg:!w-[8rem]"
                          onClick={() => setIsAdvancedSettingsOptionSelected(true)}
                        >
                          {t('NewEmailPage.buttonEmailAddress')}
                        </Button>
                        <Button
                          type="button"
                          variant={isAdvancedSettingsOptionSelected ? 'transparent' : 'primary'}
                          className="!h-10 3xl:!w-[9.656rem] lg:!w-[8rem]"
                          onClick={() => setIsAdvancedSettingsOptionSelected(false)}
                        >
                          {t('NewEmailPage.buttonFiltering')}
                        </Button>
                      </div>
                    </div>
                    {isAdvancedSettingsOptionSelected ? (
                      <>
                        <SearchField withFilter={false} className="3xl:!w-[16.8rem] lg:!w-[13.5rem]" />
                        <h2 className="text-xs font-semibold text-yellow-500 mt-8">{t('NewEmailPage.resultsLabel')}</h2>
                      </>
                    ) : (
                      <h2 className="text-xs font-semibold text-yellow-500 mt-8">FILTERS</h2>
                    )}
                  </div>
                  <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                    <div className="flex items-center justify-between min-w-[40rem]">
                      <Button variant="cancel" onClick={() => navigate(AppRoutes.delivery)}>
                        {t('NewEmailPage.linkCancel')}
                      </Button>
                      <div className="flex flex-row gap-2">
                        <Button
                          type="button"
                          variant="previous"
                          className="pr-4 !w-[8.125rem]"
                          onClick={onBack}
                          iconLeft={<ChevronNewIcon type="left" color="#F5F7FC" width="20" height="20" />}
                        >
                          {t('NewEmailPage.buttonPrevious')}
                        </Button>
                        <Button
                          type="button"
                          className="group pl-4 !w-[8.125rem]"
                          onClick={onNext}
                          iconRight={
                            <span className="group-hover:[&>*_path]:stroke-gray-900 group-active:[&>*_path]:stroke-white">
                              <ChevronNewIcon type="right" color="#1C222A" width="20" height="20" />
                            </span>
                          }
                        >
                          {t('NewEmailPage.buttonNext')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Review step */}
              {(settings === t('NewEmailPage.templateOption') ? activeStep === 3 : activeStep === 2) && (
                <div className="w-[31.938rem]">
                  <h1 className="text-white font-medium text-xl mb-2">{t('NewEmailPage.previewLabel')}</h1>
                  <Input
                    placeholder={t('NewEmailPage.messageTitlePlaceholder')}
                    label={t('NewEmailPage.messageTitleLabel')}
                    {...register('messageTitle')}
                    error={errors.messageTitle?.message as string}
                    value={getValues('messageTitle')}
                    className="pointer-events-none"
                  />
                  <section className="mt-3 space-y-5">
                    <h2 className="mb-4 text-xs font-semibold text-yellow-500">
                      {t('NewEmailPage.audienceReceivingEmailLabel')}
                    </h2>
                    {isEveryoneShouldReceiveMsgActive && (
                      <span className="font-normal text-sm leading-[24px] text-gray-200">
                        {t('NewEmailPage.everyoneShouldReceiveThisMessageLabel')}
                      </span>
                    )}
                    {getValues('segmentsShouldInclude') && getValues('segmentsShouldInclude').length > 0 && (
                      <div className="flex flex-col gap-y-3">
                        <span className="font-['Montserrat'] text-xs font-medium leading-[14px] tracking-[0.02em] text-primary-400">
                          {t('NewEmailPage.belongToSegmentLabel')}
                        </span>
                        {getValues('segmentsShouldInclude') && getValues('segmentsShouldInclude').length > 0 && (
                          <div className="flex flex-row flex-wrap gap-y-2">
                            {getValues('segmentsShouldInclude')?.map(
                              (singleItem: { value: string; label: string }, index: number) => (
                                <React.Fragment key={index}>
                                  <Chip label={singleItem.label} className={'mx-1'} />
                                </React.Fragment>
                              ),
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {getValues('segmentsShouldNotInclude') && getValues('segmentsShouldNotInclude').length > 0 && (
                      <div className="flex flex-col gap-y-3">
                        <span className="font-['Montserrat'] text-xs font-medium leading-[14px] tracking-[0.02em] text-red-400">
                          {t('NewEmailPage.dontBelongToSegmentLabel')}
                        </span>

                        {getValues('segmentsShouldNotInclude') && getValues('segmentsShouldNotInclude').length > 0 && (
                          <div className="flex flex-row flex-wrap gap-y-2">
                            {getValues('segmentsShouldNotInclude')?.map(
                              (singleItem: { value: string; label: string }, index: number) => (
                                <React.Fragment key={index}>
                                  <Chip label={singleItem.label} className={'mx-1'} />
                                </React.Fragment>
                              ),
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </section>
                  <section className="mt-4">
                    <h2 className="mb-4 text-xs font-semibold text-yellow-500">{t('NewEmailPage.emailLabel')}</h2>
                    <table className="table-fixed w-full">
                      <tbody>
                        <tr className="border-b border-gray-800">
                          <td className="w-32 pb-2 text-xs font-normal leading-[14px] tracking-[0.02em] text-white">
                            {t('NewEmailPage.subjectPlaceholder')}
                          </td>
                          <td className="pb-2 text-sm font-normal leading-5 tracking-[0.02em] text-white">
                            {getValues('subject')}
                          </td>
                        </tr>
                        <tr className="border-b border-gray-800">
                          <td className="py-2 text-xs font-normal leading-[14px] tracking-[0.02em] text-white">
                            {t('NewEmailPage.fromNamePlaceholder')}
                          </td>
                          <td className="py-2 text-sm font-normal leading-5 tracking-[0.02em] text-white">
                            {getValues('fromName')}
                          </td>
                        </tr>
                        <tr className="border-b border-gray-800">
                          <td className="py-2 text-xs font-normal leading-[14px] tracking-[0.02em] text-white">
                            {t('NewEmailPage.fromEmailPlaceholder')}
                          </td>
                          <td className="py-2 text-sm font-normal leading-5 tracking-[0.02em] text-white">
                            {getValues('fromEmail')}
                          </td>
                        </tr>
                        <tr className="border-b border-gray-800">
                          <td className="py-2 text-xs font-normal leading-[14px] tracking-[0.02em] text-white">
                            {t('NewEmailPage.replyToEmailPlaceholder')}
                          </td>
                          <td className="py-2 text-sm font-normal leading-5 tracking-[0.02em] text-white">
                            {getValues('replyEmail')}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                  {htmlContent.length > 0 && (
                    <div className="mt-5 w-[31.938rem] max-h-[46.875rem] flex flex-col">
                      <div className="h-full overflow-auto bg-darkBlue rounded-lg p-4 flex flex-col items-center [&::-webkit-scrollbar]:h-1 [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-track]:bg-gray-200 [&::-webkit-scrollbar-thumb]:bg-gray-600">
                        <div className="w-full min-w-fit scale-x-[0.8] scale-y-[1] editor-preview text-gray-300">
                          {editorSanitizedHtml && parse(htmlContent)}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                    <div className="flex items-center justify-between min-w-[40rem]">
                      <Button variant="cancel" onClick={() => navigate(AppRoutes.delivery)}>
                        {t('NewEmailPage.linkCancel')}
                      </Button>
                      <div className="flex flex-row gap-2">
                        <Button
                          type="button"
                          variant="previous"
                          className="pr-4 !w-[8.125rem]"
                          onClick={onBack}
                          iconLeft={<ChevronNewIcon type="left" color="#F5F7FC" width="20" height="20" />}
                        >
                          {t('NewEmailPage.buttonPrevious')}
                        </Button>
                        <Button
                          className="px-4 !w-40"
                          onClick={handleSubmit(onSubmit)}
                          isLoading={sendEmailMutation.isLoading || sendEmailMutation.isSuccess}
                        >
                          {t('NewEmailPage.buttonSend')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </FormProvider>
        </div>
        <ProvideToasts>
          <Toast
            isOpen={open}
            setOpen={setOpen}
            message={toastInfo.message}
            toastType={toastInfo.toastType as ToastType}
          />
        </ProvideToasts>
        <EmailTemplateModal
          onCancel={() => {
            handleEmailTemplateModal(false);
            setSelectedTemplate(undefined);
            setSelectedTemplateHtmlContent('');
          }}
          isOpen={showEmailTemplateModal}
          template={selectedTemplate}
          templateData={selectedTemplateHtmlContent}
          setTemplateInUse={setTemplateInUse}
          setHtmlContent={setHtmlContent}
          setShowEmailTemplateModal={handleEmailTemplateModal}
        />
      </div>
    </div>
  );
};

export default NewEmailPage;
