import * as TooltipPrimitive from '@radix-ui/react-tooltip';

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  side?: 'bottom' | 'top' | 'right' | 'left';
  triggerClassName?: string;
  contentClassName?: string;
  arrowClassName?: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  side = 'bottom',
  triggerClassName,
  contentClassName,
  arrowClassName,
  ...props
}) => (
  <TooltipPrimitive.Provider>
    <TooltipPrimitive.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange} delayDuration={200}>
      <TooltipPrimitive.Trigger asChild className={triggerClassName}>
        {children}
      </TooltipPrimitive.Trigger>
      <TooltipPrimitive.Content side={side} className={contentClassName} {...props}>
        {content}
        <TooltipPrimitive.Arrow className={arrowClassName} />
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Root>
  </TooltipPrimitive.Provider>
);

export default Tooltip;
