import React, { ButtonHTMLAttributes, forwardRef, PropsWithChildren } from 'react';

import LoadingSpinnerIcon from '../Icons/LoadingSpinner/LoadingSpinner';

export type ButtonSize = 'large' | 'small';
export type ButtonVariant = 'primary' | 'secondary' | 'cancel' | 'destructive' | 'transparent' | 'previous';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
  isLoading?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  (
    { variant = 'primary', size = 'small', iconLeft, iconRight, isLoading = false, className = '', children, ...rest },
    ref,
  ) => {
    let buttonClasses =
      'flex items-center justify-center space-x-2 rounded-lg focus:outline-none tracking-[0.32px] text-sm font-medium';
    let spinnerColor = '';
    switch (variant) {
      case 'primary':
        buttonClasses += ` bg-primary-400 text-gray-900 hover:bg-primary-500 hover:border hover:border-primary-400 active:bg-primary-600 active:text-white active:border-none disabled:bg-primary-400 disabled:opacity-50 disabled:pointer-events-none ${
          isLoading && 'bg-primary-400 text-gray-800 pointer-events-none'
        }`;
        spinnerColor += '#2F6A4F';
        break;
      case 'secondary':
        buttonClasses += ` bg-secondary-400 text-gray-900 hover:bg-secondary-500 hover:border hover:border-secondary-400 active:bg-secondary-600 active:text-white active:border-none disabled:bg-secondary-400 disabled:opacity-50 disabled:pointer-events-none ${
          isLoading && 'bg-secondary-400 text-gray-900 pointer-events-none'
        }`;
        spinnerColor += '#000000';
        break;
      case 'cancel':
        buttonClasses += ` bg-transparent text-gray-100 hover:bg-cancelButtonHoverBG active:bg-gray-400 active:text-gray-800 disabled:bg-transparent disabled:opacity-50 disabled:pointer-events-none ${
          isLoading && 'bg-transparent text-gray-100 pointer-events-none'
        }`;
        spinnerColor += '#F7F7F7';
        break;
      case 'destructive':
        buttonClasses += ` bg-errorRed-400 text-white hover:bg-errorRed-500 active:bg-errorRed-600 disabled:bg-errorRed-100 disabled:text-errorRed-500 disabled:pointer-events-none ${
          isLoading && 'bg-errorRed-400 text-white pointer-events-none'
        }`;
        spinnerColor += '#FFFFFF';
        break;
      case 'transparent':
        buttonClasses += ` bg-transparent text-secondary-400 hover:bg-transparentButtonHoverBg active:bg-transparentButtonActiveBg active:text-secondary-100 disabled:bg-transparent disabled:text-secondary-400 disabled:opacity-50 disabled:pointer-events-none ${
          isLoading && 'bg-transparent text-secondary-400 pointer-events-none'
        }`;
        spinnerColor += '#7898FB';
        break;
      case 'previous':
        buttonClasses += ` bg-gray-800 text-white hover:bg-gray-600 hover:border hover:border-gray-500 active:bg-gray-500 active:border-none disabled:bg-gray-800 disabled:text-gray-50 disabled:opacity-50 disabled:pointer-events-none ${
          isLoading && 'bg-gray-800 text-white pointer-events-none'
        }`;
        spinnerColor += '#FFFFFF';
        break;
      default:
        break;
    }

    if (size === 'large') {
      buttonClasses += ' h-[3.5rem] w-[16.25rem]';
    }
    if (size === 'small') {
      buttonClasses += ' h-12 min-w-fit w-36';
    }

    return (
      <button ref={ref} className={`${buttonClasses} ${className}`} {...rest}>
        {iconLeft && iconLeft}
        <div className="flex flex-row gap-2 items-center justify-center">
          {isLoading && <LoadingSpinnerIcon color={spinnerColor} />}
          {children}
        </div>
        {iconRight && iconRight}
      </button>
    );
  },
);
