import { useQuery } from 'react-query';
import axios from 'axios';

import { useAppSelector } from '@/app/hooks';
import { Domain, DomainsPerWorkspace } from '@/models/Domain';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { AwsConfigApiGateway } from '@/utils/config/api';

import queryKeys from '../../modules/shared/query/queryKeys';

async function getDomains(token: string | undefined, workspaceId: string) {
  const response = await axios.get<DomainsPerWorkspace>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/workspaces/${workspaceId}/domains`,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data.data;
}

export function useGetDomains() {
  const { user } = useAuth();
  const workspace = useAppSelector(selectCurrentWorkspace);
  return useQuery<Domain[]>({
    queryKey: [queryKeys.domainNames],
    queryFn: () => getDomains(user?.signInUserSession.idToken.jwtToken, workspace?.id ?? ''),
  });
}
