export const readFileAsBase64 = (file: File | null) => {
  return new Promise<string>((resolve, reject) => {
    if (!file) {
      return null;
    }

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        reject(new Error('File is empty.'));
      } else if (typeof reader.result === 'string') {
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      } else {
        reject(new Error('Unexpected result type.'));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const readFileListFileAsBase64 = async (fileList: File | undefined) => {
  if (fileList instanceof FileList && fileList.length > 0) {
    const file = fileList[0];
    const base64String = await readFileAsBase64(file);
    return base64String;
  } else {
    return null;
  }
};

export const getFileName = (fileList: File | undefined): string => {
  if (fileList instanceof FileList && fileList.length > 0) {
    const file = fileList[0];
    return file ? file.name : '';
  }
  return 'key';
};
