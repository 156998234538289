import { SVGProps } from 'react';

interface VolumeIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const VolumeIcon: React.FC<VolumeIconProps> = ({ color = '#9bafd0', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M15.6833 4.10184C16.5159 4.87824 17.1806 5.817 17.6363 6.86021C18.0921 7.90342 18.3293 9.02895 18.3332 10.1674C18.3372 11.3058 18.1079 12.4329 17.6594 13.4793C17.211 14.5257 16.5528 15.469 15.7257 16.2512M13.41 6.53968C13.9095 7.00552 14.3083 7.56878 14.5818 8.19471C14.8552 8.82063 14.9975 9.49595 14.9999 10.179C15.0023 10.862 14.8647 11.5383 14.5956 12.1662C14.3266 12.794 13.9317 13.36 13.4354 13.8293M6.23354 13.0355L7.62957 14.7631C8.35731 15.6637 8.72119 16.114 9.03998 16.163C9.31595 16.2054 9.59483 16.1068 9.78281 15.9004C9.99996 15.6619 9.99996 15.0829 9.99996 13.9251V6.46784C9.99996 5.30996 9.99996 4.73103 9.78281 4.49253C9.59483 4.28608 9.31595 4.18748 9.03998 4.22991C8.72119 4.27893 8.35732 4.72922 7.62957 5.62981L6.23354 7.35739C6.08652 7.53933 6.01301 7.63029 5.92206 7.69575C5.84148 7.75374 5.75118 7.79687 5.65543 7.8231C5.54735 7.8527 5.43039 7.8527 5.19648 7.8527H4.01038C3.38021 7.8527 3.06512 7.8527 2.81049 7.93682C2.30938 8.10236 1.91629 8.49546 1.75075 8.99656C1.66663 9.2512 1.66663 9.56628 1.66663 10.1965C1.66663 10.8266 1.66663 11.1417 1.75075 11.3963C1.91629 11.8974 2.30938 12.2905 2.81049 12.4561C3.06512 12.5402 3.38021 12.5402 4.01038 12.5402H5.19648C5.43039 12.5402 5.54735 12.5402 5.65543 12.5698C5.75118 12.596 5.84148 12.6392 5.92206 12.6971C6.01301 12.7626 6.08652 12.8536 6.23354 13.0355Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VolumeIcon;
