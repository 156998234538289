import { useEffect, useRef, useState } from 'react';

import useOutsideClick from '@/modules/shared/hooks/useOutsideClick';

interface DropDownOption {
  icon?: JSX.Element;
  text: string;
}

interface DropDownProps {
  options: DropDownOption[];
  selectedOption?: string;
  trigger: React.ReactNode;
  onSelect: (option: DropDownOption) => void;
  className?: string;
}

const DropDown: React.FC<DropDownProps> = ({ options, selectedOption, trigger, onSelect, className = '' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const divWhereToScroll = useRef<HTMLDivElement>(null);

  const toggleDropDown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: DropDownOption) => {
    onSelect(option);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen && divWhereToScroll) {
      divWhereToScroll.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isOpen]);

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  return (
    <div ref={dropdownRef} className="relative inline-block text-left z-20">
      <div onClick={toggleDropDown}>{trigger}</div>
      {isOpen && (
        <div
          className={`absolute right-0 top-8 w-44 z-10 mt-2 p-2 pb-2 px-2 bg-custom-darkBlue rounded-lg border-[0.063rem] border-gray-800 whitespace-nowrap ${className}`}
          ref={divWhereToScroll}
        >
          {options.map((option, index) => (
            <div
              key={index}
              className={`group flex flex-row items-center mb-0.5 ${
                !option.icon ? 'justify-center' : ''
              } gap-2 px-2 py-3 cursor-pointer rounded hover:bg-activeSideMenuOption text-custom-aliceBlue hover:text-primary-400 ${
                selectedOption == option.text ? 'bg-activeSideMenuOption text-primary-400' : ''
              } ${option.text === 'Sign Out' ? 'buttonSignOut' : ''} ${
                option.text === 'My Account' ? 'buttonMyAccount' : ''
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.icon && <span className="group-hover:[&>*_path]:stroke-primary-400">{option.icon}</span>}
              <span className="font-light text-sm tracking-[0.0175rem]">{option.text}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDown;
