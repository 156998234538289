import { SVGProps } from 'react';

interface CircleWarningIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
  width?: string;
  height?: string;
}

const CircleWarningIcon: React.FC<CircleWarningIconProps> = ({
  color = '#D81212',
  width = '18',
  height = '18',
  ...props
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M8 5.63346V8.30013M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14ZM8.0332 10.3001V10.3668L7.9668 10.3665V10.3001H8.0332Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CircleWarningIcon;
