interface BadgeProps {
  number: number;
  icon?: React.ReactElement;
  className?: string;
}

const Badge: React.FC<BadgeProps> = ({ number, icon, className }) => {
  const badgeNumber = number > 99 ? '99+' : number;
  const shouldApplyPadding = number > 99;

  const badgeClasses = `w-[24px] h-6 bg-primary-400 text-center text-sm font-montserrat font-medium rounded-full flex justify-center items-center ${
    shouldApplyPadding ? 'w-[24px] h-7 pl-2 pr-2' : ''
  } ${className}`;

  return <div className={badgeClasses}>{icon ? icon : badgeNumber}</div>;
};

export default Badge;
