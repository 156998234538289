/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetEmailMetricsByWorkspace } from '@/api/dashboard/useGetEmailMetricsByWorkspace';
import { useAppSelector } from '@/app/hooks';
import BigColoredText from '@/component-library/primitives/BigColoredText/BigColoredText';
import CircleBarWithText from '@/component-library/primitives/CircleBarWithText/CircleBarWithText';
import ChartVerticalBarIcon from '@/component-library/primitives/Icons/CharctVerticalBarIcon/ChartVerticalBarIcon';
import CircleWarningIcon from '@/component-library/primitives/Icons/CircleWarningIcon/CircleWarningIcon';
import LayersIcon from '@/component-library/primitives/Icons/LayersIcon/LayersIcon';
import Loader from '@/component-library/primitives/Loader/Loader';
import BarChart from '@/component-library/widgets/BarChart/BarChart';
import { Input } from '@/component-library/widgets/Input/Input';
import PieChart from '@/component-library/widgets/PieChart/PieChart';
import DashboardSection from '@/modules/Dashboard/DashboardPage/DashboardSection/DashboardSection';
import DeliveryTableMini from '@/modules/Dashboard/Delivery/DeliveryTableMini/DeliveryTableMini';
import { roundNumber } from '@/utils/numberUtils';

import { selectCurrentWorkspace } from '../Workspaces/workspaceSlice';

import './styles.css';
// keeping this data for testing purposes
const mockMetricsData = [
  {
    date: '2024-12-30T09:12:46.905Z',
    delivered: 1400,
    opened: 1100,
  },
  {
    date: '2024-12-31T09:12:46.905Z',
    delivered: 1200,
    opened: 750,
  },
  {
    date: '2025-01-03T09:12:46.905Z',
    delivered: 1200,
    opened: 850,
  },
  {
    date: '2025-01-13T09:12:46.905Z',
    delivered: 1200,
    opened: 1100,
  },
  {
    date: '2025-01-23T09:12:46.905Z',
    delivered: 1200,
    opened: 850,
  },
  {
    date: '2025-03-30T09:12:46.905Z',
    delivered: 1400,
    opened: 850,
  },
  {
    date: '2025-03-31T09:12:46.905Z',
    delivered: 1300,
    opened: 720,
  },
  {
    date: '2025-07-01T09:12:46.905Z',
    delivered: 900,
    opened: 520,
  },
  {
    date: '2025-07-02T09:12:46.905Z',
    delivered: 900,
    opened: 380,
  },
  {
    date: '2026-07-02T09:12:46.905Z',
    delivered: 900,
    opened: 390,
  },
  {
    date: '2026-07-03T09:12:46.905Z',
    delivered: 900,
    opened: 490,
  },
  {
    date: '2026-08-03T09:12:46.905Z',
    delivered: 900,
    opened: 850,
  },
];

const DashboardPage = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const selectedWorkspace = useAppSelector(selectCurrentWorkspace);
  const tzoffset = new Date().getTimezoneOffset() * 60000;
  // TODO: uncomment this to just get the last 2 weeks before today
  // const [startDate, setStartDate] = useState(
  //   new Date(new Date(Date.now() - tzoffset).setDate(new Date().getDate() - 15)).toISOString().slice(0, -8),
  // );
  const [startDate, setStartDate] = useState(
    new Date(new Date(new Date(selectedWorkspace?.createdAt ?? Date.now()).getTime() - tzoffset))
      .toISOString()
      .slice(0, -8),
  );
  const [endDate, setEndDate] = useState(new Date(Date.now() - tzoffset).toISOString().slice(0, -8));

  const formatedStartDate = !isNaN(new Date(startDate) as any) ? new Date(startDate).toISOString() : '';
  const formatedEndDate = !isNaN(new Date(endDate) as any) ? new Date(endDate).toISOString() : '';
  const { data, isLoading } = useGetEmailMetricsByWorkspace(formatedStartDate, formatedEndDate);

  const timelineWithoutZeroes = useMemo(
    () => data?.data.emailMetrics.timeline.data.filter((item) => item.delivered > 0),
    [data],
  );

  let errorMessage = '';

  if (new Date(startDate) > new Date(endDate)) {
    // @ts-ignore
    errorMessage = t('DashboardPage.endDateAfterStartDateErrorMsg');
  } else if (new Date(endDate) > new Date()) {
    errorMessage = t('DashboardPage.endDateAfterCurrentDateErrorMsg');
  }

  const formatedMetricsData = data?.data.emailMetrics;

  return (
    <div className="mt-5">
      <div className="grid-container">
        <div className="email-header">
          <div className="flex flex-row items-center gap-x-2 mb-8">
            <ChartVerticalBarIcon />
            <h2 className="text-white font-medium text-xl">{t('DashboardPage.emailMetricsLabel')}</h2>
            <div className="flex flex-row justify-between ml-8">
              <div>
                <Input
                  // @ts-ignore
                  label={t('DashboardPage.startDateLabel')}
                  type="datetime-local"
                  onChange={(e) => setStartDate(e.target.value)}
                  value={startDate}
                />
              </div>
              <div className="ml-8">
                <Input
                  label={t('DashboardPage.endDateLabel')}
                  type="datetime-local"
                  onChange={(e) => setEndDate(e.target.value)}
                  value={endDate}
                />
              </div>
            </div>
          </div>
          {errorMessage.length > 0 && (
            <p className="text-errorRed-500 mt-2 text-xs font-medium flex flex-row items-center gap-2">
              <CircleWarningIcon /> {errorMessage}
            </p>
          )}
        </div>
        {isLoading ? (
          <div className="absolute w-[80%] h-[80%]">
            <Loader />
          </div>
        ) : errorMessage.length > 0 ? (
          <></>
        ) : (
          <>
            <div className="total-sent">
              <DashboardSection title={t('DashboardPage.total')} contentCentered>
                <BigColoredText number={formatedMetricsData?.totals.totalSent} color="#7E6CF5" />
              </DashboardSection>
            </div>
            <div className="total-delivered">
              <DashboardSection title={t('DashboardPage.totalDelivered')}>
                <BigColoredText number={formatedMetricsData?.totals.totalDelivered} color="#529F7C" />
              </DashboardSection>
            </div>
            <div className="delivery-rate">
              <DashboardSection title="Delivery rate">
                <CircleBarWithText
                  circleSvgId="delivery-rate"
                  fillPercentage={roundNumber(formatedMetricsData?.rates.deliveryRate ?? 0)}
                  color="#529F7C"
                  textColor="#529F7C"
                />
              </DashboardSection>
            </div>
            <div className="delivery-table">
              <DashboardSection
                titleCentered={false}
                contentCentered
                title={
                  <div className="flex flex-row items-center gap-x-2 mb-2">
                    <LayersIcon />
                    <h3 className="text-white font-medium text-xl w-[100%]">{t('DashboardPage.delivery')}</h3>
                  </div>
                }
              >
                <DeliveryTableMini data={formatedMetricsData?.deliveries} />
              </DashboardSection>
            </div>
            <div className="open-rate">
              <DashboardSection title="Open to Delivered rate">
                <CircleBarWithText
                  circleSvgId="open-to-delivered-rate"
                  fillPercentage={roundNumber(formatedMetricsData?.rates.openRate ?? 0)}
                  color="#9BEBC6"
                  textColor="#9BEBC6"
                />
              </DashboardSection>
            </div>

            <div className="total-opened">
              <DashboardSection title="Total opened">
                <BigColoredText number={formatedMetricsData?.totals.totalOpened} color="#9BEBC6" />
              </DashboardSection>
            </div>
            <div className="click-to-open">
              <DashboardSection title="Click-to-Open rate">
                <BigColoredText
                  number={roundNumber(formatedMetricsData?.rates.clickToOpenRate ?? 0)}
                  color="#7898FB"
                  numberSizePx={60}
                  withPercentage
                />
              </DashboardSection>
            </div>
            <div className="complaint-rate">
              <DashboardSection title="Complaint rate" contentEnd>
                <BigColoredText
                  number={roundNumber(formatedMetricsData?.rates.complaintRate ?? 0)}
                  color="#E87171"
                  numberSizePx={60}
                  withPercentage
                />
              </DashboardSection>
            </div>
            <div className="delivery-status">
              <DashboardSection title={t('DashboardPage.deliveryByStatus')} titleCentered={false} contentCentered>
                <PieChart
                  data={[
                    {
                      name: 'Opened',
                      value: roundNumber(formatedMetricsData?.deliveryByStatus.opened || 0),
                    },
                    {
                      name: 'Not opened',
                      value: roundNumber(formatedMetricsData?.deliveryByStatus.notOpened || 0),
                    },
                    { name: 'Bounced', value: roundNumber(formatedMetricsData?.deliveryByStatus.bounced || 0) },
                    { name: 'Rejected', value: roundNumber(formatedMetricsData?.deliveryByStatus.rejected || 0) },
                    { name: 'Complaints', value: roundNumber(formatedMetricsData?.deliveryByStatus.complaints || 0) },
                  ]}
                  colors={[
                    { name: 'Opened', value: '#9BEBC6' },
                    { name: 'Not opened', value: '#EFF3FA' },
                    { name: 'Bounced', value: '#F5F069' },
                    { name: 'Rejected', value: '#FFC271' },
                    { name: 'Complaints', value: '#E87171' },
                  ]}
                />
              </DashboardSection>
            </div>

            <div className="delivered-vs-opened">
              <DashboardSection title={t('DashboardPage.deliveredVsOpened')} contentCentered>
                {/* keeping this call for testing purposes */}
                {/* {data && <BarChart data={mockMetricsData} />} */}
                {timelineWithoutZeroes?.length ? <BarChart data={timelineWithoutZeroes} /> : <h2>No data</h2>}
              </DashboardSection>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DashboardPage;
