import { useTranslation } from 'react-i18next';

import ChevronNewIcon from '@/component-library/primitives/Icons/ChevronNewIcon/ChevronNewIcon';
import DataFlowIcon from '@/component-library/primitives/Icons/DataFlowIcon/DataFlowIcon';
import SettingsFutureIcon from '@/component-library/primitives/Icons/SettingsFutureIcon/SettingsFutureIcon';
import { Segment, SegmentMembership } from '@/models/Segments';

import NoDataSection from '../NoDataSection';

interface SegmentsSectionProps {
  changeActiveStep: (step: string) => void;
  segmentsData: SegmentMembership | undefined;
}

const MAX_ITEMS = 5;

const SegmentsSection: React.FC<SegmentsSectionProps> = ({ changeActiveStep, segmentsData }) => {
  const { t } = useTranslation();
  return (
    <div className="px-5 pt-5 pb-7 w-full min-h-[20rem] max-h-[20rem] rounded-[10px] bg-darkBlue">
      <div className="flex flex-row items-center justify-between mb-3">
        <h2 className="text-gray-50 text-base font-normal tracking-[0.32px]">{t('MemberProfilePage.segmentsLabel')}</h2>
        <button
          onClick={() => changeActiveStep(t('MemberProfilePage.segmentsTabLabel'))}
          className="group hover:bg-iconButtonHoverBG rounded-lg w-8 h-8 flex items-center justify-center"
        >
          <span className="group-hover:[&>*_path]:stroke-gray-400">
            <ChevronNewIcon type="right" color="white" />
          </span>
        </button>
      </div>
      <div className="h-[13.5rem] overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
        <>
          {segmentsData && (segmentsData.belong.length > 0 || segmentsData.notBelong.length > 0) ? (
            <>
              {segmentsData.belong.length > 0 && (
                <>
                  <label className="text-xs text-secondary-400 font-semibold tracking-[0.24px]">
                    {t('SegmentsPage.belongToSegment')}
                  </label>
                  <div className="mt-3 flex flex-wrap items-start">
                    {segmentsData.belong.slice(0, MAX_ITEMS).map((segment: Segment) => {
                      return (
                        <div
                          key={segment.id}
                          className="flex flex-row items-center gap-2 rounded-[50px] bg-gray-800 w-fit py-2 px-4 mr-4 mb-4"
                        >
                          {segment.segmentType === 'manual' ? <SettingsFutureIcon /> : <DataFlowIcon color="#9BAFD0" />}
                          <span className="first-letter:uppercase text-gray-50 text-sm">{segment.name}</span>
                        </div>
                      );
                    })}
                    {segmentsData.belong.length > MAX_ITEMS && (
                      <div
                        className={
                          'w-[24px] h-6 bg-primary-400 text-center text-base font-montserrat font-normal rounded-full flex justify-center items-center !bg-secondary-400 pt-4 pb-4 min-w-[40px] min-h-[35px]'
                        }
                      >
                        {'+' + (segmentsData.belong.length - MAX_ITEMS)}
                      </div>
                    )}
                  </div>
                </>
              )}

              {segmentsData.notBelong.length > 0 && (
                <>
                  <label className="text-xs text-secondary-400 font-semibold tracking-[0.24px]">
                    {t('SegmentsPage.notBelongToSegment')}
                  </label>
                  <div className="mt-3 flex flex-wrap items-start">
                    {segmentsData.notBelong.slice(0, MAX_ITEMS).map((segment: Segment) => {
                      return (
                        <div
                          key={segment.id}
                          className="flex flex-row items-center gap-2 rounded-[50px] bg-gray-800 w-fit py-2 px-4 mr-4 mb-4"
                        >
                          {segment.segmentType === 'manual' ? <SettingsFutureIcon /> : <DataFlowIcon color="#9BAFD0" />}
                          <span className="first-letter:uppercase text-gray-50 text-sm">{segment.name}</span>
                        </div>
                      );
                    })}
                    {segmentsData.notBelong.length > MAX_ITEMS && (
                      <div
                        className={
                          'w-[24px] h-6 bg-primary-400 text-center text-base font-montserrat font-normal rounded-full flex justify-center items-center !bg-secondary-400 pt-4 pb-4 min-w-[40px] min-h-[35px]'
                        }
                      >
                        {'+' + (segmentsData.notBelong.length - MAX_ITEMS)}
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <NoDataSection />
          )}
        </>
      </div>
    </div>
  );
};

export default SegmentsSection;
