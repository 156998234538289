import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import AuthLayout from '@/component-library/layouts/AuthLayout/AuthLayout';
import { Button } from '@/component-library/primitives/Button/Button';
import ArrowIcon from '@/component-library/primitives/Icons/ArrowIcon/ArrowIcon';
import Link from '@/component-library/primitives/Link/Link';
import { Input } from '@/component-library/widgets/Input/Input';
import { ForgotPasswordData } from '@/models/ForgotPasswordData';
import { AppRoutes } from '@/utils/routes/router';

import { useAuth } from '../../hooks/useAuth';

const ForgotPasswordPage = () => {
  const { forgotPassword } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [attemptLimitExceeded, setAttemptLimitExceeded] = useState(false);

  const schema = useMemo(() => {
    return yup.object().shape({
      email: yup
        .string()
        .email(t('CommonErrors.emailFormatValidation'))
        .max(256)
        .required(t('CommonErrors.emailRequired')),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ email }: ForgotPasswordData) => {
    try {
      await forgotPassword(email);
      navigate(AppRoutes.resetPassword);
    } catch (error: any) {
      console.log('Something went wrong!');
      if (error.code === 'LimitExceededException') {
        setAttemptLimitExceeded(true);
      }
    }
  };
  return (
    <AuthLayout title={t('ForgotPasswordPage.title')} content={t('ForgotPasswordPage.subtitle')}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full space-y-4">
        <Input
          placeholder={t('ForgotPasswordPage.emailPlaceholder')}
          {...register('email')}
          label={t('ForgotPasswordPage.emailLabel')}
          error={errors.email?.message}
          data-cy="userEmail"
        />
        <Button className="w-full !my-5" data-cy="buttonSubmit">
          {t('ForgotPasswordPage.buttonSubmit')}{' '}
        </Button>
      </form>
      <Link
        to={AppRoutes.signIn}
        variant="secondary"
        label={t('ForgotPasswordPage.linkBackToSignin')}
        iconLeft={<ArrowIcon type="left" color="#7898FB" />}
      />
      {attemptLimitExceeded && (
        <p className="text-errorRed-500 mt-2 text-xs font-medium">{t('ResetPasswordPage.attemptLimitExceeded')}</p>
      )}
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
