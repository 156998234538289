import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import AuthLayout from '@/component-library/layouts/AuthLayout/AuthLayout';
import { Button } from '@/component-library/primitives/Button/Button';
import CircleWarningIcon from '@/component-library/primitives/Icons/CircleWarningIcon/CircleWarningIcon';
import Link from '@/component-library/primitives/Link/Link';
import { Input } from '@/component-library/widgets/Input/Input';
import { AppRoutes } from '@/utils/routes/router';

import { useAuth } from '../../hooks/useAuth';

type FormData = {
  email: string;
  password: string;
};

const SignInPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [signInError, setSignInError] = useState('');
  const { t } = useTranslation();

  const schema = useMemo(() => {
    return yup.object().shape({
      email: yup
        .string()
        .email(t('CommonErrors.emailFormatValidation'))
        .max(256)
        .required(t('CommonErrors.emailRequired')),
      password: yup.string().required(t('CommonErrors.passwordRequired')),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormData) => {
    try {
      const result = await auth.signIn(data.email, data.password);
      if (result.isSignInSuccess) {
        navigate(AppRoutes.dashboard);
      } else if (result.message === 'NEW_PASSWORD_REQUIRED') {
        navigate({ pathname: '/create-new-password' });
      } else {
        setSignInError(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <AuthLayout
      title={t('SignInPage.title')}
      content={t('SignInPage.subtitle')}
      titleClass="text-left"
      contentClass="text-left"
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-full space-y-4"
        data-testid="@auth/sign-in/form"
      >
        <Input
          placeholder={'example@email.com'}
          {...register('email', { required: 'Email is required!' })}
          error={errors.email?.message}
          label={t('SignInPage.emailPlaceholder')}
          data-testid="@auth/sign-in/email-input"
          data-cy="userEmail"
        />
        <Input
          placeholder={t('SignInPage.passwordPlaceholder')}
          type="password"
          {...register('password', { required: 'Password is required!' })}
          error={errors.password?.message}
          label={t('SignInPage.passwordPlaceholder')}
          data-testid="@auth/sign-in/password-input"
          data-cy="userPassword"
        />
        {signInError && (
          <p className="text-errorRed-500 mt-2 text-xs font-medium flex flex-row items-center gap-2">
            <CircleWarningIcon /> {signInError}
          </p>
        )}
        <Link
          to={AppRoutes.forgotPassword}
          label={t('SignInPage.linkForgotPassword')}
          variant="secondary"
          className="text-xs text-left !justify-start buttonForgotPassword"
        />
        <Button data-testid="@auth/sign-in/submit-button" className="w-full" data-cy="buttonSignInSubmit">
          {t('SignInPage.buttonSignIn')}
        </Button>
      </form>
      {/* <div className="flex">
        <p className="inline text-gray-400 text-sm">{t('SignInPage.noAccount')}</p>
        <Link
          className="inline text-primary-400 text-sm font-normal"
          to={AppRoutes.signUp}
          label={t('SignInPage.registerHere')}
        />
      </div> */}
    </AuthLayout>
  );
};

export default SignInPage;
