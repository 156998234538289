import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { useGetSegments } from '@/api/segments/useGetSegments';
import { useGetAudienceMemberById } from '@/api/workspaces/useGetAudienceMemberById';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  selectIsCollapsed,
  setCollapsedSidebar,
  setIsCollapsableIconHidden,
} from '@/component-library/layouts/SideMenu/sideMenuSlice';
import { Button } from '@/component-library/primitives/Button/Button';
// import { Button } from '@/component-library/primitives/Button/Button';
// import ChevronIcon from '@/component-library/primitives/Icons/ChevronIcon/ChevronIcon';
// import ExternalLinkIcon from '@/component-library/primitives/Icons/ExternalLinkIcon/ExternalLinkIcon';
import Loader from '@/component-library/primitives/Loader/Loader';
import NoDataBoard from '@/component-library/widgets/NoDataBoard/NoDataBoard';
import Table from '@/component-library/widgets/Table/Table';
import TabMenu from '@/component-library/widgets/TabMenu/TabMenu';
import { MemberOverview } from '@/models/AudienceByWorkspace';
import { Segment } from '@/models/Segments';
import { AppRoutes } from '@/utils/routes/router';

import { useSegmentsTable } from '../../Segments/useSegmentsTable';

// import useOutsideClick from '@/modules/shared/hooks/useOutsideClick';
import OverviewPage from './OverviewPage/OverviewPage';
import MemberProfileSideMenu from './MemberProfileSideMenu';

const MemberProfile = () => {
  const { t } = useTranslation();
  const { id: memberId } = useParams();
  const { data, isFetched, isLoading } = useGetAudienceMemberById(memberId);

  const { data: segmentsData, isLoading: segmentsLoading } = useGetSegments();
  const { columns } = useSegmentsTable();
  const [showBelongsTo, setShowBelongsTo] = useState(true);

  const [member, setMember] = useState<MemberOverview | undefined>(undefined);
  // const [isOptionsDropdownOpen, setIsOptionsDropdownOpen] = useState(false);
  const collapsedSidebar = useAppSelector(selectIsCollapsed);
  const [previousCollapsedState, setPreviousCollapsedState] = useState(collapsedSidebar);
  // const dropdownRef = useRef(null);
  const navigate = useNavigate();
  // const menuOptions = [
  //   { name: 'Dummy option 1', route: '#' },
  //   { name: 'Dummy option 2', route: '#' },
  // ];

  // const toggleDropdown = () => {
  //   setIsOptionsDropdownOpen(!isOptionsDropdownOpen);
  // };

  // useOutsideClick(dropdownRef, () => setIsOptionsDropdownOpen(false));

  const filteredSegments = useMemo(() => {
    const segmentValues = member?.membership?.segments?.in.map((segment) => Object.values(segment)).flat() || [];
    const belong: Segment[] = [];
    const notBelong: Segment[] = [];
    segmentsData?.forEach((segment) => {
      if (segmentValues.includes(segment.id)) {
        belong.push(segment);
      } else {
        notBelong.push(segment);
      }
    });
    return { belong, notBelong };
  }, [segmentsData, member]);

  const handleFilterClick = (belongTo: boolean) => {
    setShowBelongsTo(belongTo); // Update state to show segments that belong or don't belong
  };

  const tabLabels = [
    t('MemberProfilePage.overviewTabLabel'),
    t('MemberProfilePage.attributesTabLabel'),
    t('MemberProfilePage.activitiesTabLabel'),
    t('MemberProfilePage.segmentsTabLabel'),
    t('MemberProfilePage.devicesTabLabel'),
    t('MemberProfilePage.subscriptionsTabLabel'),
    t('MemberProfilePage.campaignsTabLabel'),
  ];
  const [activeStep, setActiveStep] = useState(t('MemberProfilePage.overviewTabLabel'));

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setCollapsedSidebar(true));
    dispatch(setIsCollapsableIconHidden(true));
    isFetched && setMember(data);
    return () => {
      !previousCollapsedState && dispatch(setCollapsedSidebar(false));
      dispatch(setIsCollapsableIconHidden(false));
    };
  }, [dispatch, isFetched]);

  const changeActiveStep = (step: string) => {
    setActiveStep(step);
  };

  return (
    <div>
      {isLoading && (
        <div className="absolute w-[90%] h-[70%] z-50">
          <Loader />
        </div>
      )}
      {/* Member profile side menu */}
      <MemberProfileSideMenu memberData={data} />
      <div className="ml-[16.688rem] overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] h-full">
        {/* Tab menu with action buttons */}
        <div className="flex flex-row items-center justify-between">
          <TabMenu tabLabels={tabLabels} activeStep={activeStep} changeActiveStep={changeActiveStep} />
          {/* <div className="flex flex-row items-center gap-3">
            <Button variant="secondary" className="group !w-12">
              <span className="group-active:[&>*_path]:stroke-white">
                <ExternalLinkIcon />
              </span>
            </Button>
            <div className="relative">
              <Button variant="secondary" onClick={toggleDropdown} ref={dropdownRef} className="group">
                <span className="flex flex-row gap-3 justify-center items-center">
                  <span>{t('MemberProfilePage.buttonOptions')}</span>
                  <span className="group-hover:[&>*_path]:fill-gray-900 group-active:[&>*_path]:fill-white">
                    <ChevronIcon color="#1C222A" type={isOptionsDropdownOpen ? 'up' : 'down'} />
                  </span>
                </span>
              </Button>
              {isOptionsDropdownOpen && (
                <div className="absolute right-0 top-12 w-fit z-10 mt-2 p-2 pb-2 px-2 bg-custom-darkBlue rounded-lg border-[0.063rem] border-gray-800 whitespace-nowrap">
                  {menuOptions.map((option, index) => (
                    <div
                      key={index}
                      className="group flex flex-row items-center gap-2 px-2 py-3 cursor-pointer rounded hover:bg-activeSideMenuOption text-custom-aliceBlue hover:text-primary-400"
                      onClick={() => navigate(option.route)}
                    >
                      <span className="font-light text-sm tracking-[0.0175rem]">{option.name}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div> */}
        </div>
        {/* Pages */}
        <div className="mt-6">
          {activeStep === t('MemberProfilePage.overviewTabLabel') && (
            <OverviewPage memberData={member} changeActiveStep={changeActiveStep} segmentsData={filteredSegments} />
          )}
          {activeStep === t('MemberProfilePage.attributesTabLabel') && (
            <div className="h-[100vh]">
              <h2 className="text-gray-50 text-base font-normal tracking-[0.32px]">
                {t('MemberProfilePage.attributesTabLabel')}
              </h2>
            </div>
          )}
          {activeStep === t('MemberProfilePage.activitiesTabLabel') && (
            <div className="h-[100vh]">
              <h2 className="text-gray-50 text-base font-normal tracking-[0.32px]">
                {t('MemberProfilePage.activitiesTabLabel')}
              </h2>
            </div>
          )}
          {activeStep === t('MemberProfilePage.segmentsTabLabel') && (
            <div className="h-[100vh]">
              <h2 className="text-gray-50 text-base font-normal tracking-[0.32px]">
                {t('MemberProfilePage.segmentsTabLabel')}
              </h2>
              <div>
                {segmentsLoading ? (
                  <Loader />
                ) : segmentsData && segmentsData.length > 0 ? (
                  showBelongsTo && filteredSegments.belong.length > 0 ? (
                    <Table
                      columns={columns}
                      data={filteredSegments.belong}
                      className={{
                        cell: 'overflow-visible h-[7rem]',
                      }}
                    />
                  ) : !showBelongsTo && filteredSegments.notBelong.length > 0 ? (
                    <Table
                      columns={columns}
                      data={filteredSegments.notBelong}
                      className={{
                        cell: 'overflow-visible h-[7rem]',
                      }}
                    />
                  ) : (
                    <NoDataBoard className="p-40" />
                  )
                ) : (
                  <NoDataBoard
                    className="p-40"
                    description={t('NoDataBoard.addYourFirstSegment')}
                    actionButton={
                      <Button
                        className="!w-[10.5rem] mt-4"
                        data-cy="newSegmentButton"
                        onClick={() => navigate(AppRoutes.createSegment)}
                      >
                        {t('SegmentsPage.buttonNewSegment')}
                      </Button>
                    }
                  />
                )}
              </div>
              {segmentsData && segmentsData.length > 0 && (
                <div className="flex justify-center items-center">
                  <div className="flex space-x-4">
                    <span
                      onClick={() => handleFilterClick(true)}
                      className={`flex items-center justify-center gap-2 tracking-[0.32px] font-medium group text-secondary-400 hover:text-secondary-500 active:text-secondary-300 text-sm cursor-pointer ${
                        showBelongsTo ? 'underline' : ''
                      }`}
                    >
                      {t('SegmentsPage.belongToSegment')}
                    </span>
                    <span
                      onClick={() => handleFilterClick(false)}
                      className={`flex items-center justify-center gap-2 tracking-[0.32px] font-medium group text-secondary-400 hover:text-secondary-500 active:text-secondary-300 text-sm cursor-pointer ${
                        !showBelongsTo ? 'underline' : ''
                      }`}
                    >
                      {t('SegmentsPage.notBelongToSegment')}
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}

          {activeStep === t('MemberProfilePage.devicesTabLabel') && (
            <div className="h-[100vh]">
              <h2 className="text-gray-50 text-base font-normal tracking-[0.32px]">
                {t('MemberProfilePage.devicesTabLabel')}
              </h2>
            </div>
          )}
          {activeStep === t('MemberProfilePage.subscriptionsTabLabel') && (
            <div className="h-[100vh]">
              <h2 className="text-gray-50 text-base font-normal tracking-[0.32px]">
                {t('MemberProfilePage.subscriptionsTabLabel')}
              </h2>
            </div>
          )}
          {activeStep === t('MemberProfilePage.campaignsTabLabel') && (
            <div className="h-[100vh]">
              <h2 className="text-gray-50 text-base font-normal tracking-[0.32px]">
                {t('MemberProfilePage.campaignsTabLabel')}
              </h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MemberProfile;
