import React, { useEffect, useState } from 'react';

import Chip from '@/component-library/primitives/Chip/Chip';
import SortAscendingIcon from '@/component-library/primitives/Icons/SortAscendingIcon/SortAscendingIcon';
import SortDescendingIcon from '@/component-library/primitives/Icons/SortDescendingIcon/SortDescendingIcon';
import SearchField from '@/component-library/widgets/SearchField/SearchField';

interface PredefinedAttributesContainerProps {
  data: { id: number; label: string }[];
  appendAttribute: (key: string) => void;
}

interface FilteredResults {
  filteredData: { id: number; label: string }[];
  appendAttribute: (key: string) => void;
}

const FilteredResults: React.FC<FilteredResults> = ({ filteredData, appendAttribute }) => {
  return (
    <div className="flex flex-row flex-wrap gap-y-3">
      {filteredData?.map((item) => (
        <React.Fragment key={item.id}>
          <Chip
            label={`+ ${item.label}`}
            onClick={() => appendAttribute(item.label)}
            className={'hover:bg-gray-600 mx-1 cursor-pointer'}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

const PredefinedAttributesContainer: React.FC<PredefinedAttributesContainerProps> = ({ data, appendAttribute }) => {
  const [filteredData, setFilteredData] = useState<never | { id: number; label: string }[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [isDataSortedAscending, setIsDataSortedAscending] = useState(false);

  const filterResults = (searchValue: string) => {
    const filtData = data.filter((info) => {
      return info.label.toLowerCase().includes(searchValue.toLocaleLowerCase());
    });
    setFilteredData(filtData);
  };

  const sortResultsAlphabetically = () => {
    if (!isDataSortedAscending) {
      const sortedResults = filteredData.sort((a: { id: number; label: string }, b: { id: number; label: string }) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setFilteredData(sortedResults);
      setIsDataSortedAscending(!isDataSortedAscending);
    } else {
      const sortedResults = filteredData.sort((a: { id: number; label: string }, b: { id: number; label: string }) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return 1;
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      setFilteredData(sortedResults);
      setIsDataSortedAscending(!isDataSortedAscending);
    }
  };

  useEffect(() => {
    setFilteredData(data);
    filterResults(searchValue);
  }, [searchValue, data]);

  return (
    <>
      <div className="flex flex-row items-center gap-2">
        <SearchField
          withFilter={false}
          className="3xl:!w-[13rem] lg:!w-[11rem]"
          initialValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <button type="button" onClick={sortResultsAlphabetically}>
          {!isDataSortedAscending ? <SortAscendingIcon /> : <SortDescendingIcon />}
        </button>
      </div>
      <div className="mt-7">
        <FilteredResults filteredData={filteredData} appendAttribute={appendAttribute} />
      </div>
    </>
  );
};

export default PredefinedAttributesContainer;
