interface LinkedinIconProps {
  color?: string;
}

export default function LinkedinIcon({ color = 'white' }: LinkedinIconProps) {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.86207 0C4.4154 0 0 4.41541 0 9.86208V24.6552C0 30.1018 4.41541 34.5172 9.86208 34.5172H24.6552C30.1018 34.5172 34.5172 30.1018 34.5172 24.6552V9.86207C34.5172 4.4154 30.1018 0 24.6552 0H9.86207ZM26.4729 25.9705V19.1028C26.4729 15.4233 24.5085 13.711 21.8897 13.711C19.7764 13.711 18.8294 14.8738 18.3016 15.6895V13.9925H14.3201C14.3728 15.1166 14.3201 25.9705 14.3201 25.9705H18.3016V19.2811C18.3016 18.924 18.3274 18.5663 18.433 18.3106C18.7203 17.5952 19.3753 16.8546 20.4747 16.8546C21.9161 16.8546 22.4919 17.9529 22.4919 19.5625V25.9711L26.4729 25.9705ZM10.1257 12.3577C11.5136 12.3577 12.3786 11.437 12.3786 10.2871C12.3528 9.11261 11.5136 8.21838 10.1521 8.21838C8.79048 8.21838 7.89977 9.11202 7.89977 10.2871C7.89977 11.437 8.76409 12.3577 10.1005 12.3577H10.1257ZM12.1164 25.9705V13.9925H8.13549V25.9705H12.1164Z"
        fill={color}
        fillOpacity="0.58"
      />
    </svg>
  );
}
