import { useQuery } from 'react-query';
import axios from 'axios';

import { CompanyAccountData, CompanyAccountDetails } from '@/models/CompanyAccountDetails';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

import queryKeys from '../../modules/shared/query/queryKeys';

async function getAccountDetails(token: string | undefined) {
  const response = await axios.get<CompanyAccountData>(`${AwsConfigApiGateway.pooledTenantApiUrl}/tenants`, {
    headers: {
      'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data.data;
}

export function useGetAccountDetails() {
  const { user } = useAuth();

  return useQuery<CompanyAccountDetails>({
    queryKey: [queryKeys.companyAccount],
    queryFn: () => getAccountDetails(user?.signInUserSession.idToken.jwtToken),
  });
}
