import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import InfoIcon from '@/component-library/primitives/Icons/InfoIcon/InfoIcon';

interface ContentModalProps {
  isOpen: boolean;
  templateData: string;
  onCancel: () => void;
}

const ContentModal: React.FC<ContentModalProps> = ({ isOpen, templateData, onCancel }) => {
  const { t } = useTranslation();
  if (!isOpen) return null;

  return (
    <div
      className="fixed overflow-auto inset-0 flex flex-col items-center justify-center z-50 bg-modalOpacityBG backdrop-blur-[10px]"
      onClick={onCancel}
    >
      <div className="absolute 3xl:top-14 lg:top-7 flex flex-row items-center gap-3">
        <span>
          <InfoIcon color="#DDE4F3" />
        </span>
        <span className="font-normal text-xs leading-[14px] tracking-[0.02em] text-gray-200">
          {t('NewEmailPage.tapAnywhereToExitLabel')}
        </span>
      </div>
      <div className=" min-w-[50rem] h-fit absolute top-[15%]">
        <div className="bg-white w-full h-full p-6 rounded-lg relative overflow-auto [&::-webkit-scrollbar]:h-0 [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-track]:bg-gray-200 [&::-webkit-scrollbar-thumb]:bg-gray-600">
          <div className="mt-6 editor-preview text-gray-300">{parse(templateData)}</div>
        </div>
      </div>
    </div>
  );
};

export default ContentModal;
