import { t } from 'i18next';

const Companies = () => {
  return (
    <div className="flex justify-between">
      <p className="text-white">{t('CompaniesPage.title')}</p>
    </div>
  );
};

export default Companies;
