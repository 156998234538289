import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FilterIcon from '@/component-library/primitives/Icons/FilterIcon/FilterIcon';
import SearchMagnifyingGlassIcon from '@/component-library/primitives/Icons/SearchMagnifyingGlassIcon/SearchMagnifyingGlassIcon';

interface SearchFieldProps {
  initialValue?: string | number;
  setSearchValue?: (value: any) => void;
  debounce?: number;
  className?: string;
  withFilter?: boolean;
}

const SearchField: React.FC<SearchFieldProps> = ({
  setSearchValue,
  debounce = 500,
  initialValue,
  className,
  withFilter = true,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchValue && setSearchValue(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <div className="bg-darkBlue w-fit rounded-lg pl-11 flex flex-row relative">
      <span className="absolute top-[0.875rem] left-[0.875rem]">
        <SearchMagnifyingGlassIcon />
      </span>
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={t('SearchField.placeholder')}
        className={`text-gray-300 placeholder-gray-300 text-sm bg-darkBlue mr-3 w-[17rem] h-12 outline-none ${className}`}
      />
      {withFilter && (
        <button className="px-5 py-3.5 border-l-[0.063rem] border-l-gray-700 text-gray-50 text-sm">
          <div className="flex flex-row items-center gap-2">
            <FilterIcon />
            <span>{t('SearchField.filterLabel')}</span>
          </div>
        </button>
      )}
    </div>
  );
};

export default SearchField;
