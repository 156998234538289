import { useEffect, useState } from 'react';

const BigColoredText = ({
  number = 0,
  color,
  numberSizePx,
  withPercentage,
}: {
  number?: number;
  color: string;
  numberSizePx?: number;
  withPercentage?: boolean;
}) => {
  const numberSize = number.toString().length > 2 ? 50 : 70;
  const [animatedNumber, setAnimatedNumber] = useState(0);
  useEffect(() => {
    const animateFill = () => {
      let currentNumber = 0;
      const animationInterval = setInterval(() => {
        const increment = number < 50 ? 1 : number < 100 ? 10 : number < 500 ? 50 : 100;
        currentNumber += increment;
        setAnimatedNumber(currentNumber);
        if (currentNumber >= number) {
          clearInterval(animationInterval);
          setAnimatedNumber(number);
        }
      }, 20);
    };

    animateFill();
    return () => {
      clearInterval(animatedNumber);
    };
  }, [number]);

  return (
    <h1
      style={{
        color,
        fontSize: numberSizePx ? `${numberSizePx}px` : `${numberSize}px`,
        lineHeight: numberSizePx ? `${numberSizePx}px` : `${numberSize}px`,
        paddingBottom: '20px',
      }}
      className="font-semibold"
    >
      {animatedNumber}
      {withPercentage && '%'}
    </h1>
  );
};

export default BigColoredText;
