import { SVGProps } from 'react';

interface MoreIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const MoreIcon: React.FC<MoreIconProps> = ({ color = '#9BAFD0' }) => {
  return (
    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.1667 1.99999C11.1667 2.46023 11.5398 2.83332 12.0001 2.83332C12.4603 2.83332 12.8334 2.46023 12.8334 1.99999C12.8334 1.53975 12.4603 1.16666 12.0001 1.16666C11.5398 1.16666 11.1667 1.53975 11.1667 1.99999Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.16675 1.99999C6.16675 2.46023 6.53984 2.83332 7.00008 2.83332C7.46032 2.83332 7.83341 2.46023 7.83341 1.99999C7.83341 1.53975 7.46032 1.16666 7.00008 1.16666C6.53984 1.16666 6.16675 1.53975 6.16675 1.99999Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.16675 1.99999C1.16675 2.46023 1.53984 2.83332 2.00008 2.83332C2.46032 2.83332 2.83341 2.46023 2.83341 1.99999C2.83341 1.53975 2.46032 1.16666 2.00008 1.16666C1.53984 1.16666 1.16675 1.53975 1.16675 1.99999Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MoreIcon;
