import { SVGProps } from 'react';

interface WindowSidebarIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const WindowSidebarIcon: React.FC<WindowSidebarIconProps> = ({ color = '#9bafd0', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M2.5 5.19648H2.51599M2.51599 5.19648H7.5M2.51599 5.19648C2.5 5.45846 2.5 5.78141 2.5 6.19665V14.1966C2.5 15.1301 2.5 15.5961 2.68166 15.9526C2.84144 16.2662 3.09623 16.5219 3.40983 16.6817C3.76601 16.8632 4.2325 16.8632 5.16412 16.8632L7.5 16.8631M2.51599 5.19648C2.53595 4.86955 2.58081 4.63757 2.68166 4.43965C2.84144 4.12604 3.09623 3.87126 3.40983 3.71147C3.76635 3.52982 4.23341 3.52982 5.16683 3.52982H14.8335C15.7669 3.52982 16.233 3.52982 16.5895 3.71147C16.9031 3.87126 17.1587 4.12604 17.3185 4.43965C17.4194 4.63757 17.4642 4.86955 17.4841 5.19648M7.5 5.19648H17.4841M7.5 5.19648V16.8631M17.4841 5.19648H17.5M17.4841 5.19648C17.5 5.45789 17.5 5.77999 17.5 6.19391L17.5 14.199C17.5 15.1306 17.5 15.5965 17.3185 15.9526C17.1587 16.2662 16.9031 16.5219 16.5895 16.6817C16.2333 16.8631 15.7675 16.8631 14.8359 16.8631H7.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WindowSidebarIcon;
