import { useQuery } from 'react-query';
import axios from 'axios';

import { TeamMember, TeamMembersPerTenant } from '@/models/TeamMembers';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

import queryKeys from '../../modules/shared/query/queryKeys';

async function getTeamMembers(token: string | undefined) {
  const response = await axios.get<TeamMembersPerTenant>(`${AwsConfigApiGateway.pooledTenantApiUrl}/team-members`, {
    headers: {
      'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data.data;
}

export function useGetTeamMembers() {
  const { user } = useAuth();
  return useQuery<TeamMember[]>({
    queryKey: [queryKeys.teamMembers],
    queryFn: () => getTeamMembers(user?.signInUserSession.idToken.jwtToken),
  });
}
