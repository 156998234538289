import { SVGProps, useState } from 'react';

interface ArrowsReloadIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
  isHoverable?: boolean;
  tooltipLabel?: string;
}

const ArrowsReloadIcon: React.FC<ArrowsReloadIconProps> = ({
  color = '#F5F7FC',
  isHoverable = false,
  tooltipLabel,
  ...props
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative" onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11 14H16V19M7 6H2V1M16.4176 7.0034C15.8569 5.61566 14.9181 4.41304 13.708 3.53223C12.4979 2.65141 11.0652 2.12752 9.57227 2.02051C8.07937 1.9135 6.58606 2.2274 5.2627 2.92661C3.93933 3.62582 2.83882 4.68254 2.08594 5.97612M1.58203 12.9971C2.14272 14.3848 3.08146 15.5874 4.29157 16.4682C5.50169 17.3491 6.93588 17.8723 8.42878 17.9793C9.92168 18.0863 11.4138 17.7725 12.7371 17.0732C14.0605 16.374 15.1603 15.3175 15.9131 14.0239"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {isHoverable && showTooltip && (
        <div className="absolute left-8 -bottom-[5px] bg-custom-darkBlue p-2 rounded-lg z-10 whitespace-nowrap">
          {tooltipLabel}
        </div>
      )}
    </div>
  );
};

export default ArrowsReloadIcon;
