import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';

import { Notification } from '@/models/NotificationsByWorkspace';

export const useNotificationsTable = () => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<Notification>();

  const columns = [
    columnHelper.accessor('toEmail', {
      header: t('NotificationOverviewPage.toEmailColumn'),
      cell: (info) => (
        <div className="flex space-x-2 h-[2.75rem] items-center">
          <span>{info.renderValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor('open', {
      header: () => <div>{t('NotificationOverviewPage.openColumn')}</div>,
      cell: (info) => <span>{info.renderValue()}</span>,
    }),
    columnHelper.accessor('firstOpenAt', {
      header: () => <div>{t('NotificationOverviewPage.openedAtColumn')}</div>,
      cell: (info) => {
        const firstOpenedAtValue = info.row.original.firstOpenAt;
        if (firstOpenedAtValue) {
          return (
            <div className="flex flex-col">
              <span>
                {new Date(info.renderValue() as string).toLocaleDateString('en-us', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </span>
              <span className="text-sm">
                {new Date(info.renderValue() as string).toLocaleTimeString('en-us', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </span>
            </div>
          );
        } else {
          return <span className="text-yellow-500">{t('NotificationOverviewPage.notOpenedYetLabel')}</span>;
        }
      },
    }),
    columnHelper.accessor('click', {
      header: () => <div>{t('NotificationOverviewPage.clickColumn')}</div>,
      cell: (info) => <span>{info.renderValue()}</span>,
    }),
    // columnHelper.accessor('createdByName', {
    //   header: () => <div>{t('NotificationOverviewPage.createdByColumn')}</div>,
    //   cell: (info) => <div className="truncate max-w-[12rem]">{info.renderValue()}</div>,
    // }),
  ];

  return { columns };
};
