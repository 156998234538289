import { ReactNode } from 'react';

import DashboardRoutesWrapper from '@/modules/Dashboard/DashboardRoutesWrapper/DashboardRoutesWrapper';
import protectedRoute from '@/utils/routes/PrivateRoute';

import LoggedInHeader from '../LoggedInHeader/LoggedInHeader';
import SideMenu from '../SideMenu/SideMenu';
import WorkspacesMenu from '../WorkspacesMenu/WorkspacesMenu';

const ProtectedLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex">
      <WorkspacesMenu />
      <SideMenu />
      <DashboardRoutesWrapper>
        <LoggedInHeader>{children}</LoggedInHeader>
      </DashboardRoutesWrapper>
    </div>
  );
};

export default protectedRoute(ProtectedLayout);
