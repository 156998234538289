import { SVGProps } from 'react';

interface CircleHelpIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const CircleHelpIcon: React.FC<CircleHelpIconProps> = ({ color = '#7898FB', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M7.62207 7.56135C7.7644 7.12277 8.02513 6.73247 8.37565 6.4329C8.72618 6.13334 9.15316 5.93648 9.60856 5.86423C10.064 5.79198 10.5302 5.847 10.9562 6.02336C11.3823 6.19971 11.7512 6.49062 12.0223 6.86361C12.2934 7.23659 12.4557 7.67713 12.492 8.1368C12.5282 8.59647 12.4365 9.05732 12.2272 9.46818C12.0179 9.87904 11.6995 10.2237 11.3063 10.4647C10.9132 10.7056 10.4611 10.8331 10 10.8331V11.6669M10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5ZM10.0415 14.1667V14.25L9.9585 14.2502V14.1667H10.0415Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CircleHelpIcon;
