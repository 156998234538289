import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';
import { RolesResponse } from '@/models/RolePermission';

export interface RolePermissionState {
  data: RolesResponse | null;
  loading: boolean;
}

const initialState: RolePermissionState = {
  data: null,
  loading: false,
};

export const rolePermissionsSlice = createSlice({
  name: 'rolePermissions',
  initialState,
  reducers: {
    setPermissions: (state, action) => {
      state.data = action.payload.data;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setPermissions, setLoading } = rolePermissionsSlice.actions;
export const selectPermissions = (state: RootState) => state.rolePermissions.data;
export const selectLoading = (state: RootState) => state.rolePermissions.loading;

export default rolePermissionsSlice.reducer;
