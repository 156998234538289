import { SVGProps } from 'react';

interface SortDescendingIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const SortDescendingIcon: React.FC<SortDescendingIconProps> = ({ color = '#9BAFD0', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M4 17H16M4 12H13M4 7H10M18 13V5M18 5L21 8M18 5L15 8"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SortDescendingIcon;
