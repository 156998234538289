import React from 'react';

interface ModalProps {
  isOpen: boolean;
  title: string;
  body: string;
  cancelBtnTxt: string;
  confirmBtnTxt: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, title, body, cancelBtnTxt, confirmBtnTxt, onCancel, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white w-80 p-6 rounded-lg shadow-xl">
        <h2 className="text-2xl font-semibold mb-4">{title}</h2>
        <p className="text-gray-600">{body}</p>
        <div className="flex justify-end mt-6">
          <button
            className="mr-4 px-4 py-2 text-white bg-gray-500 rounded-lg hover:bg-gray-600 focus:outline-none"
            onClick={onCancel}
          >
            {cancelBtnTxt}
          </button>
          <button
            className="px-4 py-2 text-black button-gradient rounded-lg hover:bg-blue-600 focus:outline-none"
            onClick={onConfirm}
          >
            {confirmBtnTxt}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
