'use client';

import { useEffect, useState } from 'react';
import { html } from '@codemirror/lang-html';
import { Diagnostic, linter, lintGutter } from '@codemirror/lint';
import { createTheme } from '@uiw/codemirror-themes';
import CodeMirror, { Extension } from '@uiw/react-codemirror';
import { HTMLHint } from 'htmlhint';
import { Hint } from 'htmlhint/types';
import sanitizeHtml from 'sanitize-html';

import ImageIcon from '@/component-library/primitives/Icons/ImageIcon/ImageIcon';
import TextAlignIcon from '@/component-library/primitives/Icons/TextAlignIcon/TextAlignIcon';

const myTheme = createTheme({
  theme: 'light',
  settings: {
    background: '#15171EB2',
    backgroundImage: '',
    foreground: '#75baff',
    caret: '#fff',
    selection: '#036dd626',
    selectionMatch: '#036dd626',
    lineHighlight: '#8a91991a',
    gutterBackground: '#15171EEE',
    gutterForeground: '#8a9199',
    gutterBorder: '#15171EB2',
  },
  styles: [],
});

const convertHintsToLints = (hints: Hint[], htmlContent: string): Diagnostic[] => {
  return hints.map((hint) => {
    const htmlSplitByLines = htmlContent.split('\n');
    const hintLineNumber = hint.line - 1;

    // we remove other lines after the hintLineNumber so that we can count all the characters up to the line where the hint is
    const linesToCount = htmlSplitByLines.slice(0, hintLineNumber);
    const linesCharAmount = linesToCount
      .map((line) => line.length)
      .reduce((totalSum, currVal) => totalSum + currVal, 0);

    // `hintLineNumber - 1` accounts for the whitespace characters in every line
    let totalCharAmount = linesCharAmount + hint.col + hintLineNumber - 1;

    if (totalCharAmount > htmlContent.length) {
      totalCharAmount = htmlContent.length - 1;
    }

    return {
      from: totalCharAmount,
      to: totalCharAmount,
      severity: 'warning',
      message: hint.message,
    } as Diagnostic;
  });
};

const DELAY_DURATION_MILISECONDS = 300;

const HtmlEditor = ({
  htmlContentInit,
  handleHTMLChange,
}: {
  htmlContentInit?: string;
  handleHTMLChange: (_: string | undefined) => void;
}) => {
  const [localHtmlContent, setLocalHtmlContent] = useState(htmlContentInit || '');

  const [customLinterExtension, setCustomLinterExtension] = useState<Extension>([]);
  const [firstErrorLine, setFirstErrorLine] = useState(0);

  // debounce changes to the main rendering area
  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      const htmlVerificationHints = HTMLHint.verify(localHtmlContent);
      let localHtmlSantized = localHtmlContent;

      if (localHtmlSantized.length > 0 && htmlVerificationHints.length > 0) {
        setFirstErrorLine(htmlVerificationHints[0].line);
        // this means we have some errors in our HTML --> set lint hints
        setCustomLinterExtension(linter((_) => convertHintsToLints(htmlVerificationHints, localHtmlContent)));
        localHtmlSantized = sanitizeHtml(localHtmlContent, {
          allowVulnerableTags: true, // makes sanitizer log NO warnings for `style` and `script` tags
        });
      } else {
        setFirstErrorLine(0);
        // no errors --> reset linter result to empty array
        setCustomLinterExtension(linter((_) => []));
      }

      handleHTMLChange(localHtmlSantized);
    }, DELAY_DURATION_MILISECONDS);

    return () => clearTimeout(delayTimeout);
  }, [localHtmlContent, handleHTMLChange]);

  return (
    <div className="bg-htmlEditor h-full rounded-lg p-4 flex flex-col">
      <div className="flex flex-row items-center justify-between w-[100%]">
        <div className="flex flex-row items-center">
          {firstErrorLine > 0 && (
            <span className="text-red-400 text-sm">Fix errors to render properly - check line {firstErrorLine}</span>
          )}
        </div>
        <div className="flex flex-row items-center gap-x-1">
          <button
            type="button"
            className="bg-htmlEditorBtnBG p-1 h-6 rounded-[5px] flex items-center justify-center group"
          >
            <span className="group-hover:[&>*_path]:stroke-gray-100">
              <TextAlignIcon />
            </span>
          </button>
          <button
            type="button"
            className="bg-htmlEditorBtnBG p-1 h-6 rounded-[5px] flex items-center justify-center group"
          >
            <span className="group-hover:[&>*_path]:stroke-gray-100">
              <ImageIcon />
            </span>
          </button>
        </div>
      </div>
      <section className="mt-6 h-full">
        <CodeMirror
          value={localHtmlContent}
          extensions={[html({ autoCloseTags: true, selfClosingTags: true }), customLinterExtension, lintGutter()]}
          onChange={(value) => setLocalHtmlContent(value)}
          theme={myTheme}
          style={{ height: '92%' }}
        />
      </section>
    </div>
  );
};

export default HtmlEditor;
