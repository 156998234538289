import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { useDeleteAPICredentialsById } from '@/api/APICredentials/useDeleteAPICredential';
import { useGetAPICredentials } from '@/api/APICredentials/useGetAPICredentials';
import { Button } from '@/component-library/primitives/Button/Button';
import ChevronIcon from '@/component-library/primitives/Icons/ChevronIcon/ChevronIcon';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import { Input } from '@/component-library/widgets/Input/Input';
import { APICredential, DeleteAPICredentils } from '@/models/APICredentials';
import queryKeys from '@/modules/shared/query/queryKeys';
import { AppRoutes } from '@/utils/routes/router';

const DeleteAPICredentials = () => {
  const deleteAPICredentialsMutation = useDeleteAPICredentialsById();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { id: credentialId } = useParams();
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const queryClient = useQueryClient();
  const { data } = useGetAPICredentials();

  const apiCredentialToDelete = data?.filter((cred) => cred.id === credentialId)[0];

  useEffect(() => {
    let timerBeforeNavigation: NodeJS.Timeout;
    if (toastInfo.toastType === 'success') {
      timerBeforeNavigation = setTimeout(() => navigate(AppRoutes.apiCredentials), 2000);
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, toastInfo]);

  const schema = useMemo(() => {
    return yup
      .object()
      .shape({
        name: yup
          .string()
          .trim()
          .oneOf([apiCredentialToDelete?.name], t('DeleteAPIcredentialPage.namesNotMatchingError'))
          .required(t('CommonErrors.credentialNameRequired')),
      })
      .required();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DeleteAPICredentils>();

  const onSubmit = (data: DeleteAPICredentils) => {
    deleteAPICredentialsMutation.mutate(credentialId ?? '', {
      onSuccess: () => {
        queryClient.setQueryData<APICredential[]>(
          queryKeys.apiCredentials,
          (prevData) => prevData?.filter((credential) => credential.id !== credentialId) || [],
        );
        setToastInfo({ message: t('DeleteAPIcredentialPage.toastSuccess'), toastType: 'success' });
        setOpen(true);
      },
      onError: () => {
        setToastInfo({ message: t('DeleteAPIcredentialPage.toastError'), toastType: 'error' });
        setOpen(true);
      },
    });
  };

  const onBack = () => {
    navigate(AppRoutes.apiCredentials);
  };

  return (
    <>
      <div className="flex flex-col items-center mt-12">
        <div>
          <div className="position: relative mb-4">
            <div className="position: absolute top-0.5 -left-10 cursor-pointer" onClick={onBack}>
              <ChevronIcon type={'left'} width={'24'} height={'24'} viewBox={'0 0 24 24'} />
            </div>
            <h1 className="text-custom-aliceBlue font-semibold text-xl mb-3">{t('DeleteAPIcredentialPage.title')}</h1>
            <p className="text-gray-900 py-5 px-6 bg-yellow-200 rounded-lg w-[50rem]">
              <Trans
                i18nKey="DeleteAPIcredentialPage.bannerLabel"
                components={{
                  primary: <span className="font-bold" />,
                }}
              />
            </p>
          </div>
          <form className="space-y-6 min-w-[50rem] max-w-[50rem] w-1/2 mx-auto" onSubmit={handleSubmit(onSubmit)}>
            <section className="px-6 pt-6 pb-8 space-y-8 shadow rounded-lg bg-custom-gray text-custom-aliceBlue">
              <p>
                <Trans
                  i18nKey="DeleteAPIcredentialPage.descriptionText"
                  components={{
                    primary: <span className="text-yellow-200" />,
                  }}
                  values={{ credentialName: apiCredentialToDelete?.name }}
                />
              </p>
              <section className="space-y-4">
                <p>{t('DeleteAPIcredentialPage.typeNameInstructionLabel')}</p>
                <Input
                  className="text-custom-aliceBlue border-gray-700"
                  placeholder={t('DeleteAPIcredentialPage.credentialNamePlaceholder')}
                  {...register('name')}
                  error={t(errors.name?.message) as string}
                />
                <p>{t('DeleteAPIcredentialPage.caseSensitiveLabel')}</p>
              </section>
            </section>
            <div className="flex justify-end">
              <Button variant="cancel" onClick={onBack}>
                {t('DeleteAPIcredentialPage.linkCancel')}
              </Button>
              <Button
                variant="destructive"
                className="px-4 !w-52 ml-8"
                isLoading={deleteAPICredentialsMutation.isLoading || deleteAPICredentialsMutation.isSuccess}
              >
                {t('DeleteAPIcredentialPage.buttonPermanentlyDelete')}
              </Button>
            </div>
          </form>
        </div>
        <ProvideToasts>
          <Toast
            isOpen={open}
            setOpen={setOpen}
            message={toastInfo.message}
            toastType={toastInfo.toastType as ToastType}
          />
        </ProvideToasts>
      </div>
    </>
  );
};

export default DeleteAPICredentials;
