import { SVGProps } from 'react';

interface InfoIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const InfoIcon: React.FC<InfoIconProps> = ({ color = '#FAF7B4', ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
      style={{ cursor: 'pointer' }}
    >
      <path
        d="M8.88932 10.6671V7.11154C8.88932 6.8758 8.79567 6.6497 8.62897 6.48301C8.46227 6.31631 8.23618 6.22266 8.00043 6.22266H7.11154C6.8758 6.22266 6.6497 6.31631 6.48301 6.48301C6.31631 6.6497 6.22266 6.8758 6.22266 7.11154C6.22266 7.34729 6.31631 7.57339 6.48301 7.74008C6.6497 7.90678 6.8758 8.00043 7.11154 8.00043V11.556C7.11154 11.7917 7.2052 12.0178 7.37189 12.1845C7.53859 12.3512 7.76469 12.4449 8.00043 12.4449H8.88932C9.12507 12.4449 9.35116 12.3512 9.51786 12.1845C9.68456 12.0178 9.77821 11.7917 9.77821 11.556C9.77821 11.3202 9.68456 11.0941 9.51786 10.9274C9.35116 10.7608 9.12507 10.6671 8.88932 10.6671Z"
        fill={color}
      />
      <path
        d="M8.00022 5.33344C8.49114 5.33344 8.88911 4.93547 8.88911 4.44455C8.88911 3.95363 8.49114 3.55566 8.00022 3.55566C7.5093 3.55566 7.11133 3.95363 7.11133 4.44455C7.11133 4.93547 7.5093 5.33344 8.00022 5.33344Z"
        fill={color}
      />
      <path
        d="M8 14.3722L8.00017 14.3722C9.68955 14.3703 11.3092 13.6983 12.5038 12.5038C13.6983 11.3092 14.3703 9.68955 14.3722 8.00017V8C14.3722 6.73969 13.9985 5.50769 13.2983 4.45978C12.5981 3.41188 11.6029 2.59513 10.4385 2.11283C9.27417 1.63054 7.99293 1.50434 6.75684 1.75022C5.52075 1.99609 4.38533 2.60299 3.49416 3.49416C2.60299 4.38533 1.9961 5.52075 1.75022 6.75684C1.50435 7.99293 1.63054 9.27417 2.11284 10.4385C2.59514 11.6029 3.41188 12.5981 4.45979 13.2983C5.50769 13.9985 6.7397 14.3722 8 14.3722ZM3.63878 1.47296C4.92968 0.61041 6.44736 0.150017 7.99992 0.15C10.0812 0.15233 12.0765 0.980137 13.5482 2.45181C15.0199 3.9235 15.8477 5.91888 15.85 8.00016C15.85 9.55269 15.3896 11.0703 14.527 12.3612C13.6645 13.6522 12.4385 14.6583 11.0041 15.2525C9.56967 15.8466 7.99129 16.0021 6.46854 15.6992C4.94579 15.3963 3.54706 14.6486 2.44921 13.5508C1.35137 12.4529 0.603733 11.0542 0.300839 9.53146C-0.00205478 8.00871 0.153401 6.43033 0.747549 4.99593C1.3417 3.56153 2.34785 2.33553 3.63878 1.47296Z"
        fill={color}
        stroke="#323C48"
        strokeWidth="0.3"
      />
    </svg>
  );
};

export default InfoIcon;
