import { SVGProps } from 'react';

interface NavigationIconProps extends SVGProps<SVGSVGElement> {
  type: 'single' | 'double';
  color?: string;
  orientation?: 'left' | 'right';
}

function NavigationIcon({ color = '#CDD7ED', type = 'single', orientation = 'left', ...props }: NavigationIconProps) {
  const rotationAngle = orientation === 'right' ? 180 : 0;
  return (
    <svg width={24} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform={`rotate(${rotationAngle} 8 8)`}>
        {type === 'double' ? (
          <>
            <path
              d="M4.99997 8.00003C4.99997 7.87216 5.04884 7.74416 5.14647 7.64653L10.1465 2.64653C10.3418 2.45116 10.6582 2.45116 10.8535 2.64653C11.0487 2.84191 11.0488 3.15828 10.8535 3.35353L6.20697 8.00003L10.8535 12.6465C11.0488 12.8419 11.0488 13.1583 10.8535 13.3535C10.6581 13.5488 10.3417 13.5489 10.1465 13.3535L5.14647 8.35353C5.04884 8.25591 4.99997 8.12791 4.99997 8.00003Z"
              fill={color}
            />
            <path
              d="M13 8.00003C13 7.87216 13.0488 7.74416 13.1465 7.64653L18.1465 2.64653C18.3418 2.45116 18.6582 2.45116 18.8535 2.64653C19.0487 2.84191 19.0488 3.15828 18.8535 3.35353L14.207 8.00003L18.8535 12.6465C19.0488 12.8419 19.0488 13.1583 18.8535 13.3535C18.6581 13.5488 18.3417 13.5489 18.1465 13.3535L13.1465 8.35353C13.0488 8.25591 13 8.12791 13 8.00003Z"
              fill={color}
            />
          </>
        ) : (
          <>
            <path
              d="M4.99997 8.00003C4.99997 7.87216 5.04884 7.74416 5.14647 7.64653L10.1465 2.64653C10.3418 2.45116 10.6582 2.45116 10.8535 2.64653C11.0487 2.84191 11.0488 3.15828 10.8535 3.35353L6.20697 8.00003L10.8535 12.6465C11.0488 12.8419 11.0488 13.1583 10.8535 13.3535C10.6581 13.5488 10.3417 13.5489 10.1465 13.3535L5.14647 8.35353C5.04884 8.25591 4.99997 8.12791 4.99997 8.00003Z"
              fill={color}
            />
          </>
        )}
      </g>
    </svg>
  );
}

export default NavigationIcon;
