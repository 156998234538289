import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useGetAccountDetails } from '@/api/settings/useGetAccountDetails';
import { useUpdateAccountDetails } from '@/api/settings/useUpdateAccountDetails';
import { Button } from '@/component-library/primitives/Button/Button';
import Loader from '@/component-library/primitives/Loader/Loader';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import { Input } from '@/component-library/widgets/Input/Input';
import { CompanyAccountDetails, EditCompanyAccountDetails } from '@/models/CompanyAccountDetails';
import { AppRoutes } from '@/utils/routes/router';

const CompanyAccount = () => {
  const { data, isFetched, isLoading } = useGetAccountDetails();
  const [account, setAccount] = useState<EditCompanyAccountDetails | undefined>(undefined);
  const updateAccountDetailsMutation = useUpdateAccountDetails();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const [isInEditMode, setIsInEditMode] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    isFetched && setAccount(data);
  }, [data, isFetched]);

  useEffect(() => {
    let timerBeforeNavigation: NodeJS.Timeout;
    if (toastInfo.toastType === 'success') {
      timerBeforeNavigation = setTimeout(() => navigate(AppRoutes.account), 2000);
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, toastInfo]);

  const schema = useMemo(() => {
    return yup
      .object()
      .shape({
        tenantName: yup.string().trim().required(t('CommonErrors.companyNameRequired')),
        invoiceEmail: yup
          .string()
          .trim()
          .email(t('CommonErrors.emailFormatValidation'))
          .required(t('CommonErrors.invoiceEmailAddressRequired')),
        legalEmail: yup
          .string()
          .trim()
          .email(t('CommonErrors.emailFormatValidation'))
          .required(t('CommonErrors.legalEmailAddressRequired')),
        technicalEmail: yup
          .string()
          .trim()
          .email(t('CommonErrors.emailFormatValidation'))
          .required(t('CommonErrors.technicalEmailAddressRequired')),
        tenantAddress: yup.string().trim().required(t('CommonErrors.addressRequired')),
        city: yup.string().trim().required(t('CommonErrors.cityRequired')),
        zipCode: yup.string().trim().required(t('CommonErrors.zipOrPostalCodeIsRequired')),
      })
      .required();
  }, [t]);

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<CompanyAccountDetails>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (account && isInEditMode === false) {
      setValue('tenantName', account.tenantName);
      setValue('invoiceEmail', account.invoiceEmail);
      setValue('legalEmail', account.legalEmail);
      setValue('technicalEmail', account.technicalEmail);
      setValue('tenantAddress', account.tenantAddress);
      setValue('tenantNumber', account.tenantNumber);
      setValue('city', account.city);
      setValue('zipCode', account.zipCode);
      clearErrors();
    }
  }, [account, setValue, isInEditMode, clearErrors]);

  const watchAll = watch();

  const onSubmit = (data: EditCompanyAccountDetails) => {
    const sendData = {
      tenantName: data.tenantName,
      invoiceEmail: data.invoiceEmail,
      legalEmail: data.legalEmail,
      technicalEmail: data.technicalEmail,
      createdAt: new Date().toISOString(),
      tenantAddress: data.tenantAddress,
      tenantNumber: '+381628145689',
      city: data.city,
      zipCode: data.zipCode,
    };
    updateAccountDetailsMutation.mutate(sendData as CompanyAccountDetails, {
      onSuccess: () => {
        setAccount(sendData);
        setToastInfo({ message: t('CompanyAccountSettingsPage.toastSuccess'), toastType: 'success' });
        setOpen(true);
        setIsInEditMode(false);
      },
      onError: () => {
        setToastInfo({ message: t('CompanyAccountSettingsPage.toastError'), toastType: 'error' });
        setOpen(true);
      },
    });
  };

  const onCancel = () => {
    isInEditMode ? setIsInEditMode(false) : navigate(AppRoutes.settings);
  };

  return (
    <div className="relative mb-32">
      {isLoading && (
        <div className="absolute w-full h-full z-50">
          <Loader />
        </div>
      )}
      <div className="flex flex-col items-center mt-4">
        <div>
          <div className="relative mb-8">
            <h1 className="text-white font-medium text-xl mb-2">{t('CompanyAccountSettingsPage.title')}</h1>
            <span className="text-gray-300 text-sm">{t('CompanyAccountSettingsPage.description')}</span>
          </div>
          <form className="space-y-7 min-w-[40rem] w-1/2">
            <section className="space-y-4">
              <h2 className="text-xs font-semibold text-yellow-500">
                {t('CompanyAccountSettingsPage.basicInformationLabel')}
              </h2>
              <Input
                readOnly={!isInEditMode}
                label={t('CompanyAccountSettingsPage.companyNameLabel')}
                placeholder={
                  isInEditMode === false && !watchAll.tenantName
                    ? t('CompanyAccountSettingsPage.undefinedLabel')
                    : t('CompanyAccountSettingsPage.companyNamePlaceholder')
                }
                {...register('tenantName')}
                className={`!bg-transparent ${
                  isInEditMode === false ? '!pl-0 text-gray-300' : watchAll.tenantName ? '!text-gray-100' : ''
                }`}
                withBorder={isInEditMode}
                error={errors.tenantName?.message as string}
              />
            </section>
            <section className="space-y-4">
              <h2 className="text-xs font-semibold text-yellow-500">
                {t('CompanyAccountSettingsPage.contactInformationLabel')}
              </h2>
              <Input
                readOnly={!isInEditMode}
                label={t('CompanyAccountSettingsPage.invoiceEmailAddressLabel')}
                placeholder={
                  isInEditMode === false && !watchAll.invoiceEmail
                    ? t('CompanyAccountSettingsPage.undefinedLabel')
                    : t('CompanyAccountSettingsPage.invoiceEmailAddressPlaceholder')
                }
                {...register('invoiceEmail')}
                className={`!bg-transparent ${
                  isInEditMode === false ? '!pl-0 text-gray-300' : watchAll.invoiceEmail ? '!text-gray-100' : ''
                }`}
                withBorder={isInEditMode}
                error={errors.invoiceEmail?.message as string}
              />
              <Input
                readOnly={!isInEditMode}
                label={t('CompanyAccountSettingsPage.companyEmailAddressLabel')}
                placeholder={
                  isInEditMode === false && !watchAll.legalEmail
                    ? t('CompanyAccountSettingsPage.undefinedLabel')
                    : t('CompanyAccountSettingsPage.companyEmailAddressPlaceholder')
                }
                {...register('legalEmail')}
                className={`!bg-transparent ${
                  isInEditMode === false ? '!pl-0 text-gray-300' : watchAll.legalEmail ? '!text-gray-100' : ''
                }`}
                withBorder={isInEditMode}
                error={errors.legalEmail?.message as string}
              />
              <Input
                readOnly={!isInEditMode}
                label={t('CompanyAccountSettingsPage.technicalContactEmailAddressLabel')}
                placeholder={
                  isInEditMode === false && !watchAll.technicalEmail
                    ? t('CompanyAccountSettingsPage.undefinedLabel')
                    : t('CompanyAccountSettingsPage.technicalContactEmailAddressPlaceholder')
                }
                {...register('technicalEmail')}
                className={`!bg-transparent ${
                  isInEditMode === false ? '!pl-0 text-gray-300' : watchAll.technicalEmail ? '!text-gray-100' : ''
                }`}
                withBorder={isInEditMode}
                error={errors.technicalEmail?.message as string}
              />
            </section>
            <section className="space-y-4">
              <h2 className="text-xs font-semibold text-yellow-500">
                {t('CompanyAccountSettingsPage.locationInformationLabel')}
              </h2>
              <Input
                readOnly={!isInEditMode}
                label={t('CompanyAccountSettingsPage.addressLabel')}
                placeholder={
                  isInEditMode === false && !watchAll.tenantAddress
                    ? t('CompanyAccountSettingsPage.undefinedLabel')
                    : t('CompanyAccountSettingsPage.addressPlaceholder')
                }
                {...register('tenantAddress')}
                className={`!bg-transparent ${
                  isInEditMode === false ? '!pl-0 text-gray-300' : watchAll.tenantAddress ? '!text-gray-100' : ''
                }`}
                withBorder={isInEditMode}
                error={errors.tenantAddress?.message as string}
              />
              <Input
                readOnly={!isInEditMode}
                label={t('CompanyAccountSettingsPage.cityLabel')}
                placeholder={
                  isInEditMode === false && !watchAll.city
                    ? t('CompanyAccountSettingsPage.undefinedLabel')
                    : t('CompanyAccountSettingsPage.cityPlaceholder')
                }
                {...register('city')}
                className={`!bg-transparent ${
                  isInEditMode === false ? '!pl-0 text-gray-300' : watchAll.city ? '!text-gray-100' : ''
                }`}
                withBorder={isInEditMode}
                error={errors.city?.message as string}
              />
              <Input
                readOnly={!isInEditMode}
                label={t('CompanyAccountSettingsPage.zipOrPostalCodeLabel')}
                placeholder={
                  isInEditMode === false && !watchAll.zipCode
                    ? t('CompanyAccountSettingsPage.undefinedLabel')
                    : t('CompanyAccountSettingsPage.zipOrPostalCodePlaceholder')
                }
                {...register('zipCode')}
                className={`!bg-transparent ${
                  isInEditMode === false ? '!pl-0 text-gray-300' : watchAll.zipCode ? '!text-gray-100' : ''
                }`}
                withBorder={isInEditMode}
                error={errors.zipCode?.message as string}
              />
            </section>
            <div className="flex items-center justify-center fixed bottom-0 z-50 bg-applicationBgBlue overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
              <div className="flex items-center justify-between 3xl:min-w-[40rem] lg:min-w-[35rem]">
                <Button type="button" variant="cancel" onClick={onCancel}>
                  {isInEditMode ? t('CompanyAccountSettingsPage.linkCancel') : 'Back to Settings'}
                </Button>
                {isInEditMode ? (
                  <Button
                    type="button"
                    className="px-4 !w-52"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={updateAccountDetailsMutation.isLoading || updateAccountDetailsMutation.isSuccess}
                  >
                    {t('CompanyAccountSettingsPage.buttonUpdateCompanyAccount')}
                  </Button>
                ) : (
                  <Button type="button" className="px-4 !w-52" onClick={() => setIsInEditMode(true)}>
                    {t('CompanyAccountSettingsPage.buttonEditCompanyAccount')}
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
        <ProvideToasts>
          <Toast
            isOpen={open}
            setOpen={setOpen}
            message={toastInfo.message}
            toastType={toastInfo.toastType as ToastType}
          />
        </ProvideToasts>
      </div>
    </div>
  );
};
export default CompanyAccount;
