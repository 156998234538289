import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

import LogoIcon from '../../primitives/Icons/LogoIcon/LogoIcon';

interface AuthLayoutProps {
  children: React.ReactNode;
  title: string;
  content: string;
  titleClass?: string;
  contentClass?: string;
  variant?: 'one-column' | 'two-column';
  titleIconProps?: {
    path: string;
    classes?: string;
    alt?: string;
  };
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
  children,
  title,
  content,
  titleClass,
  contentClass,
  variant,
  titleIconProps,
}: {
  children: React.ReactNode;
  title: string;
  content: string;
  titleClass?: string;
  contentClass?: string;
  variant?: 'one-column' | 'two-column';
  titleIconProps?: {
    path: string;
    classes?: string;
    alt?: string;
  };
}) => {
  const navigate = useNavigate();
  return (
    <>
      {variant === 'one-column' ? (
        <div className="top-0 left-0 right-0 bottom-0 flex flex-col justify-between h-screen px-2 sm:px-6 lg:px-8 overflow-y-scroll">
          <div className="flex flex-col sm:flex-row justify-center items-center p-10 w-full max-w-[75%] m-auto h-fit">
            <div className="flex flex-col items-center w-authFieldsContainer">
              <div className="cursor-pointer" onClick={() => navigate('/')}>
                <LogoIcon withText />
              </div>
              <div className="flex flex-col items-center w-full mt-32 p-5 bg-opacity-50 rounded-xl px-16">
                {titleIconProps && (
                  <img
                    src={titleIconProps?.path || ''}
                    className={`mb-2 h-full ${titleIconProps?.classes || ''}`}
                    alt={'title icon'}
                  />
                )}
                <h3 className={`text-gray-50 text-2xl mb-2 font-semibold ${titleClass || ''}`}>{title}</h3>
                <span className={`text-gray-400 font-normal tracking-tightest w-97 mb-8 text-sm ${contentClass || ''}`}>
                  {content}
                </span>
                <div className="flex flex-col items-center w-full space-y-4">{children}</div>
              </div>
            </div>
          </div>
          <footer className="text-gray-400 text-center my-2 sm:my-10 text-xs">{t('AuthLayout.footer')}</footer>
        </div>
      ) : (
        <div className="flex flex-col sm:flex-row bg-gray-900 justify-center min-h-screen">
          {/* Left Column */}

          <div
            className="flex flex-col items-center w-full sm:w-[50%] bg-gray-900 pt-24 pb-12 px-[10rem] sm:px-[0] justify-center min-h-screen"
            style={{ height: `100%` }}
          >
            <div>
              <div className="cursor-pointer" onClick={() => navigate('/')}>
                <LogoIcon withText />
              </div>
            </div>
            <div className="flex flex-col items-center w-full mt-0 tall:mt-[10%] justify-center bg-opacity-50 rounded-xl max-w-[35rem] min-w-[20rem] px-0 sm:px-16 h-full grow">
              {titleIconProps && (
                <img
                  src={titleIconProps?.path || ''}
                  className={`pb-4 h-full ${titleIconProps?.classes || ''}`}
                  alt={'title icon'}
                />
              )}
              <h3 className={`text-gray-50 text-2xl mb-2 w-full font-semibold ${titleClass || ''}`}>{title}</h3>
              <span className={`text-gray-400 font-normal tracking-tightest w-full mb-8 text-sm ${contentClass || ''}`}>
                {content}
              </span>
              <div className="relative flex flex-col items-center w-full space-y-4">{children}</div>
            </div>
            <span className="text-gray-400 text-center my-2 text-xs min-w-[16rem]">{t('AuthLayout.footer')}</span>
          </div>
          {/* Right Column */}
          <div
            className="hidden sm:flex w-[60%] bg-cover bg-center"
            style={{ backgroundImage: 'url("/images/signInBg.jpg")' }}
          >
            <div className="h-full w-full"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default AuthLayout;
