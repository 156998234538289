/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';

import ArrowsReloadIcon from '@/component-library/primitives/Icons/ArrowsReloadIcon/ArrowsReloadIcon';
import BellRingIcon from '@/component-library/primitives/Icons/BellRingIcon/BellRingIcon';
import ChatIcon from '@/component-library/primitives/Icons/ChatIcon/ChatIcon';
import CheckAllIcon from '@/component-library/primitives/Icons/CheckAllIcon/CheckAllIcon';
import ClockIcon from '@/component-library/primitives/Icons/ClockIcon/ClockIcon';
import EmailIcon from '@/component-library/primitives/Icons/EmailIcon/EmailIcon';
import { Notification } from '@/models/NotificationsByWorkspace';

export const useDeliveryTable = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<Notification>();

  const mapChannelIcon = (channel: string | null) => {
    switch (channel) {
      case 'EMAIL':
        return <EmailIcon color="#9BAFD0" isHoverable={true} tooltipLabel={t('DeliveryPage.emailLabel')} />;
      case 'SMS':
        return <ChatIcon color="#9BAFD0" isHoverable={true} tooltipLabel={t('DeliveryPage.smsLabel')} />;
      default:
        return (
          <BellRingIcon
            color="#9BAFD0"
            width="24"
            height="24"
            isHoverable={true}
            tooltipLabel={t('DeliveryPage.pushNotificationLabel')}
          />
        );
    }
  };

  const mapDeliveryType = (channel: string | null) => {
    switch (channel) {
      case 'Immediately':
        return <CheckAllIcon color="#9BAFD0" isHoverable={true} tooltipLabel={t('DeliveryPage.immediatelyLabel')} />;
      case 'Specific Time':
        return <ClockIcon color="#9BAFD0" isHoverable={true} tooltipLabel={t('DeliveryPage.specificTimeLabel')} />;
      case 'Event based':
        return <ArrowsReloadIcon color="#9BAFD0" isHoverable={true} tooltipLabel={t('DeliveryPage.eventBasedLabel')} />;
    }
  };

  const columns = [
    columnHelper.accessor('title', {
      id: 'title',
      header: () => <div className="w-72">{t('DeliveryPage.titleColumn')}</div>,
      cell: (info) => (
        <div className="flex space-x-2 h-[2.75rem] w-72 items-center">
          <span>{info.renderValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor('channel', {
      id: 'channel',
      header: () => <div className="flex justify-center">{t('DeliveryPage.channelColumn')}</div>,
      cell: (info) => <div className="flex justify-center">{mapChannelIcon(info.renderValue())}</div>,
    }),
    columnHelper.accessor('notificationStatistics.sent', {
      id: 'sent',
      header: () => <div className="flex justify-center">{t('DeliveryPage.sentColumn')}</div>,
      cell: (info) => <div className="flex justify-center">{info.renderValue()}</div>,
    }),
    columnHelper.accessor('createdByName', {
      id: 'createdByName',
      header: () => <div className="flex justify-center">{t('DeliveryPage.createdByColumn')}</div>,
      cell: (info) => <div className="flex justify-center">{info.renderValue()}</div>,
    }),
    columnHelper.accessor('deliveryScheduleType', {
      id: 'deliveryScheduleType',
      header: () => <div className="flex justify-center">{t('DeliveryPage.deliveryScheduleTypeColumn')}</div>,
      cell: (info) => <div className="flex justify-center">{mapDeliveryType(info.renderValue())}</div>,
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => <div className="flex justify-center">{t('DeliveryPage.createdAtColumn')}</div>,
      cell: (info) => (
        <div className="flex flex-col items-center">
          <span>
            {new Date(info.renderValue() as string).toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </span>
          <span className="text-sm">
            {new Date(info.renderValue() as string).toLocaleTimeString('en-us', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        </div>
      ),
    }),
  ];

  const selectOptions = [
    // @ts-ignore
    { value: '', label: t('DeliveryPage.allChannelsOption'), id: '1' },
    { value: 'email', label: t('DeliveryPage.emailChannelOption'), id: '2' },
    { value: 'sms', label: t('DeliveryPage.smsChannelOption'), id: '3' },
  ];

  return { columns, selectOptions };
};
