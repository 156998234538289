import { useRef, useState } from 'react';

import DropDown from '@/component-library/primitives/DropDown/DropDown';
import ChevronIcon from '@/component-library/primitives/Icons/ChevronIcon/ChevronIcon';
import NavigationIcon from '@/component-library/primitives/Icons/NavigationIcon/NavigationIcon';
import useOutsideClick from '@/modules/shared/hooks/useOutsideClick';

import PageNumber from './PageNumber';

const PaginationControls = ({ table, currentPageIndex, data, showRecordsPerPage = true, className }: any) => {
  const [isNumberOfRecordsOpen, setIsNumberOfRecordsOpen] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setIsNumberOfRecordsOpen(false));
  const numberOfRecordsOptions = [
    {
      text: '5',
      key: '5',
    },
    {
      text: '10',
      key: '10',
    },
    {
      text: '20',
      key: '20',
    },
  ];

  return (
    <div className={`flex items-center gap-2 mt-4 justify-between ${className}`}>
      {/* display data information */}
      <div className="flex items-center gap-x-4">
        <span className="text-gray-500 text-xs font-normal">
          {`Showing ${currentPageIndex * table.getState().pagination.pageSize + 1}-${Math.min(
            (currentPageIndex + 1) * table.getState().pagination.pageSize,
            data.length,
          )} of ${data.length} results`}
        </span>
        {/** Records per page drop-down */}
        {showRecordsPerPage && (
          <>
            <span className="text-gray-800 text-2xl font-thin">|</span>
            <span className="text-gray-300 text-xs font-normal tracking-[0.0175rem]">Listing per Page</span>
            <DropDown
              options={numberOfRecordsOptions}
              trigger={
                <div
                  ref={dropdownRef}
                  className="w-[51px] flex cursor-pointer items-center justify-center py-1.5 px-[10px] gap-x-2 border border-gray-700 rounded"
                  onClick={() => setIsNumberOfRecordsOpen(!isNumberOfRecordsOpen)}
                >
                  <span className="text-primary-400 text-xs tracking-[0.0175rem] font-medium">
                    {table.getState().pagination.pageSize}
                  </span>
                  <span>
                    <ChevronIcon width="6" height="3" color="#F5F7FC" type={isNumberOfRecordsOpen ? 'up' : 'down'} />
                  </span>
                </div>
              }
              selectedOption={table.getState().pagination.pageSize}
              className="!w-fit !left-0 !top-7"
              onSelect={(option: any) => {
                switch (option.key) {
                  case '5':
                    table.setPageSize(5);
                    setIsNumberOfRecordsOpen(false);
                    break;
                  case '10':
                    table.setPageSize(10);
                    setIsNumberOfRecordsOpen(false);
                    break;
                  case '20':
                    table.setPageSize(20);
                    setIsNumberOfRecordsOpen(false);
                    break;
                  default:
                    break;
                }
              }}
            />
          </>
        )}
      </div>

      {/* navigation controls */}
      <div className="flex flex-row w-56 justify-between">
        <button className="" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
          <NavigationIcon type={'double'} orientation={'left'} />
        </button>
        <button className="mr-2.5" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          <NavigationIcon type={'single'} orientation={'left'} />
        </button>

        {table.getPageCount() <= 3 ? (
          Array.from({ length: table.getPageCount() }, (_, index) => (
            <PageNumber
              key={index}
              number={index + 1}
              className={index === currentPageIndex ? 'bg-activeSideMenuOption text-primary-400' : ''}
              onClick={() => table.setPageIndex(index)}
            />
          ))
        ) : (
          <>
            {currentPageIndex >= 1 && (
              <PageNumber
                number={currentPageIndex >= 2 ? '...' : currentPageIndex}
                onClick={() => table.setPageIndex(currentPageIndex - 2)}
              />
            )}

            {table.getPageCount() == currentPageIndex + 1 && (
              <PageNumber number={currentPageIndex - 1} onClick={() => table.setPageIndex(currentPageIndex - 2)} />
            )}

            {table.getPageCount() > 2 && currentPageIndex !== 0 && currentPageIndex !== 1 && (
              <PageNumber number={currentPageIndex} onClick={() => table.setPageIndex(currentPageIndex - 1)} />
            )}

            {/* Show current page */}
            <PageNumber number={currentPageIndex + 1} className="bg-activeSideMenuOption text-primary-400" />

            {table.getPageCount() > 2 && table.getCanNextPage() && (
              <PageNumber number={currentPageIndex + 2} onClick={() => table.setPageIndex(currentPageIndex + 1)} />
            )}

            {currentPageIndex === 0 && table.getPageCount() > 2 && (
              <PageNumber number={currentPageIndex + 3} onClick={() => table.setPageIndex(currentPageIndex + 2)} />
            )}

            {table.getCanNextPage() && currentPageIndex + 2 < table.getPageCount() && (
              <PageNumber number="..." onClick={() => table.setPageIndex(currentPageIndex + 2)} />
            )}
          </>
        )}

        {/* navigation controls */}
        <button className="ml-2.5" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          <NavigationIcon type={'single'} orientation={'right'} />
        </button>
        <button
          className=""
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          <NavigationIcon type={'double'} orientation={'right'} />
        </button>
      </div>
    </div>
  );
};

export default PaginationControls;
