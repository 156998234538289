import { useMutation } from 'react-query';
import axios from 'axios';

import { InAppChangePasswordData } from '@/models/PasswordData';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function changeUserPassword(data: InAppChangePasswordData, token: string | undefined) {
  const response = await axios.post<InAppChangePasswordData>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/settings/user/change-password`,
    data,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useChangeUserPassword() {
  const { user } = useAuth();

  return useMutation<InAppChangePasswordData, Error, InAppChangePasswordData>((data) =>
    changeUserPassword(data, user?.signInUserSession.idToken.jwtToken),
  );
}
