import { SVGProps, useState } from 'react';

interface ChatIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
  isHoverable?: boolean;
  tooltipLabel?: string;
}

const ChatIcon: React.FC<ChatIconProps> = ({ color = '#F5F7FC', isHoverable = false, tooltipLabel, ...props }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative" onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16 8H20C20.5523 8 21 8.44772 21 9V20L17.667 17.231C17.4875 17.0818 17.2608 17 17.0273 17H9C8.44771 17 8 16.5523 8 16V13M16 8V5C16 4.44772 15.5523 4 15 4H4C3.44772 4 3 4.44772 3 5V16.0003L6.33301 13.2308C6.51255 13.0817 6.73924 13 6.97266 13H8M16 8V12C16 12.5523 15.5523 13 15 13H8"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {isHoverable && showTooltip && (
        <div className="absolute left-8 -bottom-[6px] bg-custom-darkBlue p-2 rounded-lg z-10">{tooltipLabel}</div>
      )}
    </div>
  );
};

export default ChatIcon;
