const queryKeys = {
  workspaces: 'workspaces',
  workspace: 'workspace',
  audience: 'audience',
  campaigns: 'campaigns',
  templates: 'templates',
  segments: 'segments',
  segmentDetails: 'segment-details',
  accountMetrics: 'account-metrics',
  teamMembers: 'team-members',
  emailMetrics: 'email-metrics',
  rolePermissions: 'role-permissions',
  activityLogs: 'activity-logs',
  notifications: 'notifications',
  companyAccount: 'company-account',
  apiCredentials: 'api-credentials',
  domainNames: 'domain-names',
  domainDetails: 'domainDetails',
  inquiry: 'inquiry',
};

export default queryKeys;
