import { useMutation } from 'react-query';
import axios from 'axios';

import { SendEmailDetails } from '@/models/SendEmailDetails';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function sendEmail(data: SendEmailDetails, token: string | undefined) {
  const response = await axios.post<SendEmailDetails>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/notifications/email/schedule`,
    data,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useSendEmail() {
  const { user } = useAuth();
  return useMutation<SendEmailDetails, Error, SendEmailDetails>((data) =>
    sendEmail(data, user?.signInUserSession.idToken.jwtToken),
  );
}
