import React from 'react';
import { useTranslation } from 'react-i18next';

import CloseButtonIcon from '@/component-library/primitives/Icons/CloseButtonIcon/CloseButtonIcon';
import NoDataBoard from '@/component-library/widgets/NoDataBoard/NoDataBoard';
import Table from '@/component-library/widgets/Table/Table';
import { Member } from '@/models/AudienceByWorkspace';

import { useAudienceModalTable } from './useAudienceModalTable';

interface ModalProps {
  isOpen: boolean;
  data: Member[] | undefined;
  onCancel: () => void;
}

const AudienceModal: React.FC<ModalProps> = ({ isOpen, data, onCancel }) => {
  const { columns } = useAudienceModalTable();
  const { t } = useTranslation();
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-modalOpacityBG backdrop-blur-[10px]">
      <div className="bg-darkBlue min-w-[75rem] max-w-[90rem] min-h-[41rem] p-6 rounded-lg relative">
        <div className="flex justify-end">
          <button type="button" onClick={onCancel} className="group">
            <span className="group-hover:[&>*_path]:stroke-gray-100">
              <CloseButtonIcon />
            </span>
          </button>
        </div>
        {data && data.length > 0 ? (
          <Table
            columns={columns}
            data={data ?? []}
            showRecordsPerPage={false}
            className={{
              row: 'h-[3rem]',
              cell: 'overflow-visible',
              pagination: 'absolute bottom-5 w-[96%]',
            }}
          />
        ) : (
          <NoDataBoard description={t('SegmentOverview.modalDescription')} className="!h-[35rem]" />
        )}
      </div>
    </div>
  );
};

export default AudienceModal;
