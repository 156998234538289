import { useTranslation } from 'react-i18next';

import ChevronNewIcon from '@/component-library/primitives/Icons/ChevronNewIcon/ChevronNewIcon';

import NoDataSection from '../NoDataSection';

interface RecentActivitySectionProps {
  activities: { type: string; name: string; timestamp: string }[] | undefined;
  changeActiveStep: (step: string) => void;
}

const RecentActivitySection: React.FC<RecentActivitySectionProps> = ({ activities, changeActiveStep }) => {
  const { t } = useTranslation();

  return (
    <div className="px-5 pt-5 pb-7 w-full min-h-[15rem] max-h-[15rem] rounded-[10px] bg-darkBlue">
      <div className="flex flex-row items-center justify-between mb-8">
        <h2 className="text-gray-50 text-base font-normal tracking-[0.32px]">
          {t('MemberProfilePage.recentActivityLabel')}
        </h2>
        <button
          onClick={() => changeActiveStep(t('MemberProfilePage.activitiesTabLabel'))}
          className="group hover:bg-iconButtonHoverBG rounded-lg w-8 h-8 flex items-center justify-center"
        >
          <span className="group-hover:[&>*_path]:stroke-gray-400">
            <ChevronNewIcon type="right" color="white" />
          </span>
        </button>
      </div>
      <div className="h-[9rem] overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
        {activities && activities.length > 0 && (
          <table className="w-full">
            <thead>
              <tr className="text-xs text-secondary-400 font-semibold tracking-[0.24px] border-b-2 border-b-gray-800">
                <th className="text-left pb-4 w-[30%]">{t('MemberProfilePage.activityTypeLabel')}</th>
                <th className="text-left pb-4">{t('MemberProfilePage.nameLabel')}</th>
                <th className="text-left pb-4">{t('MemberProfilePage.timestampLabel')}</th>
              </tr>
            </thead>
            <tbody>
              {activities &&
                activities.length > 0 &&
                activities?.map((activity, id) => {
                  return (
                    <tr
                      key={id}
                      className="text-gray-300 text-sm font-normal border-b border-b-gray-800 last:border-none"
                    >
                      <td className="py-4">{activity.type}</td>
                      <td className="py-4">{activity.name}</td>
                      <td>
                        <div className="flex flex-row gap-x-3 py-4">
                          <span>
                            {new Date(activity.timestamp as string).toLocaleDateString('en-us', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                            })}
                          </span>
                          <span className="!text-gray-400">
                            {new Date(activity.timestamp as string).toLocaleTimeString('en-us', {
                              hour: '2-digit',
                              minute: '2-digit',
                            })}
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
        {activities && activities.length < 1 && (
          <div className="h-[9rem]">
            <NoDataSection />
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentActivitySection;
