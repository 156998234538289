import { ChangeEventHandler, forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import CircleWarningIcon from '../Icons/CircleWarningIcon/CircleWarningIcon';

interface TextareaProps {
  value?: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  onFocus?: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  error?: string;
  className?: string;
  maxLength?: number;
  label?: string;
  optional?: boolean;
  disabled?: boolean;
  characterCount?: boolean;
  maxCharacter?: number;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      value,
      onChange,
      onBlur,
      onFocus,
      placeholder,
      error,
      optional = false,
      disabled = false,
      label,
      maxCharacter,
      characterCount = false,
      className = '',
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation();
    // const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    //   const textarea = event.target;

    //   // Reset the textarea's height and then set it based on the scrollHeight
    //   textarea.style.height = 'auto';
    //   textarea.style.height = `${textarea.scrollHeight}px`;

    //   if (onChange) {
    //     onChange(event);
    //   }
    // };

    const internalRef = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(ref, () => internalRef.current as HTMLTextAreaElement);

    return (
      <div>
        <div className={`flex flex-col relative ${disabled && 'opacity-50 pointer-events-none'}`}>
          {label && (
            <label className={`${error ? 'text-errorRed-500' : 'text-gray-100'} text-xs font-medium mb-2`}>
              {label}{' '}
              {optional && (
                <span className={`${error ? 'text-errorRed-500' : 'text-gray-400'} text-xs font-medium`}>
                  ({t('InputField.optionalLabel')})
                </span>
              )}{' '}
            </label>
          )}
          <textarea
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            ref={internalRef}
            rows={3}
            className={`relative border ${
              error && '!border-errorRed-500'
            } autofill:shadow-[inset_0_0_0px_1000px_#272933] autofill:![-webkit-text-fill-color:#f5f7fc] autofill:![caret-color:white] bg-darkBlue rounded-lg py-4 pl-4 pr-7 text-gray-50 text-sm h-textarea font-normal focus:outline-none focus:border-secondary-400 hover:border-gray-800 placeholder:focus:text-transparent placeholder:text-gray-500 placeholder:tracking-[0.018rem] [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] ${
              internalRef.current?.value && internalRef.current?.value.length > 0
                ? 'border-gray-600 hover:border-gray-600'
                : 'border-transparent'
            } ${className}`}
            {...rest}
          />
          {characterCount && (
            <div className="absolute right-2 bottom-2 text-xs text-gray-500 w-fit bg-darkBlue">
              <span
                className={`${
                  internalRef.current?.value && internalRef.current?.value.length > 0
                    ? '!text-gray-100'
                    : 'text-gray-500'
                }`}
              >
                {internalRef.current ? internalRef.current.value.length : 0}
              </span>
              {maxCharacter && <span>/{maxCharacter}</span>}
            </div>
          )}
        </div>
        {error && (
          <p className="text-errorRed-500 mt-2 text-xs font-medium flex flex-row items-center gap-2">
            <CircleWarningIcon /> {error}
          </p>
        )}
      </div>
    );
  },
);
