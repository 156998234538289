import { SVGProps } from 'react';

interface ArrowDownUpIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const ArrowDownUpIcon: React.FC<ArrowDownUpIconProps> = ({ color = '#9BAFD0', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.16663 13.3334L6.66663 15.8334M6.66663 15.8334L4.16663 13.3334M6.66663 15.8334V4.16675M10.8333 6.66675L13.3333 4.16675M13.3333 4.16675L15.8333 6.66675M13.3333 4.16675V15.8334"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowDownUpIcon;
