import { SVGProps } from 'react';

interface PdfIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const PdfIcon: React.FC<PdfIconProps> = ({ color = '#7898FB', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M5.10048 0H11.7941L17.4606 5.90882V17.3995C17.4606 18.8344 16.295 20 14.8562 20H5.1005C3.6656 20 2.5 18.8344 2.5 17.3995V2.6005C2.49997 1.1656 3.66558 0 5.10048 0Z"
        fill={color}
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7852 0V4.1954C11.7852 5.11588 12.5313 5.86207 13.4518 5.86207H17.4573L11.7852 0Z"
        fill="white"
      />
      <path
        d="M12.6169 10.4059H7.34279C7.10043 10.4059 6.90039 10.2097 6.90039 9.96734C6.90039 9.725 7.10043 9.52881 7.34279 9.52881H12.6169C12.8592 9.52881 13.0554 9.725 13.0554 9.96734C13.0554 10.2097 12.8592 10.4059 12.6169 10.4059ZM10.8589 15.68H7.34279C7.10043 15.68 6.90039 15.4838 6.90039 15.2414C6.90039 14.9991 7.10043 14.8029 7.34279 14.8029H10.8589C11.1012 14.8029 11.2974 14.9991 11.2974 15.2414C11.2974 15.4838 11.1012 15.68 10.8589 15.68ZM12.6169 13.9219H7.34279C7.10043 13.9219 6.90039 13.7257 6.90039 13.4834C6.90039 13.2411 7.10043 13.0449 7.34279 13.0449H12.6169C12.8592 13.0449 13.0554 13.2411 13.0554 13.4834C13.0554 13.7257 12.8592 13.9219 12.6169 13.9219ZM12.6169 12.1639H7.34279C7.10043 12.1639 6.90039 11.9677 6.90039 11.7254C6.90039 11.483 7.10043 11.2868 7.34279 11.2868H12.6169C12.8592 11.2868 13.0554 11.483 13.0554 11.7254C13.0554 11.9677 12.8592 12.1639 12.6169 12.1639Z"
        fill="white"
      />
    </svg>
  );
};

export default PdfIcon;
