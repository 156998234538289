import { useEffect, useState } from 'react';

import { useGetWorkspacesById } from '@/api/workspaces/useGetWorkspaceById';

import { attributeTypeOptions } from '../CreateSegment/ConditionsScheme';
import { handleOperatorOptions } from '../CreateSegment/ConditionsScheme';
const useParseRuleObject = (rule: string) => {
  const [mainLogicalOperator, setMainLogicalOperator] = useState<boolean>(false);
  const [conditions, setConditions] = useState<
    {
      nestedLogicalOperator: boolean | null;
      conditionGroup: string | null;
      nestedConditions: {
        attribute: { value: string; label: string } | undefined;
        attributeType: { value: string; label: string } | undefined;
        operator: { value: string; label: string } | undefined;
        value: string;
      }[];
    }[]
  >([]);
  const { data: workspaceData } = useGetWorkspacesById();

  const predefinedAttributes = workspaceData?.predefinedAttributes || [];
  const coreAttributes = workspaceData?.coreAttributes || [];

  const combinedAttributesSet = new Set([...predefinedAttributes, ...coreAttributes]);

  const uniqueAttributesArray = Array.from(combinedAttributesSet);

  const attributeOptions = uniqueAttributesArray.map((attribute, index) => ({
    value: index.toString(),
    label: attribute,
  }));

  const findAttributeType = (attributeTypeLabel: string) => {
    return attributeTypeOptions.find((item) => item.label === attributeTypeLabel);
  };

  const findOperator = (attributeType: string, operatorLabel: string) => {
    const arrayOfOperators = handleOperatorOptions(attributeType);
    return arrayOfOperators.find((item) => item.label === operatorLabel);
  };

  const findAttribute = (attributeLabel: string) => {
    if (attributeOptions) {
      return attributeOptions.find((item) => item.label === attributeLabel);
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    if (rule && attributeOptions) {
      const parsedObject = JSON.parse(rule);
      const attributesWithTypes = parsedObject.rule.attributes;
      const parsedConditions = Object.values(parsedObject.rule.decisions[0].conditions)[0] as any[];

      const conditions = parsedConditions.map((item) => {
        if (Object.keys(item)[0] === 'fact') {
          const attribute = attributesWithTypes.find((attr: any) => attr.name === item.fact);
          const alternativeAttribute = attributesWithTypes.find((attr: any) =>
            item.params ? attr.name === item.params.key : '',
          );
          return {
            nestedLogicalOperator: null,
            conditionGroup: null,
            nestedConditions: [
              {
                attribute: findAttribute(item.fact) ?? findAttribute(alternativeAttribute.name),
                attributeType: findAttributeType(attribute ? attribute.type : alternativeAttribute.type),
                operator: findOperator(attribute ? attribute.type : alternativeAttribute.type, item.operator),
                value: item.value,
              },
            ],
          };
        } else {
          const nestedLogicalOperator = Object.keys(item)[0] === 'all' ? false : true;
          const nestedConds = Object.values(item)[0] as any[];
          return {
            nestedLogicalOperator: nestedLogicalOperator,
            conditionGroup: Math.random().toString(),
            nestedConditions: [
              ...nestedConds.map((nestedItem) => {
                const nestedAttribute = attributesWithTypes.find((attr: any) => attr.name === nestedItem.fact);
                const nestedAlternativeAttribute = attributesWithTypes.find((attr: any) =>
                  nestedItem.params ? attr.name === nestedItem.params.key : '',
                );
                return {
                  attribute: findAttribute(nestedItem.fact) ?? findAttribute(nestedAlternativeAttribute.name),
                  attributeType: findAttributeType(
                    nestedAttribute ? nestedAttribute.type : nestedAlternativeAttribute.type,
                  ),
                  operator: findOperator(
                    nestedAttribute ? nestedAttribute.type : nestedAlternativeAttribute.type,
                    nestedItem.operator,
                  ),
                  value: nestedItem.value,
                };
              }),
            ],
          };
        }
      });

      setMainLogicalOperator(Object.keys(parsedObject.rule.decisions[0].conditions)[0] === 'all' ? false : true);
      setConditions(conditions);
    }
  }, [rule]);

  return [mainLogicalOperator, conditions];
};

export default useParseRuleObject;
