import React, { createContext, useContext, useEffect, useState } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { t } from 'i18next';

import { AwsConfigAuth } from '@/utils/config/auth';

Amplify.configure({ Auth: AwsConfigAuth });

interface User {
  username: string;
  pool: {
    userPoolId: string;
    clientId: string;
    client: {
      endpoint: string;
      fetchOptions: object;
    };
    advancedSecurityDataCollectionFlag: boolean;
    storage: {
      domain: string;
      path: string;
      expires: number;
      secure: boolean;
      sameSite: string;
    };
  };
  Session: null;
  client: {
    endpoint: string;
    fetchOptions: object;
  };
  signInUserSession: {
    idToken: {
      jwtToken: string;
      payload: {
        sub: string;
        'cognito:groups': string[];
        email_verified: boolean;
        iss: string;
        'cognito:username': string;
        given_name: string;
        'custom:userId': string;
        origin_jti: string;
        'custom:tenantId': string;
        aud: string;
        event_id: string;
        'custom:userRole': string;
        token_use: string;
        auth_time: number;
        exp: number;
        iat: number;
        family_name: string;
        jti: string;
        email: string;
      };
    };
    refreshToken: {
      token: string;
    };
    accessToken: {
      jwtToken: string;
      payload: {
        sub: string;
        'cognito:groups': string[];
        iss: string;
        client_id: string;
        origin_jti: string;
        event_id: string;
        token_use: string;
        scope: string;
        auth_time: number;
        exp: number;
        iat: number;
        jti: string;
        'custom:userRole': string;
        username: string;
      };
    };
    clockDrift: number;
  };
  attributes: {
    custom: {
      tenantId: string;
      userId: string;
      userRole: string;
    };
    email: string;
    email_verified: boolean;
    family_name: string;
    given_name: string;
    sub: string;
  };
}
interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  username: string;
  signIn: (username: string, password: string) => Promise<Result>;
  signOut: () => void;
  newPassword: (password: string) => Promise<Result>;
  forgotPassword: (username: string) => Promise<void>;
  forgotPasswordSubmit: (user: string, code: string, password: string) => Promise<void>;
  user: User | null;
}

interface Result {
  isSignInSuccess: boolean;
  message: string;
}

type Props = {
  children?: React.ReactNode;
};

const authContext = createContext({} as UseAuth);

export const ProvideAuth: React.FC<Props> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    checkUser();
  }, [username]);

  const checkUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);

      setUser(user);
      setUsername(user.username);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const signIn = async (username: string, password: string) => {
    try {
      const result = await Auth.signIn(username, password);

      if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(result);
        // Redirect the user to the create password page
        return { isSignInSuccess: false, message: 'NEW_PASSWORD_REQUIRED' };
      } else {
        setUsername(result.username);
        setIsAuthenticated(true);

        return { isSignInSuccess: true, message: '' };
      }
    } catch (error) {
      return {
        isSignInSuccess: false,
        message: t('CommonErrors.incorrectUsernameOrPassword'),
      };
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setUsername('');
      setIsAuthenticated(false);
      setUser(null);
      return { isSignInSuccess: true, message: '' };
    } catch (error) {
      return {
        isSignInSuccess: false,
        message: t('CommonErrors.logoutFail'),
      };
    }
  };

  const newPassword = async (password: string) => {
    try {
      await Auth.completeNewPassword(user, password);

      return { isSignInSuccess: false, message: '' };
    } catch (error) {
      return {
        isSignInSuccess: false,
        message: t('CommonErrors.incorrectUsernameOrPassword'),
      };
    }
  };

  const forgotPassword = async (user: string) => {
    setUsername(user);
    await Auth.forgotPassword(user);
  };

  const forgotPasswordSubmit = async (user: string, code: string, password: string) => {
    await Auth.forgotPasswordSubmit(user, code, password);
  };

  return {
    isLoading,
    isAuthenticated,
    username,
    signIn,
    signOut,
    newPassword,
    forgotPassword,
    forgotPasswordSubmit,
    user,
  };
};
