import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const protectedRoute =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) => {
    const navigate = useNavigate();
    async function checkAuthState() {
      try {
        await Auth.currentAuthenticatedUser();
      } catch (err) {
        navigate('/');
      }
    }
    useEffect(() => {
      checkAuthState();
    });
    return <Component {...props} />;
  };

export default protectedRoute;
