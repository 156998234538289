import { SVGProps, useState } from 'react';

interface BellRingIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
  width?: string;
  height?: string;
  isHoverable?: boolean;
  tooltipLabel?: string;
}

const BellRingIcon: React.FC<BellRingIconProps> = ({
  color = '#EFE605',
  width = '20',
  height = '20',
  isHoverable = false,
  tooltipLabel,
  ...props
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative" onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
        <path
          d="M12.4997 14.1667V15C12.4997 16.3807 11.3804 17.5 9.99967 17.5C8.61896 17.5 7.49967 16.3807 7.49967 15V14.1667M12.4997 14.1667H7.49967M12.4997 14.1667H15.4918C15.8105 14.1667 15.9707 14.1667 16.0998 14.1231C16.3463 14.04 16.5392 13.8464 16.6224 13.5998C16.6661 13.4702 16.6661 13.3096 16.6661 12.9883C16.6661 12.8477 16.6659 12.7774 16.6549 12.7104C16.6342 12.5837 16.585 12.4636 16.5101 12.3594C16.4706 12.3043 16.4203 12.254 16.3212 12.1549L15.9966 11.8303C15.8919 11.7255 15.833 11.5835 15.833 11.4353V8.33334C15.833 5.11168 13.2213 2.5 9.99967 2.50001C6.77802 2.50002 4.16634 5.11169 4.16634 8.33334V11.4354C4.16634 11.5835 4.10738 11.7255 4.00265 11.8303L3.67806 12.1548C3.57865 12.2542 3.52887 12.3042 3.48926 12.3594C3.41439 12.4637 3.3648 12.5837 3.34401 12.7104C3.33301 12.7774 3.33301 12.8477 3.33301 12.9883C3.33301 13.3096 3.33301 13.4702 3.37672 13.5998C3.45988 13.8464 3.65367 14.04 3.90023 14.1231C4.02931 14.1667 4.18885 14.1667 4.50764 14.1667H7.49967M15.0151 1.67807C16.1645 2.54416 17.0699 3.69315 17.6433 5.01312M4.98465 1.67807C3.8353 2.54416 2.92984 3.69315 2.35645 5.01312"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {isHoverable && showTooltip && (
        <div className="absolute left-8 -bottom-[6px] bg-custom-darkBlue p-2 rounded-lg z-10 whitespace-nowrap">
          {tooltipLabel}
        </div>
      )}
    </div>
  );
};

export default BellRingIcon;
