export type ChipSize = 'large' | 'small';
export type ChipVariant = 'light' | 'dark' | 'gray';
interface ChipProps {
  label: string;
  className?: string;
  onClick?: () => void;
  size?: ChipSize;
  variant?: ChipVariant;
}

const Chip: React.FC<ChipProps> = ({ label, className, onClick, size = 'small', variant = 'gray' }) => {
  let chipClasses = `rounded-[50px] flex justify-center items-center w-fit py-2 px-4 font-medium ${
    size === 'small' ? 'h-8 text-xs' : 'h-10 text-base'
  }`;
  switch (variant) {
    case 'light':
      chipClasses += ' bg-primary-100 text-primary-600';
      break;
    case 'dark':
      chipClasses += ' bg-primary-400 text-gray-900';
      break;
    case 'gray':
      chipClasses += ' bg-gray-800 text-gray-50';
      break;
    default:
      break;
  }
  return (
    <div onClick={onClick} className={`${chipClasses} ${className}`}>
      {label}
    </div>
  );
};

export default Chip;
