import { useTranslation } from 'react-i18next';

interface LabelCardParams {
  label: string;
  value: any;
}

const StringLabel = (params: LabelCardParams) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-col gap-y-1">
        <span className="text-white text-xs font-normal tracking-[0.24px]">{t(params.label)}</span>
        <span className="text-gray-400 text-sm font-normal truncate">{params.value}</span>
      </div>
    </>
  );
};

export default StringLabel;
