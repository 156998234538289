import { SVGProps } from 'react';

interface CircleInfoIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
  width?: string;
  height?: string;
}

const CircleInfoIcon: React.FC<CircleInfoIconProps> = ({
  color = '#7085A8',
  width = '18',
  height = '18',
  ...props
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 21" fill="none">
      <path
        d="M10 9.45231V13.619M10 17.7856C5.85786 17.7856 2.5 14.4278 2.5 10.2856C2.5 6.14351 5.85786 2.78564 10 2.78564C14.1421 2.78564 17.5 6.14351 17.5 10.2856C17.5 14.4278 14.1421 17.7856 10 17.7856ZM10.0415 6.95231V7.03564L9.9585 7.03581V6.95231H10.0415Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CircleInfoIcon;
