import React from 'react';
import { CSVLink } from 'react-csv';

type HeaderLine = {
  label: string;
  key: string;
};

interface CsvLinkProps {
  data: any[] | string;
  headers?: HeaderLine[];
  fileName?: string;
  target?: string;
  className?: string;
  linkLabel: string;
}

const CsvLink: React.FC<CsvLinkProps> = ({
  data,
  headers,
  fileName = 'example_file',
  target = '_blank',
  className,
  linkLabel,
}) => {
  return (
    <span className={className}>
      <CSVLink data={data} headers={headers} filename={fileName} target={target}>
        {linkLabel}
      </CSVLink>
    </span>
  );
};

export default CsvLink;
