import { SVGProps } from 'react';

interface ImageIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const ImageIcon: React.FC<ImageIconProps> = ({ color = '#7898FB' }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.25004 12.5001C1.25 12.4516 1.25 12.4017 1.25 12.3501V3.65015C1.25 2.81007 1.25 2.38972 1.41349 2.06885C1.5573 1.7866 1.7866 1.5573 2.06885 1.41349C2.38972 1.25 2.81007 1.25 3.65015 1.25H12.3501C13.1902 1.25 13.61 1.25 13.9309 1.41349C14.2132 1.5573 14.4429 1.7866 14.5867 2.06885C14.75 2.3894 14.75 2.80925 14.75 3.64768V12.3523C14.75 12.7161 14.75 13.0009 14.7367 13.2331M1.25004 12.5001C1.25062 13.2413 1.26003 13.6294 1.41349 13.9305C1.5573 14.2128 1.7866 14.4429 2.06885 14.5867C2.3894 14.75 2.80925 14.75 3.64768 14.75H12.3527C13.1911 14.75 13.6104 14.75 13.9309 14.5867C14.2132 14.4429 14.4429 14.2128 14.5867 13.9305C14.6791 13.7491 14.7193 13.536 14.7367 13.2331M1.25004 12.5001L4.82598 8.32812L4.82704 8.32697C5.1442 7.95695 5.30305 7.77163 5.49145 7.70483C5.65696 7.64616 5.83765 7.64753 6.00232 7.7085C6.18998 7.77797 6.34629 7.96541 6.65894 8.34058L8.66231 10.7446C8.95171 11.0919 9.09726 11.2666 9.27397 11.3367C9.42984 11.3985 9.60174 11.4055 9.76257 11.358C9.94565 11.304 10.1068 11.1428 10.4291 10.8206L10.8019 10.4478C11.13 10.1196 11.2941 9.95569 11.4798 9.9021C11.6429 9.85503 11.8172 9.86514 11.9741 9.92993C12.1528 10.0037 12.2976 10.1843 12.5875 10.5466L14.7367 13.2331M14.7367 13.2331L14.75 13.2497M10.25 5.75C9.83579 5.75 9.5 5.41421 9.5 5C9.5 4.58579 9.83579 4.25 10.25 4.25C10.6642 4.25 11 4.58579 11 5C11 5.41421 10.6642 5.75 10.25 5.75Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ImageIcon;
