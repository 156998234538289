import { useRef, useState } from 'react';

import useOutsideClick from '@/modules/shared/hooks/useOutsideClick';

import ChevronIcon from '../../primitives/Icons/ChevronIcon/ChevronIcon';

interface DropDownOption {
  text: string;
}

interface OptionPickerProps {
  options: DropDownOption[];
  setSelectedOption: (option: any) => void;
  selectedOption: { text: string };
  className?: string;
}

const OptionPicker: React.FC<OptionPickerProps> = ({ options, selectedOption, className = '', setSelectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropDown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: DropDownOption) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  return (
    <div ref={dropdownRef} className="relative inline-block text-left z-20">
      <div
        onClick={toggleDropDown}
        className="rounded-lg flex justify-center items-center w-fit-content py-2 px-4 text-base font-normal bg-gray-700 text-custom-aliceBlue w-fit hover:cursor-pointer"
      >
        <span className="after:content-['\00a0\00a0']">{selectedOption.text}</span>
        <ChevronIcon type={isOpen ? 'up' : 'down'} />
      </div>
      {isOpen && (
        <div
          className={`origin-top-right absolute mt-2 w-fit rounded-xl shadow-md right-0 bg-gray-700 text-custom-aliceBlue ${className}`}
        >
          <ul className="p-2" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {options.map((option, index) => (
              <li
                key={index}
                className="flex px-4 py-2 cursor-pointer text-base rounded hover:bg-secondary-300 hover:text-gray-900"
                onClick={() => handleOptionClick(option)}
              >
                <span className="whitespace-nowrap">{option.text}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default OptionPicker;
