import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import AuthLayout from '@/component-library/layouts/AuthLayout/AuthLayout';
import { Button } from '@/component-library/primitives/Button/Button';
import ArrowIcon from '@/component-library/primitives/Icons/ArrowIcon/ArrowIcon';
import Link from '@/component-library/primitives/Link/Link';
import { Input } from '@/component-library/widgets/Input/Input';
import { PhoneData } from '@/models/PhoneData';

const ChangePhoneNumberPage = () => {
  const { t } = useTranslation();

  const schema = useMemo(() => {
    return yup
      .object()
      .shape({
        tenantPhone: yup.string().required(t('CommonErrors.tenantPhoneRequired')),
      })
      .required();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PhoneData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = () => {
    try {
      console.log('a');
    } catch (error) {
      console.log('Something went wrong!');
    }
  };
  return (
    <AuthLayout title={t('ChangePhoneNumberPage.title') + ' 📱'} content={t('ChangePhoneNumberPage.subtitle')}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full space-y-4">
        <Input
          placeholder={t('ChangePhoneNumberPage.tenantPhonePlaceholder')}
          {...register('tenantPhone')}
          error={errors.tenantPhone?.message}
        />
        <Button className="w-full">{t('ChangePhoneNumberPage.buttonContinue')}</Button>
      </form>
      <Link to={'#'} label={t('ChangePhoneNumberPage.linkBack')} iconLeft={<ArrowIcon type="left" color="#82e6b8" />} />
    </AuthLayout>
  );
};

export default ChangePhoneNumberPage;
