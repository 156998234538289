const Loader = () => {
  return (
    <div className="h-full w-full relative flex items-center justify-center" data-testid="loader">
      <div className="absolute flex items-center justify-center">
        {/* <div className="bg-gradient-to-r from-teal-300 via-gray-800 to-yellow-400 animate-gradient-x p-4 rounded-full">
          <div className="w-8 h-8 border-t-2 border-white rounded-full animate-spin"></div>
        </div> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="124"
          height="121"
          viewBox="0 0 124 121"
          fill="none"
          className="animate-spin"
        >
          <circle cx="12.0556" cy="34.9536" r="5.74074" fill="#9BEBC6" />
          <circle cx="5.74074" cy="59.065" r="5.74074" fill="#9BEBC6" />
          <circle cx="10.9074" cy="83.1758" r="5.74074" fill="#9BEBC6" />
          <circle cx="26.9815" cy="103.269" r="5.74074" fill="#9BEBC6" />
          <circle cx="48.7963" cy="114.75" r="5.74074" fill="#9BEBC6" />
          <circle opacity="0.6" cx="74.0556" cy="114.75" r="5.74074" fill="#9BEBC6" />
          <circle opacity="0.4" cx="97.0186" cy="103.269" r="5.74074" fill="#9BEBC6" />
          <circle opacity="0.5" cx="113.093" cy="84.3242" r="5.74074" fill="url(#paint0_linear_1492_12098)" />
          <circle opacity="0.5" cx="118.259" cy="59.065" r="5.74074" fill="url(#paint1_linear_1492_12098)" />
          <path
            d="M45.5954 4.28559C44.8812 3.40151 43.7405 2.99071 42.621 3.20706L16.5192 8.2307C15.3396 8.44308 14.4392 9.28421 14.1112 10.4287C13.7765 11.5934 14.4198 13.1715 15.3847 13.8961L22.8761 19.52C23.6445 20.0965 24.7081 20.069 25.4459 19.4515L35.3978 11.1429C35.9002 10.7088 36.6584 10.7794 37.0721 11.2989C37.4872 11.8024 37.4177 12.5494 36.9153 12.9835L26.9473 21.2922C26.2081 21.9079 25.9834 22.953 26.4045 23.8193L30.5124 32.2975C30.9922 33.3039 31.9212 33.9356 32.9951 34.0356C33.1216 34.0473 33.2636 34.0606 33.3916 34.0564C34.6383 34.0123 35.696 33.2612 36.1702 32.103L46.0679 7.42353C46.5003 6.3737 46.3097 5.16968 45.5954 4.28559Z"
            fill="#9BEBC6"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1492_12098"
              x1="107.352"
              y1="84.3242"
              x2="121.499"
              y2="82.479"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9BEBC6" stopOpacity="0.74" />
              <stop offset="1" stopColor="#1F2129" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1492_12098"
              x1="112.519"
              y1="59.065"
              x2="124"
              y2="59.065"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9BEBC6" stopOpacity="0.34" />
              <stop offset="1" stopColor="#1F2129" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default Loader;
