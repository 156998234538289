import React from 'react';
import * as ToastUI from '@radix-ui/react-toast';

export type ToastType = 'info' | 'success' | 'warning' | 'error' | 'default';

type Props = {
  children?: React.ReactNode;
};

export interface ToastProps {
  className?: string;
  isOpen: boolean;
  setOpen: (val: boolean) => void;
  message: string;
  toastType: ToastType;
}

export const ProvideToasts: React.FC<Props> = ({ children }) => {
  return <ToastUI.Provider duration={2000}>{children}</ToastUI.Provider>;
};

export const Toast: React.FC<ToastProps> = ({ toastType = 'default', className = '', isOpen, setOpen, message }) => {
  let toastClasses = ' flex rounded-md shadow-lg items-center justify-between p-4 data-[state=open]: animate-slideIn';

  switch (toastType) {
    case 'info':
      toastClasses += ' bg-primary-500 text-gray-100';
      break;
    case 'success':
      toastClasses += ' bg-primary-400 text-gray-100';
      break;
    case 'warning':
      toastClasses += ' bg-yellow-500 text-gray-100';
      break;
    case 'error':
      toastClasses += ' bg-red-500 text-gray-100';
      break;
    case 'default':
      toastClasses += ' bg-white text-gray-900';
      break;
    default:
      break;
  }

  return (
    <>
      <ToastUI.Root className={`${toastClasses} ${className}`} open={isOpen} onOpenChange={setOpen}>
        <ToastUI.Description className="m-0 text-sm">{message}</ToastUI.Description>
        <ToastUI.Close className="text-gray-100 hover:text-gray-900 text-sm">{'x'}</ToastUI.Close>
      </ToastUI.Root>
      <ToastUI.Viewport className="fixed top-0 right-0 flex flex-col gap-3 p-6 w-60 max-w-full m-0 list-none outline-none z-50" />
    </>
  );
};
