import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from '@/component-library/primitives/Tooltip/Tooltip';
import { NotificationOverview } from '@/models/NotificationsByWorkspace';

import StringLabel from './StringLabel';

interface BasicDataSidePanelProps {
  notificationData: NotificationOverview | undefined;
  setShowContentModal: (value: boolean) => void;
}

const BasicDataSidePanel: React.FC<BasicDataSidePanelProps> = ({ notificationData, setShowContentModal }) => {
  const { t } = useTranslation();
  const [isContentLabelHovered, setIsContentLabelHovered] = useState(false);

  return (
    <div className="absolute top-0 left-[11rem] bg-memberProfileSideMenuBG w-[16.688rem] h-full py-11 px-6">
      <div className="mt-1">
        <h2 className="text-xs font-semibold text-secondary-400 tracking-[0.24px] mb-7">
          {t('MemberProfilePage.detailsLabel')}
        </h2>
        <section className="space-y-6 mb-6">
          <StringLabel label="NotificationOverviewPage.titleLabel" value={notificationData?.title} />
          <StringLabel label="NotificationOverviewPage.subjectLabel" value={notificationData?.subject} />

          <div className="flex flex-col gap-y-1">
            <span className="text-white text-xs font-normal tracking-[0.24px]">
              {t('NotificationOverviewPage.contentLabel')}
            </span>
            <div
              className="text-sm font-normal cursor-pointer hover:underline decoration-[#82E6B8] w-fit"
              onClick={() => setShowContentModal(true)}
            >
              <Tooltip
                side="right"
                content={
                  <div className="text-gray-100 text-xs font-light">{t('NotificationOverviewPage.contentTooltip')}</div>
                }
                open={isContentLabelHovered}
                onOpenChange={(open: boolean) => {
                  if (open) {
                    setIsContentLabelHovered(true);
                  } else {
                    setIsContentLabelHovered(false);
                  }
                }}
                contentClassName="bg-custom-darkBlue p-2 ml-2 rounded-lg z-50 w-36 text-center"
                arrowClassName="w-0 h-0"
              >
                <div className="text-primary-400 ">
                  {notificationData?.title}
                  <div className="before:content-['\00a0\00a0'] after:content-searchIcon inline-block align-sub"></div>
                </div>
              </Tooltip>
            </div>
          </div>
          <StringLabel label="NotificationOverviewPage.sentFromLabel" value={notificationData?.fromEmail} />
          <StringLabel label="NotificationOverviewPage.replyToEmailLabel" value={notificationData?.replyToEmail} />
          <StringLabel
            label="NotificationOverviewPage.deliveryScheduleTypeLabel"
            value={notificationData?.deliveryScheduleType}
          />
          {notificationData?.specificTime && (
            <div className="flex flex-col gap-y-1">
              <span className="text-white text-xs font-normal tracking-[0.24px]">
                {t('NotificationOverviewPage.specificTimeLabel')}
              </span>
              <div className="text-gray-400 text-sm font-normal flex flex-row gap-x-1">
                <span>
                  {new Date(notificationData.specificTime).toLocaleDateString('en-us', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}
                </span>
                <span>-</span>
                <span className="text-sm">
                  {new Date(notificationData.specificTime).toLocaleTimeString('en-us', {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </span>
              </div>
            </div>
          )}
        </section>
        <hr className="w-full border-gray-800 border-[1.5px]" />
        <section className="space-y-6 mt-6">
          <StringLabel label="MemberProfilePage.createdByLabel" value={notificationData?.createdByName} />
          <div className="flex flex-col gap-y-1">
            <span className="text-white text-xs font-normal tracking-[0.24px]">
              {t('NotificationOverviewPage.createdAtLabel')}
            </span>
            <div className="text-gray-400 text-sm font-normal flex flex-row gap-x-1">
              <span>
                {new Date(notificationData?.createdAt as string).toLocaleDateString('en-us', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </span>
              <span>-</span>
              <span className="text-sm">
                {new Date(notificationData?.createdAt as string).toLocaleTimeString('en-us', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </span>
            </div>
          </div>
        </section>
      </div>
      <div></div>
    </div>
  );
};

export default BasicDataSidePanel;
