import { SVGProps } from 'react';

interface SortAscendingIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const SortAscendingIcon: React.FC<SortAscendingIconProps> = ({ color = '#9BAFD0', ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 17H10M4 12H13M18 11V19M18 19L21 16M18 19L15 16M4 7H16"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SortAscendingIcon;
