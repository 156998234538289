export const AppRoutes = {
  //auth
  signIn: '/sign-in',
  signUp: '/sign-up',
  forgotPassword: '/forgot-password',
  createNewPassword: '/create-new-password',
  resetPassword: '/reset-password',
  contactUs: '/contact-us',
  verifyEmail: '/verify-email',

  dashboard: '/dashboard',
  //single sends
  singleSends: '/single-sends/*',
  buildMessage: '/build-message',
  newSMS: '/build-message/sms/new',
  newEmail: '/build-message/email/new',
  newPush: '/build-message/push/new',

  changePhoneNumber: '/change-phone-number',
  campaigns: '/campaigns',
  createCampaign: '/campaigns/create',
  deleteCampaign: '/campaigns/:id/delete',
  delivery: '/delivery',
  audience: '/audience',
  members: '/members',
  segments: '/segments',
  companies: '/companies',
  segmentOverview: '/segments/:id/overview',
  createSegment: '/segments/create',
  activityLogs: '/activity-logs',
  settings: '/settings',
  account: '/settings/account',
  personalAccount: '/settings/personal-account',
  apiCredentials: '/settings/api-credentials',
  createApiCredentials: '/settings/api-credentials/create',
  deleteApiCredentials: '/settings/api-credentials/:id/delete',
  teamMembers: '/settings/team-members',
  createTeamMember: '/settings/team-members/create',
  workspaces: '/workspaces',
  workspaceDetails: '/workspaces/:id',
  createWorkspace: '/workspaces/create',
  deleteWorkspace: '/workspaces/:id/delete',
  deleteAPICredentials: '/settings/api-credentials/:id/delete',
  editWorkspace: '/workspaces/:id/edit',
  memberProfile: '/members/:id',
  createMember: '/members/create',
  importMembers: '/members/import',
  templates: '/templates',
  templatesEdit: '/templates/:id/edit',
  createTemplate: '/templates/create',
  pushNotifications: '/settings/push-notifications',
  pushNotificationsEnableIos: '/settings/push-notifications/enable-ios',
  pushNotificationsEnableAndroid: '/settings/push-notifications/enable-android',
  deliveryOverview: '/delivery/:id',
  help: '/help',
  domains: '/settings/domains',
  domainOverview: '/settings/domains/overview/:domainName',
  createDomain: '/settings/domains/create',
};
