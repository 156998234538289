import { Link as ReactLink } from 'react-router-dom';
export type LinkSize = 'large' | 'small';
export type LinkVariant = 'primary' | 'secondary' | 'gray' | 'destructive';

export interface LinkProps {
  variant?: LinkVariant;
  size?: LinkSize;
  to: string;
  label: string;
  className?: string;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
  disabled?: boolean;
}

const Link: React.FC<LinkProps> = ({
  to,
  label,
  variant = 'primary',
  size = 'small',
  iconLeft,
  iconRight,
  className = '',
  disabled = false,
}) => {
  let linkClasses = 'flex items-center justify-center gap-2 tracking-[0.32px] font-medium underline';
  let iconClasses = '';

  switch (variant) {
    case 'primary':
      linkClasses += ` group text-primary-400 hover:text-primary-500 active:text-primary-300 ${
        disabled && '!text-gray-500 pointer-events-none no-underline'
      }`;
      iconClasses += ` group-hover:[&>*_path]:fill-primary-500 group-active:[&>*_path]:fill-primary-300 ${
        disabled && '[&>*_path]:fill-gray-500'
      }`;
      break;
    case 'secondary':
      linkClasses += ` group text-secondary-400 hover:text-secondary-500 active:text-secondary-300 ${
        disabled && '!text-gray-500 pointer-events-none no-underline'
      }`;
      iconClasses += ` group-hover:[&>*_path]:fill-secondary-500 group-active:[&>*_path]:fill-secondary-300 ${
        disabled && '[&>*_path]:fill-gray-500'
      }`;
      break;
    case 'gray':
      linkClasses += ` group text-gray-500 hover:text-gray-400 active:text-gray-300 ${
        disabled && '!text-gray-500 pointer-events-none no-underline'
      }`;
      iconClasses += ` group-hover:[&>*_path]:fill-gray-400 group-active:[&>*_path]:fill-gray-300 ${
        disabled && '[&>*_path]:fill-gray-500'
      }`;
      break;
    case 'destructive':
      linkClasses += ` group text-errorRed-500 hover:text-errorRed-400 active:text-errorRed-300 ${
        disabled && '!text-gray-500 pointer-events-none no-underline'
      }`;
      iconClasses += ` group-hover:[&>*_path]:fill-errorRed-400 group-active:[&>*_path]:fill-errorRed-300 ${
        disabled && '[&>*_path]:fill-gray-500'
      }`;
      break;
    default:
      break;
  }

  if (size === 'large') {
    linkClasses += ' text-base';
  }
  if (size === 'small') {
    linkClasses += ' text-sm';
  }

  return (
    <ReactLink to={to} className={`${linkClasses} ${className}`}>
      <div className={iconClasses}>{iconLeft && iconLeft}</div>
      {label}
      <div className={iconClasses}>{iconRight && iconRight}</div>
    </ReactLink>
  );
};

export default Link;
