import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';

import { SesRecord } from '@/models/Domain';

export const useDomainDkimTable = () => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<SesRecord>();

  const columns = [
    columnHelper.accessor('type', {
      header: () => <div>{t('DomainsPage.type')}</div>,
      cell: (info) => <span>{info.renderValue()}</span>,
    }),
    columnHelper.accessor('name', {
      header: () => <div>{t('DomainsPage.name')}</div>,
      cell: (info) => <span>{info.renderValue()}</span>,
    }),
    columnHelper.accessor('value', {
      header: () => <div>{t('DomainsPage.value')}</div>,
      cell: (info) => <span>{info.renderValue()}</span>,
    }),
  ];

  return { columns };
};
