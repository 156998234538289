import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';

import ArrowDownUpIcon from '@/component-library/primitives/Icons/ArrowDownUpIcon/ArrowDownUpIcon';
import { Member } from '@/models/AudienceByWorkspace';

export const useAudienceModalTable = () => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<Member>();

  const columns = [
    columnHelper.accessor('id', {
      header: () => <div>{t('AudiencePage.idColumn')}</div>,
      cell: (info) => <span className="block truncate max-w-[12rem]">{info.renderValue()}</span>,
    }),
    columnHelper.accessor('email', {
      header: () => (
        <div className="flex flex-row items-center gap-1">
          <span>{t('AudiencePage.emailColumn')}</span>
          <span className="cursor-pointer">
            <ArrowDownUpIcon />
          </span>
        </div>
      ),
      cell: (info) => <div>{info.renderValue()}</div>,
    }),
    columnHelper.accessor('name', {
      header: () => <div>{t('AudiencePage.nameColumn')}</div>,
      cell: (info) => <span>{info.renderValue()}</span>,
    }),
    columnHelper.accessor('createdByName', {
      header: () => <div>{t('AudiencePage.createdByNameColumn')}</div>,
      cell: (info) => <span>{info.renderValue()}</span>,
    }),
    columnHelper.accessor('createdAt', {
      header: () => <div>{t('AudiencePage.createdAtColumn')}</div>,
      cell: (info) => (
        <div className="flex flex-col">
          <span>
            {new Date(info.renderValue() as string).toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </span>
          <span className="text-sm">
            {new Date(info.renderValue() as string).toLocaleTimeString('en-us', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        </div>
      ),
    }),
  ];

  return { columns };
};
