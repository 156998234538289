const NoDataIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="130" height="103" viewBox="0 0 130 103" fill="none">
      <path d="M65 66V20L14 36.8715L65 66Z" fill="#494D67" />
      <path d="M65 66V20L116 36.8715L65 66Z" fill="#5D6282" />
      <g filter="url(#filter0_b_829_7372)">
        <path
          d="M13.7222 37L65 20.3789L52.9718 1.93329C52.2286 0.793563 50.8114 0.29673 49.5192 0.722898L3.79974 15.8015C1.90007 16.428 1.13403 18.703 2.26781 20.351L13.7222 37Z"
          fill="#5D6282"
          fillOpacity="0.6"
        />
      </g>
      <g filter="url(#filter1_b_829_7372)">
        <path
          d="M115.489 37L65 20.3789L76.8223 1.9658C77.5645 0.809835 78.997 0.305671 80.2996 0.741986L125.253 15.7995C127.135 16.4301 127.897 18.6828 126.784 20.3267L115.489 37Z"
          fill="#8187AF"
          fillOpacity="0.6"
        />
      </g>
      <path d="M65 103V53.6074L14 37V83.7429C14 86.4147 15.7667 88.7646 18.3334 89.5068L65 103Z" fill="#5D6282" />
      <path d="M65 103V53.6074L116 37V83.7429C116 86.4147 114.233 88.7646 111.667 89.5068L65 103Z" fill="#494D67" />
      <g filter="url(#filter2_b_829_7372)">
        <path
          d="M65 53.7345L116.286 37L128.018 56.047C129.031 57.692 128.248 59.852 126.415 60.4652L82.1648 75.2755C80.8857 75.7036 79.4782 75.2254 78.7245 74.1068L65 53.7345Z"
          fill="#8187AF"
          fillOpacity="0.6"
        />
      </g>
      <g filter="url(#filter3_b_829_7372)">
        <path
          d="M65 53.6533L14 37L2.03561 55.9758C0.998965 57.62 1.77552 59.8008 3.61802 60.4197L47.8298 75.271C49.1113 75.7015 50.5225 75.2216 51.2759 74.0991L65 53.6533Z"
          fill="#8187AF"
          fillOpacity="0.6"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_829_7372"
          x="-10.3721"
          y="-11.5383"
          width="87.4821"
          height="60.6483"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.055" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_829_7372" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_829_7372" result="shape" />
        </filter>
        <filter
          id="filter1_b_829_7372"
          x="52.89"
          y="-11.5236"
          width="86.5212"
          height="60.6336"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.055" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_829_7372" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_829_7372" result="shape" />
        </filter>
        <filter
          id="filter2_b_829_7372"
          x="52.89"
          y="24.89"
          width="87.6847"
          height="62.6508"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.055" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_829_7372" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_829_7372" result="shape" />
        </filter>
        <filter
          id="filter3_b_829_7372"
          x="-10.5381"
          y="24.89"
          width="87.6481"
          height="62.6474"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.055" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_829_7372" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_829_7372" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default NoDataIcon;
