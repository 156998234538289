import { forwardRef } from 'react';

interface ToggleSwitchProps {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  withLabels?: { left: string; right: string };
}

export const ToggleSwitch = forwardRef<HTMLInputElement, ToggleSwitchProps>(
  ({ value, onChange, disabled = false, withLabels = undefined }, ref) => {
    const handleToggle = () => {
      onChange(!value);
    };

    if (withLabels) {
      return (
        <label
          className={`relative inline-flex items-center cursor-pointer ${disabled && 'opacity-50 pointer-events-none'}`}
        >
          <input ref={ref} type="checkbox" className="sr-only peer" checked={value} onChange={handleToggle} />
          <div className="w-[68px] h-6 bg-darkBlue rounded-[3px]">
            <div
              className={`absolute w-[34px] h-6 ${
                value
                  ? 'right-[0px] top-[0px] bg-primary-400 rounded-tr-[3px] rounded-br-[3px]'
                  : 'left-[0.5px] top-[0px] bg-primary-400 rounded-tl-[3px] rounded-bl-[3px]'
              }`}
            ></div>
            <div
              className={`text-xs font-semibold tracking-[0.24px] ${
                value ? 'text-gray-500' : 'text-gray-900'
              }  absolute left-[4px] top-[5px]`}
            >
              <span>{withLabels.left}</span>
            </div>
            <div
              className={`text-xs font-semibold tracking-[0.24px] absolute right-[8px] top-[5px] ${
                value ? 'text-gray-900' : 'text-gray-500'
              } `}
            >
              {withLabels.right}
            </div>
          </div>
        </label>
      );
    } else {
      return (
        <label
          className={`relative inline-flex items-center cursor-pointer ${disabled && 'opacity-50 pointer-events-none'}`}
        >
          <input ref={ref} type="checkbox" className="sr-only peer" checked={value} onChange={handleToggle} />
          <div
            className={`w-[2.438rem] h-[1.375rem] ${
              value ? 'bg-primary-400' : 'bg-transparent border border-gray-400'
            } rounded-full relative`}
          >
            <div
              className={`absolute rounded-full w-[1rem] h-[1rem] bg-gray-200' ${
                value ? 'right-[3px] top-[3px] bg-white' : 'left-[3px] top-[2px] bg-gray-400'
              }`}
            />
          </div>
        </label>
      );
    }
  },
);
