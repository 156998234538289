import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { useGetDomainDetails } from '@/api/settings/useGetDomainDetails';
import Loader from '@/component-library/primitives/Loader/Loader';
import NoDataBoard from '@/component-library/widgets/NoDataBoard/NoDataBoard';
import Table from '@/component-library/widgets/Table/Table';
import { AppRoutes } from '@/utils/routes/router';

import { useDomainDkimTable } from './useDomainDkimTable';

const DomainOverview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { columns } = useDomainDkimTable();

  const { domainName } = useParams();
  useEffect(() => {
    if (!domainName) {
      navigate(AppRoutes.domains);
    }
  }, [navigate]);

  const { data, isLoading } = useGetDomainDetails(domainName as string);

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'Pending':
        return 'text-yellow-200';
      case 'Success':
        return 'text-primary-400';
      case 'Failed':
      case 'Temporary_Failure':
        return 'text-errorRed-500';
      case 'Not_Started':
        return 'text-gray-100';
      default:
        return '';
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : data ? (
        <>
          <div className="px-5 pt-5 pb-7 w-full bg-darkBlue mb-[-5px] rounded-t-[10px] justify-between flex flex-row items-center">
            <div>
              <h1 className="text-gray-50 text-base font-normal tracking-[0.32px]">{t('DomainsPage.dkimLabel')}</h1>
              <p className="text-xs text-gray-300">{t('DomainsPage.dkimDescription')}</p>
            </div>
            <div>
              <h2 className="text-gray-50 text-base font-normal tracking-[0.32px] pt-4">{'Domain: ' + data?.domain}</h2>
              <h2 className={'text-gray-50 text-base font-normal tracking-[0.32px]'}>
                {'Status: '}
                <p className={`inline ${getStatusClass(data?.dkim.status)}`}>{data?.dkim.status}</p>
              </h2>
            </div>
          </div>
          <Table
            columns={columns}
            data={data?.dkim.records ?? []}
            showPagination={false}
            className={{
              row: 'h-[3.300rem]',
              cell: 'overflow-visible',
              table: 'mb-4',
            }}
          />
          {data.mailFrom && (
            <>
              <div className="px-5 pt-5 pb-7 w-full bg-darkBlue mb-[-5px] rounded-t-[10px] justify-between flex flex-row items-center">
                <div>
                  <h1 className="text-gray-50 text-base font-normal tracking-[0.32px]">
                    {t('DomainsPage.mailFromLabel')}
                  </h1>
                  <p className="text-xs text-gray-300">{t('DomainsPage.mailFromDescription')}</p>
                </div>
                <div>
                  <h2 className="text-gray-50 text-base font-normal tracking-[0.32px] pt-4">
                    {'MailFrom: ' + data?.mailFrom.mail}
                  </h2>
                  {data.mailFrom.status && (
                    <h2 className={'text-gray-50 text-base font-normal tracking-[0.32px]'}>
                      {'Status: '}
                      <p className={`inline ${getStatusClass(data?.mailFrom.status)}`}>{data?.mailFrom.status}</p>
                    </h2>
                  )}
                </div>
              </div>
              <Table
                columns={columns}
                data={data?.mailFrom.records ?? []}
                showPagination={false}
                className={{
                  row: 'h-[3.300rem]',
                  cell: 'overflow-visible',
                }}
              />
            </>
          )}
        </>
      ) : (
        <NoDataBoard description={t('NoDataBoard.addYourFirstDomain')} />
      )}
    </>
  );
};

export default DomainOverview;
