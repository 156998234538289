import { Auth } from 'aws-amplify';
import axios from 'axios';

axios.interceptors.request.use(
  async (config) => {
    try {
      try {
        await Auth.currentAuthenticatedUser();
      } catch (noAuthError) {
        return config;
      }

      const session = await Auth.currentSession();
      const idTokenExpire = session.getIdToken().getExpiration();
      const currentTimeSeconds = Math.round(+new Date() / 1000);

      if (idTokenExpire < currentTimeSeconds) {
        const refreshedSession = await Auth.currentAuthenticatedUser();
        const refreshedAccessToken = refreshedSession.getIdToken().getJwtToken();

        config.headers.Authorization = 'Bearer ' + refreshedAccessToken;
      } else {
        config.headers.Authorization = 'Bearer ' + session.getIdToken().getJwtToken();
      }

      return config;
    } catch (error) {
      console.error('Axios interceptor error:', error);
      return Promise.reject(error);
    }
  },
  (error) => {
    return Promise.reject(error);
  },
);
