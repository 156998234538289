import { SVGProps } from 'react';

interface HouseIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const HouseIcon: React.FC<HouseIconProps> = ({ color = '#9bafd0', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M16.6667 14.3633V9.73998C16.6667 9.2947 16.6663 9.07193 16.6122 8.86473C16.5642 8.68111 16.4854 8.50735 16.3788 8.35032C16.2586 8.17314 16.0913 8.0262 15.7562 7.73298L11.7562 4.23298C11.134 3.68857 10.823 3.41651 10.4729 3.31297C10.1644 3.22175 9.83555 3.22175 9.52706 3.31297C9.17722 3.41643 8.86658 3.68824 8.24534 4.23182L4.24402 7.73298C3.90891 8.0262 3.74174 8.17314 3.62146 8.35033C3.51487 8.50735 3.4355 8.68111 3.38752 8.86473C3.33337 9.07193 3.33337 9.29469 3.33337 9.73998V14.3633C3.33337 15.1398 3.33337 15.528 3.46024 15.8343C3.6294 16.2426 3.95364 16.5675 4.36202 16.7366C4.6683 16.8635 5.05659 16.8635 5.83316 16.8635C6.60973 16.8635 6.99844 16.8635 7.30473 16.7366C7.71311 16.5675 8.03726 16.2427 8.20642 15.8343C8.33329 15.528 8.33337 15.1398 8.33337 14.3632V13.5299C8.33337 12.6094 9.07957 11.8632 10 11.8632C10.9205 11.8632 11.6667 12.6094 11.6667 13.5299V14.3632C11.6667 15.1398 11.6667 15.528 11.7936 15.8343C11.9627 16.2427 12.287 16.5675 12.6954 16.7366C13.0016 16.8635 13.3899 16.8635 14.1665 16.8635C14.9431 16.8635 15.3318 16.8635 15.6381 16.7366C16.0464 16.5675 16.3706 16.2426 16.5398 15.8343C16.6666 15.528 16.6667 15.1398 16.6667 14.3633Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HouseIcon;
