import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Progress from '@radix-ui/react-progress';

import CloseIcon from '@/component-library/primitives/Icons/CloseIcon/CloseIcon';
import PdfIcon from '@/component-library/primitives/Icons/PdfIcon/PdfIcon';

interface SingleFileUploadWithProgressProps {
  singleFile: File;
  removeSingleFile: (file: File, event: React.MouseEvent) => void;
  showProgress: boolean;
  removeProgressBar: React.Dispatch<React.SetStateAction<boolean>>;
}

const SingleFileUploadWithProgress = ({
  singleFile,
  removeSingleFile,
  showProgress,
  removeProgressBar,
}: SingleFileUploadWithProgressProps) => {
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevValue) => {
        if (prevValue < 100) {
          return prevValue + 10;
        } else {
          return 100;
        }
      });
    }, 150);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      removeProgressBar(false);
      setProgress(0);
    }
  }, [progress]);

  const formatFileInformation = (file: File) => {
    const formatedUploadDate = new Date().toLocaleDateString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    const formatedUploadTime = new Date().toLocaleTimeString('en-us', {
      hour: '2-digit',
      minute: '2-digit',
    });
    const formatedFileSize =
      file.size < 1024
        ? `${file.size} bytes`
        : file.size < 1048576
        ? `${Math.floor(file.size / 1024)} KB`
        : `${Math.floor(file.size / 1048576)} MB`;

    return [formatedUploadTime, formatedUploadDate, formatedFileSize];
  };

  return (
    <>
      {showProgress ? (
        <div className="flex flex-col flex-start -mt-5">
          <p className="text-base font-medium text-white mb-6">{t('ImportMembersPage.uploadingFileLabel')}</p>
          <div className="flex flex-row items-center gap-3">
            <PdfIcon />
            <div className="flex flex-col gap-y-3">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row gap-1">
                  <span className="text-gray-100 text-sm font-light">{singleFile.name} </span>
                  <span className="text-gray-400 text-sm font-light">
                    ({[...formatFileInformation(singleFile)][2]})
                  </span>
                </div>
                <span className="text-gray-100 text-sm font-medium">{progress}%</span>
              </div>
              <Progress.Root
                className="relative overflow-hidden bg-progressBar rounded-full w-[440px] h-[4px]"
                style={{
                  transform: 'translateZ(0)',
                }}
                value={progress}
              >
                <Progress.Indicator
                  className="bg-primary-400 w-full h-full transition-transform duration-[660ms] ease-[cubic-bezier(0.65, 0, 0.35, 1)]"
                  style={{ transform: `translateX(-${100 - progress}%)` }}
                />
              </Progress.Root>
            </div>
            <button type="button" onClick={(event) => removeSingleFile(singleFile, event)}>
              <CloseIcon />
            </button>
          </div>
        </div>
      ) : (
        <div className="flex flex-row items-center gap-2">
          <PdfIcon />
          <span className="text-gray-100 text-sm font-light">{singleFile.name}</span>
          <span className="text-gray-400 text-sm font-light">
            ({t('ImportMembersPage.uploadedLabel')} {[...formatFileInformation(singleFile)].join(' ')})
          </span>
          <button type="button" onClick={(event) => removeSingleFile(singleFile, event)}>
            <CloseIcon />
          </button>
        </div>
      )}
    </>
  );
};

export default SingleFileUploadWithProgress;
