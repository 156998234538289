import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import BlotIcon from '../Icons/BlotIcon/BlotIcon';
import CircleCheckIcon from '../Icons/CircleCheckIcon/CircleCheckIcon';
import DialogBubbleIcon from '../Icons/DialogBubbleicon/DialogBubbleIcon';
import InformationIcon from '../Icons/InformationIcon/InformationIcon';

export type BannerType = 'success' | 'info';
interface BannerProps {
  // numberOfNewMembers: number;
  // numberOfExistingMembers?: number;
  variant: BannerType;
  className?: string;
}

const Banner: React.FC<PropsWithChildren<BannerProps>> = ({ variant, children, className }) => {
  const { t } = useTranslation();
  if (variant === 'success') {
    return (
      <div className={`flex flex-row rounded-lg bg-activeSideMenuOption ${className}`}>
        <div className="relative">
          <BlotIcon />
          <div className="absolute -top-5 left-6">
            <DialogBubbleIcon />
          </div>
          <div className="absolute -top-4 left-[31px]">
            <CircleCheckIcon color="#F2FCF8" width="28" height="28" />
          </div>
        </div>
        <div className="flex flex-col justify-center -ml-6 gap-1">{children}</div>
      </div>
    );
  } else {
    return (
      <div className={`flex flex-row rounded-lg bg-infoBanner border border-purple-600 ${className}`}>
        <div className="relative">
          <BlotIcon variant="info" color="#564EE7" />
          <div className="absolute -top-[21px] left-6">
            <DialogBubbleIcon color="#564EE7" width="29.275" height="30" />
          </div>
          <div className="absolute -top-4 left-[31px]">
            <InformationIcon />
          </div>
        </div>
        <div className="flex flex-col justify-center gap-1">{children}</div>
      </div>
    );
  }
};

export default Banner;
