import React, { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Edge, Handle, Node, NodeProps, NodeToolbar, Position, useReactFlow } from 'reactflow';

import useOutsideClick from '@/modules/shared/hooks/useOutsideClick';

type Type = 'input' | 'output' | 'default';

export type DiagramNodeData = {
  name: string;
  type: Type;
  className: string;
  emoji: string;
};

export type CustomNode = Node<DiagramNodeData>;

const DiagramNode = ({ data, id }: NodeProps<DiagramNodeData>) => {
  const { t } = useTranslation();
  const { setNodes, setEdges } = useReactFlow();
  const [isContextClicked, setIsContextClicked] = useState(false);
  const nodeRef = useRef(null);

  useOutsideClick(nodeRef, () => setIsContextClicked(false));

  const openContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsContextClicked(true);
  };

  const deleteNode = useCallback(() => {
    setNodes((nodes: CustomNode[]) => nodes.filter((node: CustomNode) => node.id !== id));
    setEdges((edges: Edge[]) => edges.filter((edge: Edge) => edge.source !== id));
  }, [id, setNodes, setEdges]);

  const nodeClasses = `px-4 py-2 rounded-lg flex justify-center items-center w-fit-content ${data.className}`;
  let handles = null;

  switch (data.type) {
    case 'input':
      handles = <Handle type="source" position={Position.Bottom} className="w-4 rounded-none" />;
      break;
    case 'output':
      handles = <Handle type="target" position={Position.Bottom} className="w-4 rounded-none" />;
      break;
    case 'default':
      handles = (
        <>
          <Handle type="target" position={Position.Top} className="w-4 rounded-none" />
          <Handle type="source" position={Position.Bottom} className="w-4 rounded-none" />
        </>
      );
      break;
    default:
      break;
  }
  return (
    <>
      {data.type !== 'input' && (
        <NodeToolbar position={Position.Bottom} className="bg-gray-700 rounded" isVisible={isContextClicked}>
          <button
            type="button"
            className="p-[0.375rem] px-[0.5rem] rounded cursor-pointer text-custom-aliceBlue hover:bg-secondary-300 hover:text-gray-900"
            onClick={deleteNode}
          >
            {t('CreateCampaignPage.deleteBtnLabel')}
          </button>
        </NodeToolbar>
      )}
      <div className={nodeClasses} onContextMenu={(e) => openContextMenu(e)} ref={nodeRef}>
        <div className="flex flex-row gap-1">
          <span>{data.emoji}</span>
          <span className="text-gray-900 text-base">{data.name}</span>
        </div>
        {handles}
      </div>
    </>
  );
};

export default memo(DiagramNode);
