import { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Papa from 'papaparse';
import * as yup from 'yup';

import { useGetSegments } from '@/api/segments/useGetSegments';
import { useImportMembers } from '@/api/workspaces/useImportMembers';
import { useReviewImport } from '@/api/workspaces/useReviewImportMembers';
import Banner from '@/component-library/primitives/Banner/Banner';
import { Button } from '@/component-library/primitives/Button/Button';
import ArrowSubDownRightIcon from '@/component-library/primitives/Icons/ArrowSubDownRightIcon/ArrowSubDownRightIcon';
import CheckIcon from '@/component-library/primitives/Icons/CheckIcon/CheckIcon';
import ChevronNewIcon from '@/component-library/primitives/Icons/ChevronNewIcon/ChevronNewIcon';
import CircleHelpIcon from '@/component-library/primitives/Icons/CircleHelpIcon/CircleHelpIcon';
import CircleWarningIcon from '@/component-library/primitives/Icons/CircleWarningIcon/CircleWarningIcon';
import TriangleWarningIcon from '@/component-library/primitives/Icons/TriangleWarningIcon/TriangleWarningIcon';
import Loader from '@/component-library/primitives/Loader/Loader';
import Stepper from '@/component-library/primitives/Stepper/Stepper';
import { Textarea } from '@/component-library/primitives/Textarea/Textarea';
import { ProvideToasts, ToastType } from '@/component-library/primitives/Toast/Toast';
import { Toast } from '@/component-library/primitives/Toast/Toast';
import Tooltip from '@/component-library/primitives/Tooltip/Tooltip';
import CsvLink from '@/component-library/widgets/CsvLink/CsvLink';
import { FileUploadInput } from '@/component-library/widgets/FileUploadInput/FileUploadInput';
import { Input } from '@/component-library/widgets/Input/Input';
import SelectField from '@/component-library/widgets/SelectField/SelectField';
import Table from '@/component-library/widgets/Table/Table';
import { ToggleSwitch } from '@/component-library/widgets/ToggleSwitch/ToggleSwitch';
import {
  ImportMembersDetails,
  PreviewMembersDetails,
  ReviewImport,
  ReviewImportResponse,
} from '@/models/WorkspaceDetails';
import { csvExampleAudienceData, csvExampleAudienceHeader } from '@/modules/shared/dummyData/csvExampleFile';
import { ErrorMessage } from '@/modules/shared/enums/enums';
import { readFileAsBase64 } from '@/utils/fileUtils';
import { AppRoutes } from '@/utils/routes/router';

import { useMapFieldsTable } from '../useMapFieldsTable';

const optionsForHandlingEmptyValues = [
  { label: 'Ignore them', value: '1' },
  { label: 'Delete existing attribute values', value: '2' },
];

const ImportMembers = () => {
  const importMembersMutation = useImportMembers();
  const reviewImport = useReviewImport();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const [reviewImportResponse, setReviewImportResponse] = useState<ReviewImportResponse>();
  const [reviewImportRequestData, setReviewImportRequestData] = useState<ReviewImport>();
  const [fileErrors, setFileErrors] = useState<never | string[]>([]);
  const [reviewImportError, setReviewImportError] = useState<null | string>();
  const [generalError, setGeneralError] = useState<null | string>();
  const [activeStep, setActiveStep] = useState(0);
  const [isEmptyValuesDropdownHovered, setIsEmptyValuesDropdownHovered] = useState(false);
  const [isUpdateExistingMembersHovered, setIsUpdateExistingMembersHovered] = useState(false);
  const [mapFieldsData, setMapFieldsData] = useState<
    {
      csvHeader: string;
      csvExample: any[];
      id: number;
      attribute: { value: string; label: string } | undefined;
      type: { value: string; label: string } | undefined;
      included: boolean;
    }[]
  >([]);
  const [updateExistingMembers, setUpdateExistingMembers] = useState(false);
  const [createNewSegment, setCreateNewSegment] = useState(true);
  const [updateExistingSegment, setUpdateExistingSegment] = useState(false);
  const { data: segmentData } = useGetSegments();
  const divWhereToScroll = useRef<HTMLDivElement>(null);
  const mapFiedsTableError = useRef<HTMLParagraphElement>(null);
  const { t } = useTranslation();

  const steps = [
    t('ImportMembersPage.uploadCsvStep'),
    t('ImportMembersPage.mapFieldsStep'),
    t('ImportMembersPage.reviewStep'),
  ];

  const availableSegments = segmentData
    ?.filter((segment) => segment.segmentType === 'manual')
    .map((segment) => {
      return {
        label: segment.name,
        value: segment.id,
      };
    });

  useEffect(() => {
    let timerBeforeNavigation: NodeJS.Timeout;
    if (toastInfo.toastType === 'success') {
      timerBeforeNavigation = setTimeout(() => navigate(AppRoutes.members), 2000);
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, toastInfo]);

  const schema = useMemo(() => {
    return [
      yup.object({
        importFile: yup.array().min(1, t('CommonErrors.fileRequired')).required(t('CommonErrors.fileRequired')),
        importName: yup.string().trim(),
        importDescription: yup.string().trim(),
        emptyValuesHandle: yup.object().required(t('CommonErrors.generalErrorRequired')),
      }),
      yup.object({
        mappedAttributes: yup
          .array()
          .of(
            yup.object().shape({
              attribute: yup
                .object()
                .shape({
                  label: yup.string(),
                  value: yup.string(),
                })
                .nullable(),
              type: yup
                .object()
                .shape({
                  label: yup.string().required(),
                  value: yup.string().required(),
                })
                .required('Type is mandatory'),
            }),
          )
          .test('uniqueAttributes', t('ImportMembersPage.uniqueAttributePerColumnError'), (array) => {
            const arrayWithoutEmptyAttribute = array?.filter(
              (val) => val.attribute && Object.keys(val.attribute).length !== 0,
            );
            const uniqueData = Array.from(new Set(arrayWithoutEmptyAttribute?.map((row) => row.attribute?.label)));
            return arrayWithoutEmptyAttribute?.length === uniqueData.length;
          })
          .test('mandatoryColumns', t('ImportMembersPage.emailAndNameRequiredForMapping'), (array) => {
            const filledFields = array?.filter((val) => val.attribute && Object.keys(val.attribute).length !== 0);
            const minimumRequiredFields = filledFields?.filter(
              (val) => val.attribute?.label === 'email' || val.attribute?.label === 'name',
            );
            return minimumRequiredFields?.length === 2;
          })
          .test('typeMissing', t('ImportMembersPage.typeRequiredForAttribute'), (array) => {
            const filledFields = array?.filter((val) => val.attribute && Object.keys(val.attribute).length !== 0);
            const missingTypes = filledFields?.filter((item) => item.type && Object.keys(item.type).length !== 0);
            return filledFields?.length === missingTypes?.length;
          }),
      }),
      yup.object({
        newManualSegmentName: createNewSegment
          ? yup.string().trim().required(t('ImportMembersPage.createNewSegmentRequired'))
          : yup.string(),
        existingSegments: updateExistingSegment
          ? yup.object().required(t('ImportMembersPage.existingSegmentRequired'))
          : yup.object().nullable(),
      }),
    ];
  }, [updateExistingSegment, createNewSegment, t]);

  const currentValidationSchema = schema[activeStep];

  const methods = useForm<PreviewMembersDetails>({
    shouldUnregister: false,
    resolver: yupResolver(currentValidationSchema),
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    trigger,
    register,
    setValue,
    watch,
    resetField,
    setError,
    formState: { errors },
  } = methods;

  const watchImportFile = watch('importFile');

  const parseFile = () => {
    Papa.parse(watchImportFile[0], {
      header: true,
      complete: (results) => {
        if (results.data && results.data[0]) {
          setMapFieldsData([]);
          let identifier = 0;
          setValue(`mappedAttributes.${identifier}.type`, { label: 'String', value: '1' });
          for (const [key, value] of Object.entries(results.data[0] as any)) {
            identifier = identifier + 1;
            const numberOfValuesPerKey = results.data.reduce((acc: number, current: any) => {
              if (current[key]) {
                return acc + 1;
              } else {
                return acc;
              }
            }, 0);
            const singleData = {
              csvHeader: key,
              csvExample: [value, numberOfValuesPerKey],
              id: identifier,
              attribute: undefined,
              type: { label: 'String', value: '1' },
              included: true,
            };
            setValue(`mappedAttributes.${identifier}.type`, { label: 'String', value: '1' });
            setMapFieldsData((prevstate) => {
              return [...prevstate, singleData];
            });
          }
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          setFileErrors([t('ImportMembersPage.parsingError')]);
        }
      },
    });
  };

  const includeAttribute = (attributeId: number) => {
    setMapFieldsData((state) =>
      state.map((el, id) => {
        return id === attributeId
          ? { ...el, included: !el.included, attribute: undefined, type: { label: 'String', value: '1' } }
          : el;
      }),
    );
    setValue(`mappedAttributes.${attributeId}.attribute`, undefined);
    setValue(`mappedAttributes.${attributeId}.type`, { label: 'String', value: '1' });
  };

  useEffect(() => {
    resetField('mappedAttributes');
    setReviewImportError(null);
  }, [watchImportFile]);

  const { columns: columnsMapFields } = useMapFieldsTable(
    control,
    errors,
    mapFieldsData,
    setMapFieldsData,
    includeAttribute,
  );

  const handleUpdateExistingMembersToggle = (value: boolean) => {
    setUpdateExistingMembers(value);
  };

  const handleCreateNewManualSegmentToggle = (value: boolean) => {
    if (updateExistingSegment === false) setCreateNewSegment(value);
    else setCreateNewSegment(value);
    setUpdateExistingSegment(false);
  };

  const handleUpdateExistingSegmentToggle = (value: boolean) => {
    if (createNewSegment === false) setUpdateExistingSegment(value);
    else setUpdateExistingSegment(value);
    setCreateNewSegment(false);
  };

  const reviewImportRequest = async (): Promise<ReviewImport | undefined> => {
    const csvFile = watchImportFile;
    const formattedMapping = mapFieldsData
      .filter((item) => item.included === true)
      .map((row) => {
        return {
          mapFrom: row.csvHeader,
          mapTo: row.attribute ? row.attribute.label : row.csvHeader,
          ...(row.type && {
            type:
              !row.type.label.startsWith('String') && !row.type.label.startsWith('Number')
                ? 'datetime'
                : row.type.label.toLowerCase(),
          }),
          ...(!row.type?.label.startsWith('String') &&
            !row.type?.label.startsWith('Number') && { format: row.type?.label }),
        };
      });
    if (csvFile && csvFile.length > 0) {
      const base64Data = await readFileAsBase64(csvFile[0]);
      const data = {
        fileName: watchImportFile[0]?.name,
        base64Data: base64Data,
        fieldMapping: formattedMapping,
      };
      return data;
    }
    return undefined;
  };

  const onBack = () => {
    activeStep === 0 ? navigate(AppRoutes.members) : setActiveStep(activeStep - 1);
    divWhereToScroll.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) {
      if (activeStep === 0) {
        parseFile();
      } else if (activeStep === 1) {
        const sendData = await reviewImportRequest();
        if (sendData) {
          setReviewImportRequestData(sendData);
          reviewImport.mutate(sendData, {
            onSuccess: (res: any) => {
              setReviewImportResponse(res.data);
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
              setReviewImportError(null);
              setValue('newManualSegmentName', watchImportFile[0].name);
            },
            onError: (err: any) => {
              setReviewImportError(err.response.data.message.error);
            },
          });
        } else {
          console.error('Issue with import file');
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      divWhereToScroll.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      if (activeStep === 1) {
        mapFiedsTableError.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const onSubmit = (formData: PreviewMembersDetails) => {
    const sendData: ImportMembersDetails = {
      fileName: formData.importFile[0]?.name,
      updateExistingMembers: updateExistingMembers,
      fieldMapping: reviewImportRequestData?.fieldMapping ? reviewImportRequestData?.fieldMapping : [],
      base64Data: reviewImportRequestData?.base64Data ? reviewImportRequestData?.base64Data : '',
      ...((createNewSegment || updateExistingSegment) && {
        addToSegment: {
          createNewSegment: createNewSegment,
          ...(updateExistingSegment && { segmentId: formData.existingSegments.value }),
          ...(createNewSegment && {
            segmentName: formData.newManualSegmentName,
          }),
        },
      }),
    };

    importMembersMutation.mutate(sendData as ImportMembersDetails, {
      onSuccess: () => {
        setToastInfo({ message: t('ImportMembersPage.toastSuccess'), toastType: 'success' });
        setOpen(true);
      },
      onError: (err: any) => {
        const error = err?.response?.data?.message?.error;
        if (error == ErrorMessage.NO_NEW_MEMBERS) {
          setGeneralError(t(`ImportMembersPage.${error}`));
        } else {
          console.log(err.response.data.message.error);
          setToastInfo({ message: t('ImportMembersPage.toastError'), toastType: 'error' });
          setOpen(true);
        }
      },
    });
  };

  return (
    <div className="relative mb-80">
      {importMembersMutation.isLoading && (
        <div className="absolute w-full h-full z-50">
          <Loader />
        </div>
      )}
      <div className="flex flex-col items-center mt-4">
        <div className="flex min-w-[40rem] items-start -ml-6" ref={divWhereToScroll}>
          <Stepper steps={steps} activeStep={activeStep} />
        </div>
        <div className="w-[40rem]">
          <FormProvider {...methods}>
            <form className="space-y-6 min-w-[40rem] w-1/2">
              {/* Upload CSV step */}
              {activeStep === 0 && (
                <div>
                  <div className="relative mb-7">
                    <h1 className="text-white font-medium text-xl">{t('ImportMembersPage.uploadCsvStep')}</h1>
                  </div>
                  <section className="mb-9">
                    <h2 className="text-xs font-semibold text-yellow-500">{t('ImportMembersPage.importFileLabel')}</h2>
                    <div className="flex flex-row mb-4">
                      <span className="text-sm text-gray-100 font-medium after:content-['\00a0']">
                        {t('ImportMembersPage.requiredFieldsLabel')}
                      </span>
                    </div>
                    <p
                      className={`${
                        errors.importFile?.message ? 'text-errorRed-500' : 'text-gray-100'
                      } text-xs font-medium mb-2`}
                    >
                      {t('ImportMembersPage.uploadFileLabel')}
                    </p>
                    <FileUploadInput
                      name={'importFile'}
                      control={control}
                      value={watchImportFile}
                      setValue={setValue}
                      importError={errors.importFile?.message}
                      parseError={fileErrors}
                      setParseError={setFileErrors}
                      placeholder={t('ImportMembersPage.dragAndDropPlaceholder')}
                      fieldClassName="h-[10rem] overflow-y-auto"
                      exampleFileCSVLink={
                        <div className="flex flex-row items-center">
                          <span className="text-sm text-gray-100 font-medium after:content-['\00a0']">
                            {t('ImportMembersPage.downloadLink')}
                          </span>
                          <CsvLink
                            data={csvExampleAudienceData}
                            headers={csvExampleAudienceHeader}
                            className="text-primary-400 text-sm font-medium hover:underline"
                            linkLabel={t('ImportMembersPage.hereLabel')}
                          />
                        </div>
                      }
                    />
                  </section>
                  <hr className="w-full border-custom-darkBlue" />
                  <section className="mt-7 relative">
                    {(watchImportFile?.length === 0 || !watchImportFile) && (
                      <div className="absolute z-50 w-full h-full top-0 left-0 bg-dropShadow"></div>
                    )}
                    <h2 className="text-xs font-semibold text-yellow-500 mb-3">
                      {t('ImportMembersPage.detailsLabel')}
                    </h2>
                    <div className="space-y-3">
                      <Input
                        placeholder={t('ImportMembersPage.importNamePlaceholder')}
                        {...register('importName')}
                        error={errors.importName?.message as string}
                        label={t('ImportMembersPage.importNameLabel')}
                        value={watchImportFile && watchImportFile.length > 0 ? watchImportFile[0]?.name : ''}
                        disabled={watchImportFile && watchImportFile.length > 0}
                      />
                      <Textarea
                        {...register('importDescription')}
                        placeholder={`${t('ImportMembersPage.descriptionPlaceholder')}...`}
                        label={t('ImportMembersPage.descriptionLabel')}
                        optional={true}
                      />
                      <div className="flex flex-row items-start gap-5">
                        <div className="basis-1/2 relative">
                          <SelectField
                            name="emptyValuesHandle"
                            label={t('ImportMembersPage.whatShouldWeDoWithEmptyValuesLabel')}
                            control={control}
                            options={optionsForHandlingEmptyValues}
                            errorMsg={errors.emptyValuesHandle?.message}
                            className={'mb-2'}
                            isSearchable={false}
                          />
                          <Tooltip
                            side="right"
                            content={
                              <div className="text-gray-100 text-xs font-light">
                                {t('ImportMembersPage.dummyTooltip')}
                              </div>
                            }
                            open={isEmptyValuesDropdownHovered}
                            onOpenChange={(open: boolean) => {
                              if (open) {
                                setIsEmptyValuesDropdownHovered(true);
                              } else {
                                setIsEmptyValuesDropdownHovered(false);
                              }
                            }}
                            contentClassName="bg-custom-darkBlue p-8 rounded-lg z-50 w-44 text-center"
                            arrowClassName="fill-custom-darkBlue w-5 h-2"
                          >
                            <button type="button" className="absolute top-0 right-0">
                              <CircleHelpIcon />
                            </button>
                          </Tooltip>
                        </div>
                        <div className="basis-1/2">
                          <Input
                            {...register('peopleIdentificationProperty')}
                            error={t(errors.peopleIdentificationProperty?.message) as string}
                            label={t('ImportMembersPage.howToIdentifyPeopleLabel')}
                            value={'Email'}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                  <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                    <div className="flex items-center justify-between min-w-[40rem]">
                      <Button variant="cancel" onClick={() => navigate(AppRoutes.members)}>
                        {t('ImportMembersPage.linkCancel')}
                      </Button>
                      <div className="flex flex-row gap-2">
                        <Button
                          type="button"
                          className="group pl-4 !w-[8.125rem]"
                          data-cy="nextButton"
                          onClick={onNext}
                          iconRight={
                            <span className="group-hover:[&>*_path]:stroke-gray-900 group-active:[&>*_path]:stroke-white">
                              <ChevronNewIcon type="right" color="#1C222A" width="20" height="20" />
                            </span>
                          }
                        >
                          {t('ImportMembersPage.buttonNext')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Map Fields step */}
              {activeStep === 1 && (
                <div className="lg:-ml-44 3xl:-ml-56">
                  <div className="relative mb-7">
                    <h1 className="text-white font-medium text-xl">{t('ImportMembersPage.mapFieldsStep')}</h1>
                  </div>
                  <section className="mb-5">
                    <h2 className="text-xs font-semibold text-yellow-500">
                      {t('ImportMembersPage.requiredColumnsLabel')}
                    </h2>
                    <p className="text-sm text-gray-300 font-normal">
                      <Trans
                        i18nKey="ImportMembersPage.requiredMappingForIdAndEmailText"
                        components={{
                          primary: <span className="text-primary-400" />,
                        }}
                      />
                    </p>
                  </section>
                  <div className="mb-16">
                    <Table
                      columns={columnsMapFields}
                      data={mapFieldsData ?? []}
                      showPagination={false}
                      className={{
                        row: 'h-[4.75rem]',
                        cell: 'overflow-visible',
                      }}
                    />
                    {errors.mappedAttributes?.message && (
                      <p
                        ref={mapFiedsTableError}
                        className="text-errorRed-500 mt-2 text-xs font-medium flex flex-row items-center gap-2"
                      >
                        <CircleWarningIcon /> {errors.mappedAttributes?.message as string}
                      </p>
                    )}
                    {reviewImportError && (
                      <p className="text-errorRed-500 mt-2 text-xs font-medium flex flex-row items-center gap-2">
                        <CircleWarningIcon /> Server error: {reviewImportError as string}
                      </p>
                    )}
                  </div>
                  <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                    <div className="flex items-center justify-between min-w-[40rem]">
                      <Button variant="cancel" onClick={() => navigate(AppRoutes.members)}>
                        {t('ImportMembersPage.linkCancel')}
                      </Button>
                      <div className="flex flex-row gap-2">
                        <Button
                          type="button"
                          variant="previous"
                          className="pr-4 !w-[8.125rem]"
                          onClick={onBack}
                          iconLeft={<ChevronNewIcon type="left" color="#F5F7FC" width="20" height="20" />}
                        >
                          {t('ImportMembersPage.buttonPrevious')}
                        </Button>
                        <Button
                          type="button"
                          className="group pl-4 !w-[8.125rem]"
                          data-cy="nextButton"
                          onClick={onNext}
                          isLoading={reviewImport.isLoading}
                          iconRight={
                            <span className="group-hover:[&>*_path]:stroke-gray-900 group-active:[&>*_path]:stroke-white">
                              <ChevronNewIcon type="right" color="#1C222A" width="20" height="20" />
                            </span>
                          }
                        >
                          {t('ImportMembersPage.buttonNext')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Review step */}
              {activeStep === 2 && (
                <div>
                  <div className="mb-5">
                    <Banner variant="success">
                      <h3 className="text-sm text-primary-400 font-medium">{t('ImportMembersPage.successLabel')}!</h3>
                      <p className="text-[11.5px] text-white font-light">
                        <Trans
                          i18nKey="ImportMembersPage.weFoundXMembers"
                          values={{
                            numberOfMember: reviewImportResponse?.importDetails.newPersons,
                            memberOrMembers:
                              (reviewImportResponse?.importDetails.newPersons
                                ? reviewImportResponse?.importDetails.newPersons
                                : 0) > 1 ||
                              (reviewImportResponse?.importDetails.newPersons
                                ? reviewImportResponse?.importDetails.newPersons
                                : 0) === 0
                                ? t('ImportMembersPage.membersLabel')
                                : t('ImportMembersPage.memberLabel'),
                          }}
                        />
                        <Trans
                          i18nKey="ImportMembersPage.canUpdateXMembers"
                          values={{
                            numberOfMember: reviewImportResponse?.importDetails.existingPersons,
                            memberOrMembers:
                              (reviewImportResponse?.importDetails.existingPersons
                                ? reviewImportResponse?.importDetails.existingPersons
                                : 0) > 1 ||
                              (reviewImportResponse?.importDetails.existingPersons
                                ? reviewImportResponse?.importDetails.existingPersons
                                : 0) === 0
                                ? t('ImportMembersPage.membersLabel')
                                : t('ImportMembersPage.memberLabel'),
                          }}
                        />
                      </p>
                    </Banner>
                  </div>
                  <div className="mb-8">
                    <h1 className="text-white font-medium text-xl mb-2">{t('ImportMembersPage.importDetailsLabel')}</h1>
                    <div className="flex flex-row gap-1 text-sm text-gray-300 font-normal">
                      <span>
                        {reviewImportResponse?.importDetails.rows} {t('ImportMembersPage.rowsLabel')}
                      </span>
                      <span>-</span>
                      <span>
                        {reviewImportResponse?.importDetails.columns} {t('ImportMembersPage.columnsLabel')}
                      </span>
                    </div>
                  </div>
                  <hr className="w-full border-custom-darkBlue" />
                  <div className="flex flex-col gap-y-2 mt-8 mb-9">
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-row justify-center gap-2">
                        <CheckIcon />
                        <span className="text-base font-normal text-gray-50">
                          <Trans
                            i18nKey="ImportMembersPage.newMemberLabel"
                            values={{
                              memberOrMembers:
                                (reviewImportResponse?.importDetails.newPersons
                                  ? reviewImportResponse?.importDetails.newPersons
                                  : 0) > 1 ||
                                (reviewImportResponse?.importDetails.newPersons
                                  ? reviewImportResponse?.importDetails.newPersons
                                  : 0) === 0
                                  ? t('ImportMembersPage.membersLabel')
                                  : t('ImportMembersPage.memberLabel'),
                            }}
                          />
                        </span>
                      </div>
                      <div>
                        <span className="text-base font-normal text-gray-400">
                          {reviewImportResponse?.importDetails.newPersons}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-row justify-center gap-2">
                        <CheckIcon />
                        <span className="text-base font-normal text-gray-50">
                          {t('ImportMembersPage.idChangesLabel')}
                        </span>
                      </div>
                      <div>
                        <span className="text-base font-normal text-gray-400">
                          {reviewImportResponse?.importDetails.idChanges}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-row justify-center gap-2">
                        <TriangleWarningIcon />
                        <span className="text-base font-normal text-gray-50">
                          <Trans
                            i18nKey="ImportMembersPage.existingMembersLabel"
                            values={{
                              memberOrMembers:
                                (reviewImportResponse?.importDetails.existingPersons
                                  ? reviewImportResponse?.importDetails.existingPersons
                                  : 0) > 1 ||
                                (reviewImportResponse?.importDetails.existingPersons
                                  ? reviewImportResponse?.importDetails.existingPersons
                                  : 0) === 0
                                  ? t('ImportMembersPage.membersLabel')
                                  : t('ImportMembersPage.memberLabel'),
                            }}
                          />
                        </span>
                      </div>
                      <div>
                        <span className="text-base font-normal text-orange-400">
                          {reviewImportResponse?.importDetails.existingPersons}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-row ml-7 -mt-1">
                      <ArrowSubDownRightIcon />
                      <div className="flex flex-row gap-2 justify-center mt-1.5">
                        <span className="text-sm font-medium text-gray-100">
                          {t('ImportMembersPage.doYouWantToUpdateExistingMembersLabel')}
                        </span>
                        <Tooltip
                          side="right"
                          content={
                            <div className="text-gray-100 text-xs font-light">
                              {t('ImportMembersPage.dummyTooltip')}
                            </div>
                          }
                          open={isUpdateExistingMembersHovered}
                          onOpenChange={(open: boolean) => {
                            if (open) {
                              setIsUpdateExistingMembersHovered(true);
                            } else {
                              setIsUpdateExistingMembersHovered(false);
                            }
                          }}
                          contentClassName="bg-custom-darkBlue p-8 rounded-lg z-50 w-44 text-center"
                          arrowClassName="fill-custom-darkBlue w-5 h-2"
                        >
                          <button type="button" className="">
                            <CircleHelpIcon />
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="ml-13">
                      <ToggleSwitch value={updateExistingMembers} onChange={handleUpdateExistingMembersToggle} />
                    </div>
                  </div>
                  <hr className="w-full border-custom-darkBlue" />
                  <section className="mt-8">
                    <div className="flex flex-row gap-1">
                      <h2 className="text-xs font-semibold text-yellow-500 mb-3 uppercase">
                        <Trans
                          i18nKey="ImportMembersPage.addXMembersPeopleToASegment"
                          values={{
                            numberOfMember:
                              (reviewImportResponse?.importDetails.existingPersons
                                ? reviewImportResponse?.importDetails.existingPersons
                                : 0) +
                              (reviewImportResponse?.importDetails.newPersons
                                ? reviewImportResponse?.importDetails.newPersons
                                : 0),
                            memberOrMembers:
                              (reviewImportResponse?.importDetails.existingPersons
                                ? reviewImportResponse?.importDetails.existingPersons
                                : 0) +
                                (reviewImportResponse?.importDetails.newPersons
                                  ? reviewImportResponse?.importDetails.newPersons
                                  : 0) >
                              1
                                ? t('ImportMembersPage.membersLabel')
                                : t('ImportMembersPage.memberLabel'),
                          }}
                        />
                      </h2>
                      <span className="text-gray-400 text-xs font-medium">({t('InputField.optionalLabel')})</span>
                    </div>
                    <div className="space-y-5">
                      <div className="flex flex-row gap-5 items-center">
                        <div className={`basis-[90%] ${createNewSegment ? '' : 'pointer-events-none opacity-50'}`}>
                          <Input
                            placeholder={t('ImportMembersPage.createNewManualSegmentPlaceholder')}
                            {...register('newManualSegmentName')}
                            error={errors.newManualSegmentName?.message as string}
                            label={t('ImportMembersPage.createNewManualSegmentLabel')}
                            // value={
                            //   watchImportFile && watchImportFile.length > 0 ? watchImportFile[0]?.name : ''
                            // }
                            // disabled={watchImportFile && watchImportFile.length > 0}
                          />
                        </div>
                        <div className="mt-7">
                          <ToggleSwitch value={createNewSegment} onChange={handleCreateNewManualSegmentToggle} />
                        </div>
                      </div>
                      <div className="flex flex-row gap-5 items-center">
                        <div className={`basis-[90%] ${updateExistingSegment ? '' : 'pointer-events-none opacity-50'}`}>
                          <SelectField
                            name="existingSegments"
                            label={t('ImportMembersPage.updateAnExistingSegmentLabel')}
                            control={control}
                            options={availableSegments}
                            errorMsg={errors.existingSegments?.message}
                            className={'mb-2'}
                            isSearchable={false}
                          />
                        </div>
                        <div className="mt-3">
                          <ToggleSwitch value={updateExistingSegment} onChange={handleUpdateExistingSegmentToggle} />
                        </div>
                      </div>
                    </div>
                    {generalError && (
                      <p className="text-errorRed-500 mt-2 text-xs font-medium flex flex-row items-center gap-2">
                        <CircleWarningIcon /> {generalError}
                      </p>
                    )}
                  </section>
                  <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                    <div className="flex items-center justify-between min-w-[40rem]">
                      <Button variant="cancel" onClick={() => navigate(AppRoutes.members)}>
                        {t('ImportMembersPage.linkCancel')}
                      </Button>
                      <div className="flex flex-row gap-2">
                        <Button
                          type="button"
                          variant="previous"
                          className="pr-4 !w-[8.125rem]"
                          onClick={onBack}
                          iconLeft={<ChevronNewIcon type="left" color="#F5F7FC" width="20" height="20" />}
                        >
                          {t('ImportMembersPage.buttonPrevious')}
                        </Button>
                        <Button
                          className="px-4 !w-52"
                          data-cy="importMembersButton"
                          onClick={handleSubmit(onSubmit)}
                          isLoading={importMembersMutation.isLoading || importMembersMutation.isSuccess}
                        >
                          {t('ImportMembersPage.buttonImportMembers')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </FormProvider>
        </div>
        <ProvideToasts>
          <Toast
            isOpen={open}
            setOpen={setOpen}
            message={toastInfo.message}
            toastType={toastInfo.toastType as ToastType}
          />
        </ProvideToasts>
      </div>
    </div>
  );
};

export default ImportMembers;
