import {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import CircleWarningIcon from '../Icons/CircleWarningIcon/CircleWarningIcon';

interface RadioButtonProps {
  label?: ReactElement | string;
  name: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
  error?: string;
  disabled?: boolean;
  checked?: boolean;
  className?: string;
  value: string;
  borderWhite?: boolean;
}

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ label, value, name, onChange, onBlur, error, disabled = false, checked, className, borderWhite = false }, ref) => {
    const internalRef = useRef<HTMLInputElement | null>(null);
    useImperativeHandle(ref, () => internalRef.current as HTMLInputElement);
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
      if (internalRef.current) {
        setIsMounted(true);
      }
    }, [internalRef]);
    const isChecked = checked ?? internalRef.current?.checked;
    return (
      <div>
        <label
          className={`group flex flex-row items-center gap-2 text-gray-500 hover:text-gray-400 text-sm font-normal cursor-pointer tracking-[0.28px] ${
            error && '!text-errorRed-500 hover:!text-errorRed-500'
          } ${disabled && 'opacity-40 pointer-events-none'} ${
            isChecked && !error && '!text-gray-50 hover:!text-gray-50'
          } ${isChecked && error && 'hover:!text-errorRed-500'} ${className}`}
        >
          <input
            type="radio"
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            ref={internalRef}
            disabled={disabled}
            className="hidden"
            value={value}
          />
          <div
            className={`flex justify-center items-center border-2 cursor-pointer bg-transparent ${
              borderWhite ? 'border-white' : 'border-gray-500 group-hover:border-gray-400'
            }  rounded-full min-w-[20px] h-5 ${disabled && 'opacity-40'} ${
              isChecked && '!border-none !bg-primary-400'
            }`}
          >
            {isChecked && <div className="bg-primary-600 rounded-full w-2 h-2"></div>}
          </div>
          {label && label}
        </label>
        {error && (
          <p className="text-errorRed-500 mt-2 text-xs font-medium flex flex-row items-center gap-2">
            <CircleWarningIcon /> {error}
          </p>
        )}
      </div>
    );
  },
);
