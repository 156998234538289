export const csvExampleAudienceHeader = [
  { label: 'id', key: 'id' },
  { label: 'name', key: 'name' },
  { label: 'email', key: 'email' },
  { label: 'role', key: 'role' },
  { label: 'age', key: 'age' },
];

const csvDummyData = [
  {
    id: '1',
    name: 'John Smith',
    email: 'john.smith@mail.com',
    role: 'Manager',
    age: '33',
  },
  {
    id: '2',
    name: 'Susan Smith',
    email: 'susan.smith@mail.com',
    role: 'Developer',
    age: '28',
  },
];

export const csvExampleAudienceData = csvDummyData.map((item) => ({
  id: item.id,
  name: item.name,
  email: item.email,
  role: item.role,
  age: item.age,
}));

export const csvExampleSegmentData = `email,
john.smith@mail.com,
susan.smith@mail.com,
`;
