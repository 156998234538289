const TemplateIcon = () => {
  return (
    <svg width="48" height="47" viewBox="0 0 48 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 16H13" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M4.5 28.5H25.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M4.5 32.5H25.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M4.5 36.5H25.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M4.5 40.5H25.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M4 19.5H9.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M18.5469 8.56908C18.802 8.63032 19.0469 8.73158 19.2705 8.86865C19.5228 9.02321 19.7393 9.23978 20.1714 9.67188L27.8283 17.3287C28.2606 17.7611 28.4756 17.9761 28.6302 18.2284C28.7673 18.4521 28.8699 18.6966 28.9312 18.9517C28.9996 19.2364 28.9996 19.5388 28.9995 20.1379V38.5H42.5046C43.902 38.5 44.6022 38.5 45.1365 38.2278C45.6069 37.9881 45.9864 37.6053 46.2261 37.1348C46.4986 36.6001 46.4995 35.8998 46.4995 34.4996V12.6379C46.4996 12.0388 46.4996 11.7364 46.4312 11.4517C46.3699 11.1966 46.2673 10.9521 46.1302 10.7284C45.9756 10.4761 45.7607 10.2611 45.3283 9.82873L37.6714 2.17188C37.2393 1.73979 37.0227 1.5232 36.7705 1.36865C36.5469 1.23158 36.302 1.13032 36.0469 1.06908C35.7592 1 35.4532 1 34.8417 1H33.9995M18.9995 8.50024V5.00024C18.9995 3.60011 18.9995 2.89953 19.272 2.36475C19.5117 1.89434 19.8939 1.51217 20.3643 1.27248C20.8991 1 21.5984 1 22.9986 1H33.9995M33.9995 1V9.5C33.9995 10.9001 33.9995 11.5997 34.272 12.1345C34.5117 12.6049 34.8939 12.9881 35.3643 13.2278C35.8985 13.5 36.5971 13.5 37.9945 13.5H46.4986"
        stroke="#F5F7FC"
        strokeOpacity="0.18"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2727 8H17.1302C17.7528 8 18.0643 8 18.3573 8.07C18.617 8.13205 18.8664 8.23467 19.0941 8.37357C19.3509 8.53018 19.5714 8.74964 20.0114 9.1875L27.8074 16.9464C28.2477 17.3846 28.4666 17.6024 28.624 17.8581C28.7635 18.0848 28.868 18.3325 28.9304 18.591C29 18.8796 29 19.186 29 19.7931V38.4V41.9463C29 43.3651 28.999 44.0747 28.7216 44.6166C28.4776 45.0933 28.0896 45.4813 27.6106 45.7241C27.0667 46 26.3528 46 24.93 46L5.06763 46C3.64478 46 2.93354 46 2.38956 45.7241C1.9106 45.4813 1.52148 45.0927 1.27744 44.616C1 44.0741 1 43.3657 1 41.9469V12.0536C1 10.6348 1 9.92485 1.27744 9.38294C1.52148 8.90627 1.9106 8.519 2.38956 8.27612C2.93406 8 3.64614 8 5.07173 8H16.2727ZM16.2727 8V16.6133C16.2727 18.0321 16.2727 18.741 16.5502 19.2829C16.7942 19.7596 17.1833 20.1479 17.6623 20.3908C18.2063 20.6667 18.9175 20.6667 20.3403 20.6667H28.999"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TemplateIcon;
