import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGetAudienceByWorkspace } from '@/api/workspaces/useGetAudienceByWorkspace';
import { Button } from '@/component-library/primitives/Button/Button';
import AddPlusCircleIcon from '@/component-library/primitives/Icons/AddPlusCircleIcon/AddPlusCircleIcon';
import ChevronIcon from '@/component-library/primitives/Icons/ChevronIcon/ChevronIcon';
import UploadIcon from '@/component-library/primitives/Icons/UploadIcon/UploadIcon';
import Loader from '@/component-library/primitives/Loader/Loader';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import NoDataBoard from '@/component-library/widgets/NoDataBoard/NoDataBoard';
import SearchField from '@/component-library/widgets/SearchField/SearchField';
import Table from '@/component-library/widgets/Table/Table';
import { HttpMethodsEnum, PermissionsEnum } from '@/modules/shared/enums/enums';
import useOutsideClick from '@/modules/shared/hooks/useOutsideClick';
import usePermissionChecker from '@/modules/shared/hooks/usePermissionChecker';
import { AppRoutes } from '@/utils/routes/router';

import { useAudienceTable } from './useAudienceTable';

const Audience = () => {
  const { data, isLoading } = useGetAudienceByWorkspace();
  const { columns, deleteConfirmationModal } = useAudienceTable();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isActionAllowed = usePermissionChecker(PermissionsEnum.AUDIENCE, HttpMethodsEnum.POST);

  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const [filterValue, setFilterValue] = useState('');

  const filteredData = useMemo(() => {
    return data?.filter((row) =>
      Object.values(row).some(
        (value) => value !== null && value.toString().toLowerCase().includes(filterValue.toLowerCase()),
      ),
    );
  }, [data, filterValue]);

  const handleNavigate = (route: string, isActionAllowed: boolean) => {
    if (!isActionAllowed) {
      setToastInfo({ message: t('CommonErrors.actionNotAllowed'), toastType: 'error' });
      setOpen(true);
      return;
    }
    navigate(route);
  };

  const menuOptions = [
    { icon: <AddPlusCircleIcon />, name: t('AudiencePage.buttonOptionAddMember'), route: AppRoutes.createMember },
    { icon: <UploadIcon />, name: t('AudiencePage.buttonOptionImportMembers'), route: AppRoutes.importMembers },
  ];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useOutsideClick(dropdownRef, () => setIsDropdownOpen(false));

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row items-center mb-4">
        <div className="flex-grow justify-start">
          <SearchField initialValue={filterValue} setSearchValue={setFilterValue} />
        </div>
        {filteredData && filteredData.length > 0 && (
          <div className="relative justify-end">
            <Button onClick={toggleDropdown} ref={dropdownRef} className="group !w-[10.5rem]">
              <span className="flex flex-row gap-3 justify-center items-center">
                <span>{t('AudiencePage.buttonNewMember')}</span>
                <span className="group-hover:[&>*_path]:fill-gray-900 group-active:[&>*_path]:fill-white">
                  <ChevronIcon color="#1C222A" type={isDropdownOpen ? 'up' : 'down'} />
                </span>
              </span>
            </Button>
            {isDropdownOpen && (
              <div className="absolute right-0 top-12 w-fit z-10 mt-2 p-2 pb-2 px-2 bg-custom-darkBlue rounded-lg border-[0.063rem] border-gray-800 whitespace-nowrap">
                {menuOptions.map((option, index) => (
                  <div
                    key={index}
                    className="group flex flex-row items-center gap-2 px-2 py-3 cursor-pointer rounded hover:bg-activeSideMenuOption text-custom-aliceBlue hover:text-primary-400"
                    onClick={() => handleNavigate(option.route, isActionAllowed)}
                  >
                    <span className="group-hover:[&>*_path]:stroke-primary-400">{option.icon}</span>
                    <span className="font-light text-sm tracking-[0.0175rem]">{option.name}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      {deleteConfirmationModal}
      {isLoading ? (
        <Loader />
      ) : filteredData && filteredData.length > 0 ? (
        <Table
          columns={columns}
          data={filteredData ?? []}
          className={{
            row: 'h-[3.300rem]',
            cell: 'overflow-visible',
          }}
        />
      ) : (
        <NoDataBoard
          description={t('NoDataBoard.addYourFirstMemberLabel')}
          actionButton={
            <div className="relative justify-end mt-4">
              <Button
                data-cy="createNewMemberButton"
                onClick={toggleDropdown}
                ref={dropdownRef}
                className="group !w-[10.5rem]"
              >
                <span className="flex flex-row gap-3 justify-center items-center">
                  <span>{t('AudiencePage.buttonNewMember')}</span>
                  <span className="group-hover:[&>*_path]:fill-gray-900 group-active:[&>*_path]:fill-white">
                    <ChevronIcon color="#1C222A" type={isDropdownOpen ? 'up' : 'down'} />
                  </span>
                </span>
              </Button>
              {isDropdownOpen && (
                <div className="absolute left-0 top-12 w-fit z-10 mt-2 p-2 pb-2 px-2 bg-custom-darkBlue rounded-lg border-[0.063rem] border-gray-800 whitespace-nowrap">
                  {menuOptions.map((option, index) => (
                    <div
                      key={index}
                      className="group flex flex-row items-center gap-2 px-2 py-3 cursor-pointer rounded hover:bg-activeSideMenuOption text-custom-aliceBlue hover:text-primary-400"
                      onClick={() => handleNavigate(option.route, isActionAllowed)}
                    >
                      <span className="group-hover:[&>*_path]:stroke-primary-400">{option.icon}</span>
                      <span className="font-light text-sm tracking-[0.0175rem]">{option.name}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          }
        />
      )}
      <ProvideToasts>
        <Toast
          isOpen={open}
          setOpen={setOpen}
          message={toastInfo.message}
          toastType={toastInfo.toastType as ToastType}
        />
      </ProvideToasts>
    </div>
  );
};

export default Audience;
