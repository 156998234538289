import React, { useEffect } from 'react';

/**
 * Hook that sets up a click event listener on the document and registers a handler
 * that will check if the click target is the element passed via ref to this hook.
 * If yes, it will call the callback function passed as a second param
 * @param ref - React Ref object
 * @param callback - Callback function that will be called when target is different than element passed via ref
 */
const useOutsideClick = (ref: React.RefObject<HTMLElement>, callback: any) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback?.();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, callback]);
};

export default useOutsideClick;
