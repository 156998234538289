import { useMutation } from 'react-query';
import axios from 'axios';

import { CreatePushNotificationRequest } from '@/models/PushNotificationDetails';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function createPushNotification(data: CreatePushNotificationRequest, token: string | undefined) {
  const response = await axios.post<CreatePushNotificationRequest>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/notifications/push/schedule`,
    data,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useCreatePushNotification() {
  const { user } = useAuth();
  return useMutation<CreatePushNotificationRequest, Error, CreatePushNotificationRequest>((data) =>
    createPushNotification(data, user?.signInUserSession.idToken.jwtToken),
  );
}
