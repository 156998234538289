import { useMutation } from 'react-query';
import axios from 'axios';

import { useAppSelector } from '@/app/hooks';
import { ReviewImport } from '@/models/WorkspaceDetails';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function reviewImport(data: ReviewImport, token: string | undefined, workspaceId: string) {
  const response = await axios.post<ReviewImport>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/workspaces/${workspaceId}/audience/review-import`,
    data,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useReviewImport() {
  const { user } = useAuth();
  const workspace = useAppSelector(selectCurrentWorkspace);
  return useMutation<ReviewImport, Error, ReviewImport>((data) =>
    reviewImport(data, user?.signInUserSession.idToken.jwtToken, workspace?.id ?? ''),
  );
}
