import { SVGProps } from 'react';

interface FolderIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const FolderIcon: React.FC<FolderIconProps> = ({ color = '#9bafd0', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M2.5 5.19645V14.1964C2.5 15.1299 2.5 15.5963 2.68166 15.9528C2.84144 16.2664 3.09623 16.5219 3.40983 16.6816C3.766 16.8631 4.23249 16.8631 5.16409 16.8631H14.8359C15.7675 16.8631 16.2333 16.8631 16.5895 16.6816C16.9031 16.5219 17.1587 16.2665 17.3185 15.9529C17.5002 15.5964 17.5002 15.1297 17.5002 14.1963L17.5002 7.86293C17.5002 6.92951 17.5002 6.4628 17.3185 6.10628C17.1587 5.79268 16.9033 5.5379 16.5897 5.37811C16.2331 5.19645 15.7668 5.19645 14.8333 5.19645H10M2.5 5.19645H10M2.5 5.19645C2.5 4.27598 3.24619 3.52979 4.16667 3.52979H7.22876C7.63642 3.52979 7.84072 3.52979 8.03253 3.57584C8.20259 3.61666 8.36488 3.68417 8.514 3.77555C8.68214 3.87859 8.82651 4.02296 9.11458 4.31104L10 5.19645"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FolderIcon;
