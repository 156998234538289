import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import AuthLayout from '@/component-library/layouts/AuthLayout/AuthLayout';
import { Button } from '@/component-library/primitives/Button/Button';
import { Input } from '@/component-library/widgets/Input/Input';
import PasswordHints from '@/component-library/widgets/PasswordHints/PasswordHints';
import { ResetPasswordData } from '@/models/PasswordData';
import { AppRoutes } from '@/utils/routes/router';

import { useAuth } from '../../hooks/useAuth';

const ResetPasswordPage = () => {
  const { forgotPasswordSubmit, username: usernameFromAuth } = useAuth();
  const [searchParams] = useSearchParams();
  const usernameFromQuery = searchParams.get('username');
  const username = usernameFromQuery ? decodeURIComponent(usernameFromQuery) : usernameFromAuth;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [containsUppercase, setContainsUppercase] = useState(false);
  const [containsLowercase, setContainsLowercase] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  const [containsSpecialCharacter, setContainsSpecialCharacter] = useState(false);
  const [containsMinCharacters, setContainsMinCharacters] = useState(false);
  const [attemptLimitExceeded, setAttemptLimitExceeded] = useState(false);

  const isPasswordValid =
    containsUppercase && containsLowercase && containsNumber && containsSpecialCharacter && containsMinCharacters;

  const schema = useMemo(() => {
    return yup
      .object()
      .shape({
        code: yup.string().required(t('CommonErrors.codeRequired')),
        password: yup
          .string()
          .required(t('CommonErrors.newPasswordRequired'))
          .matches(/[a-z]/, ' ')
          .matches(/[A-Z]/, ' ')
          .matches(/^.*(?=.*\d).*$/, ' ')
          .matches(/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/, ' ')
          .min(8, ' ')
          .max(64, t('CommonErrors.passwordMaxCharacters')),
        confirmPassword: yup
          .string()
          .required(t('CommonErrors.confirmPasswordRequired'))
          .oneOf([yup.ref('password')], t('CommonErrors.passwordMismatch')),
      })
      .required();
  }, [t]);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ResetPasswordData>({
    resolver: yupResolver(schema),
  });

  const [isPasswordInputFocused, setPasswordInputFocused] = useState(false);

  const handlePasswordInputFocus = () => {
    setPasswordInputFocused(true);
  };

  const handlePasswordInputBlur = () => {
    setPasswordInputFocused(false);
  };

  const validatePassword = (password: string) => {
    // has uppercase letter
    if (password.toLowerCase() != password) setContainsUppercase(true);
    else setContainsUppercase(false);

    // has lowercase letter
    if (password.toUpperCase() != password) setContainsLowercase(true);
    else setContainsLowercase(false);

    // has number
    if (/\d/.test(password)) setContainsNumber(true);
    else setContainsNumber(false);

    // has special character
    if (/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(password)) setContainsSpecialCharacter(true);
    else setContainsSpecialCharacter(false);

    // has min 8 characters
    if (password.length >= 8) setContainsMinCharacters(true);
    else setContainsMinCharacters(false);
  };

  const onSubmit = async (data: ResetPasswordData) => {
    const { code, password } = data;

    try {
      await schema.validate(data, { abortEarly: false });
      await forgotPasswordSubmit(username, code, password);
      navigate(AppRoutes.signIn);
    } catch (error: any) {
      if (error.code === 'CodeMismatchException') {
        setError('code', {
          type: 'manual',
          message: t('ResetPasswordPage.codeMismatch'),
        });
      } else if (error.code === 'LimitExceededException') {
        setAttemptLimitExceeded(true);
      }
    }
  };

  return (
    <AuthLayout
      title={t('ResetPasswordPage.title')}
      content={t('ResetPasswordPage.subtitle')}
      titleIconProps={{ path: '/images/lock.png', classes: 'self-start' }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full space-y-4">
        <Input
          placeholder={t('ResetPasswordPage.codePlaceholder')}
          {...register('code')}
          error={errors.code?.message}
          label={t('ResetPasswordPage.codePlaceholder')}
        />
        <Input
          placeholder={t('ResetPasswordPage.newPasswordPlaceholder')}
          {...register('password', {
            onChange: (e) => validatePassword(e.target.value),
          })}
          error={errors.password?.message}
          type="password"
          onBlur={handlePasswordInputBlur}
          onFocus={handlePasswordInputFocus}
          label={t('ResetPasswordPage.newPasswordPlaceholder')}
        />
        {isPasswordInputFocused && !isPasswordValid && (
          <PasswordHints
            constainsUppercase={containsUppercase}
            containsLowercase={containsLowercase}
            containsNumber={containsNumber}
            containsSpecialCharacter={containsSpecialCharacter}
            containsMinCharacters={containsMinCharacters}
          />
        )}
        <Input
          placeholder={t('ResetPasswordPage.confirmPasswordPlaceholder')}
          {...register('confirmPassword')}
          error={errors.confirmPassword?.message}
          type="password"
          label={t('ResetPasswordPage.confirmPasswordPlaceholder')}
        />
        {attemptLimitExceeded && (
          <p className="text-errorRed-500 mt-2 text-xs font-medium">{t('ResetPasswordPage.attemptLimitExceeded')}</p>
        )}
        <Button className="w-full">{t('ResetPasswordPage.buttonChangePassword')}</Button>
      </form>
    </AuthLayout>
  );
};

export default ResetPasswordPage;
