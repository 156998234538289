import { useMutation } from 'react-query';
import axios from 'axios';

import { useAppSelector } from '@/app/hooks';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function deleteCampaignById(workspaceId: string, campaignId: string, token: string | undefined) {
  const response = await axios.delete<string>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/workspaces/${workspaceId}/campaigns/${campaignId}`,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useDeleteCampaignById() {
  const { user } = useAuth();
  const workspace = useAppSelector(selectCurrentWorkspace);
  return useMutation<string, Error, string>((campaignId: string) =>
    deleteCampaignById(workspace?.id ?? '', campaignId, user?.signInUserSession.idToken.jwtToken),
  );
}
