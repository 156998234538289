import { SVGProps } from 'react';

interface RefreshIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const RefreshIcon: React.FC<RefreshIconProps> = ({ color = '#7898FB' }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.25 5.13281C11.25 5.13281 12.1064 4.71094 9 4.71094C7.88748 4.71094 6.79995 5.04084 5.87492 5.65892C4.94989 6.277 4.22892 7.15551 3.80318 8.18334C3.37744 9.21118 3.26604 10.3422 3.48309 11.4333C3.70013 12.5245 4.23586 13.5267 5.02253 14.3134C5.8092 15.1001 6.81148 15.6358 7.90262 15.8529C8.99376 16.0699 10.1248 15.9585 11.1526 15.5328C12.1804 15.107 13.0589 14.386 13.677 13.461C14.2951 12.536 14.625 11.4485 14.625 10.3359"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M9 2.03906L11.8125 4.85156L9 7.66406"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RefreshIcon;
