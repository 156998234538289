import { SVGProps } from 'react';

type Direction = 'left' | 'right' | 'down' | 'up';
interface ChevronNewIconProps extends SVGProps<SVGSVGElement> {
  type: Direction;
  color?: string;
  width?: string;
  height?: string;
  viewBox?: string;
}

const ChevronNewIcon: React.FC<ChevronNewIconProps> = ({
  color = '#FFFFFF',
  type,
  width = '32',
  height = '32',
  viewBox = '0 0 32 32',
  ...props
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} fill="none">
      {type === 'left' && (
        <path
          d="M18.6667 21.3333L13.3334 16L18.6667 10.6666"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      {type === 'right' && (
        <path
          d="M13.3334 21.3333L18.6667 16L13.3334 10.6666"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      {type === 'down' && (
        <path
          d="M16 10.1965L12 14.1965L8 10.1965"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      {type === 'up' && (
        <path
          d="M8 14.1965L12 10.1965L16 14.1965"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
};

export default ChevronNewIcon;
