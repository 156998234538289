import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useCreatePushNotificationConfig } from '@/api/messages/pushNotifications/useCreatePushNotificationConfig';
import { useGetSubmitedConfigs } from '@/api/messages/pushNotifications/useGetSubmittedConfigs';
import { useAppSelector } from '@/app/hooks';
import { Button } from '@/component-library/primitives/Button/Button';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import SimpleFileUpload from '@/component-library/widgets/FileUploadInput/SimpleFileUpload';
import FormSelector from '@/component-library/widgets/FormSelector/FormSelector';
import { Input } from '@/component-library/widgets/Input/Input';
import { CreatePushServiceDetails } from '@/models/PushNotificationDetails';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { NotificationServiceEnum } from '@/modules/shared/enums/enums';
import { getFileName, readFileListFileAsBase64 } from '@/utils/fileUtils';
import { AppRoutes } from '@/utils/routes/router';

const EnableIosPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [settings, setSettings] = useState(NotificationServiceEnum.APNS as string);
  const workspace = useAppSelector(selectCurrentWorkspace);
  const createPushConfigMutation = useCreatePushNotificationConfig();
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const [showFirebaseForm, setShowFirebaseForm] = useState(true);
  const [showApnsForm, setShowApnsForm] = useState(true);
  const { data: submitedPushConfigData } = useGetSubmitedConfigs();

  const availableConfigs = submitedPushConfigData || [];

  const hasFirebaseService = availableConfigs.some((item) => item.service === NotificationServiceEnum.FIREBASE);
  const hasApnsService = availableConfigs.some((item) => item.service === NotificationServiceEnum.APNS);

  useEffect(() => {
    if (hasFirebaseService) {
      setShowFirebaseForm(false);
    }
    if (hasApnsService) {
      setShowApnsForm(false);
    }
    let timerBeforeNavigation: NodeJS.Timeout;
    if (toastInfo.toastType === 'success') {
      timerBeforeNavigation = setTimeout(() => navigate(AppRoutes.pushNotifications), 2000);
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, toastInfo, hasFirebaseService, hasApnsService]);

  const onSubmit = async (data: CreatePushServiceDetails) => {
    const fileList = data.configFile;
    const configFileBase64 = await readFileListFileAsBase64(fileList);

    const config = {
      workspaceId: workspace?.workspaceId,
      service: settings,
      configType: 'ios',
      filename: getFileName(data.configFile),
      ...(settings === 'apns'
        ? {
            attributes: {
              keyId: data.keyId,
              teamId: data.teamId,
              bundleId: data.bundleId,
            },
            certificateFile: configFileBase64,
          }
        : {
            serverKeyFile: configFileBase64,
          }),
    };

    createPushConfigMutation.mutate(config as CreatePushServiceDetails, {
      onSuccess: () => {
        setToastInfo({ message: t('EnableIosPage.toastSuccess'), toastType: 'success' });
        setOpen(true);
      },
      onError: () => {
        setToastInfo({ message: t('EnableIosPage.toastError'), toastType: 'error' });
        setOpen(true);
      },
    });
  };

  const schema = useMemo(() => {
    const commonValidation = (fieldName: string) => {
      return yup
        .string()
        .trim()
        .required(t('CommonErrors.fieldRequired', { fieldName: fieldName }));
    };

    const baseSchema = yup
      .object()
      .shape({
        configFile: yup.mixed().test('fileValidation', t('CommonErrors.fileRequired'), (value) => {
          return value instanceof FileList && value.length > 0;
        }),
      })
      .required();

    if (settings === NotificationServiceEnum.APNS) {
      return yup.object().shape({
        keyId: commonValidation('Key ID'),
        teamId: commonValidation('Team ID'),
        bundleId: commonValidation('Bundle ID'),
        ...baseSchema.fields,
      });
    } else {
      return baseSchema;
    }
  }, [t, settings]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreatePushServiceDetails>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleSettingsSelect = (option: string) => {
    reset();
    setSettings(option);
  };

  const handleShowForm = (service: string) => {
    if (service === NotificationServiceEnum.APNS) {
      setShowApnsForm(true);
    } else {
      setShowFirebaseForm(true);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center mt-5 mb-40">
        <form className="space-y-6 w-[40rem]" onSubmit={handleSubmit(onSubmit)}>
          <div className="relative mb-8">
            <h1 className="text-white font-medium text-xl mb-2">{t('EnableIosPage.title')}</h1>
          </div>
          <section>
            <h2 className="text-xs font-semibold text-yellow-500 mb-4 uppercase">{t('EnableIosPage.serviceLabel')}</h2>
            <div className="flex space-x-6">
              <FormSelector
                text="Apple Push Notification Service"
                image="/images/apns.svg"
                isSelected={settings === NotificationServiceEnum.APNS}
                type={NotificationServiceEnum.APNS}
                onSelect={handleSettingsSelect}
              />
              <FormSelector
                text="Firebase Cloud Messaging"
                image="/images/fcm.svg"
                isSelected={settings === NotificationServiceEnum.FIREBASE}
                type={NotificationServiceEnum.FIREBASE}
                onSelect={handleSettingsSelect}
              />
            </div>
          </section>

          {settings === NotificationServiceEnum.APNS ? (
            <>
              {hasApnsService && !showApnsForm && (
                <section className="px-6 pt-6 pb-8 space-y-4 shadow rounded-lg bg-darkBlue text-custom-aliceBlue">
                  <p className="text-custom-aliceBlue">Looks like you have Apns already configured.</p>

                  {availableConfigs
                    .filter((item) => item.service === NotificationServiceEnum.APNS)
                    .map((config, index) => (
                      <p className="text-custom-aliceBlue" key={index}>
                        You are using configuration file - {config.filename}
                      </p>
                    ))}
                  <Button onClick={() => handleShowForm(NotificationServiceEnum.APNS)} size="small">
                    Upload new file
                  </Button>
                </section>
              )}
              {showApnsForm && (
                <>
                  <div>
                    <div className="mb-4">
                      <h2 className="text-xs font-semibold text-yellow-500 uppercase">
                        {t('EnableIosPage.importFileLabel')}
                      </h2>
                      <span className="text-sm text-gray-100 font-medium">
                        {t('EnableIosPage.ImportFileApplePushDescription')}
                      </span>
                    </div>
                    <SimpleFileUpload
                      fileUploadLabel={t('EnableIosPage.fileUploadApplePushLabel')}
                      accept=".p8"
                      error={errors.configFile?.message}
                      {...register('configFile')}
                    />
                  </div>
                  <section className="space-y-4">
                    <Input
                      label={t('EnableIosPage.keyIdLabel')}
                      placeholder={t('EnableIosPage.enterPlaceholder')}
                      {...register('keyId')}
                      error={t(errors.keyId?.message) as string}
                    />
                    <Input
                      label={t('EnableIosPage.teamIdLabel')}
                      placeholder={t('EnableIosPage.enterPlaceholder')}
                      {...register('teamId')}
                      error={t(errors.teamId?.message) as string}
                    />
                    <Input
                      label={t('EnableIosPage.bundleIdLabel')}
                      placeholder={t('EnableIosPage.enterPlaceholder')}
                      {...register('bundleId')}
                      error={t(errors.bundleId?.message) as string}
                    />
                  </section>
                </>
              )}
            </>
          ) : (
            <>
              {hasFirebaseService && !showFirebaseForm && (
                <section className="px-6 pt-6 pb-8 space-y-4 shadow rounded-lg bg-darkBlue text-custom-aliceBlue">
                  <p className="text-custom-aliceBlue">Looks like you have Firebase already configured.</p>

                  {availableConfigs
                    .filter((item) => item.service === NotificationServiceEnum.FIREBASE)
                    .map((config, index) => (
                      <p className="text-custom-aliceBlue" key={index}>
                        You are using configuration file - {config.filename}
                      </p>
                    ))}
                  <Button onClick={() => handleShowForm(NotificationServiceEnum.FIREBASE)} size="small">
                    Upload new file
                  </Button>
                </section>
              )}

              {showFirebaseForm && (
                <div>
                  <div className="mb-4">
                    <h2 className="text-xs font-semibold text-yellow-500 uppercase">
                      {t('EnableIosPage.importFileLabel')}
                    </h2>
                    <span className="text-sm text-gray-100 font-medium">
                      {t('EnableIosPage.ImportFileFirebaseDescription')}
                    </span>
                  </div>
                  <SimpleFileUpload
                    fileUploadLabel={t('EnableIosPage.fileUploadFirebaseLabel')}
                    accept=".json"
                    error={errors.configFile?.message}
                    {...register('configFile')}
                  />
                </div>
              )}
            </>
          )}
          {((showFirebaseForm && settings === NotificationServiceEnum.FIREBASE) ||
            (showApnsForm && settings === NotificationServiceEnum.APNS)) && (
            <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
              <div className="flex items-center justify-between 3xl:min-w-[40rem] lg:min-w-[40rem]">
                <Button variant="cancel" onClick={() => navigate(AppRoutes.pushNotifications)}>
                  {t('EnableIosPage.cancelBtn')}
                </Button>
                <Button
                  className="px-4 !w-52"
                  isLoading={createPushConfigMutation.isLoading || createPushConfigMutation.isSuccess}
                >
                  {t('EnableIosPage.submitBtn')}
                </Button>
              </div>
            </div>
          )}
        </form>
        <ProvideToasts>
          <Toast
            isOpen={open}
            setOpen={setOpen}
            message={toastInfo.message}
            toastType={toastInfo.toastType as ToastType}
          />
        </ProvideToasts>
      </div>
    </>
  );
};

export default EnableIosPage;
