const DragAndDropIcon = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.4584 15.6475H37.7114C39.528 15.6475 40.9997 17.1201 40.9997 18.9358V37.7114C40.9997 39.528 39.527 40.9997 37.7114 40.9997H18.9358C17.1192 40.9997 15.6475 39.527 15.6475 37.7114V32.9913"
        stroke="white"
        strokeWidth="1.12677"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.54157 26.352H4.28851C2.47177 26.352 1 24.8794 1 23.0638V4.28801C0.999023 2.47238 2.47177 0.999756 4.28753 0.999756H23.0638C24.8805 0.999756 26.3523 2.47238 26.3523 4.28801V9.00784"
        stroke="#F5F7FC"
        strokeOpacity="0.18"
        strokeWidth="1.12677"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="3.38 3.38"
      />
      <path
        d="M12.571 23.0363C12.571 23.0363 11.2731 25.6402 14.046 27.1191C16.8189 28.5981 19.7699 32.1482 25.0813 30.3737C30.3927 28.5991 31.6906 23.0363 30.3927 19.6042C29.0948 16.1721 25.2584 8.8348 22.0719 11.8533"
        stroke="white"
        strokeWidth="1.12677"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.3378 20.9081L13.9349 17.7349C13.3938 17.2301 12.5664 17.2035 11.9939 17.6735L11.3036 18.2397C10.3524 19.021 10.332 20.4791 11.2619 21.2859L15.1501 24.6619"
        stroke="white"
        strokeWidth="1.12677"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14.5215 15.6742C14.5215 15.6742 15.8985 14.3473 17.5263 15.6742C19.1541 17.0011 20.1553 17.9014 20.1553 17.9014"
        stroke="white"
        strokeWidth="1.12677"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M18.4651 13.4205C18.4651 13.4205 19.7044 12.0936 21.1694 13.4205C22.6344 14.7475 23.5355 15.6477 23.5355 15.6477"
        stroke="white"
        strokeWidth="1.12677"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DragAndDropIcon;
