import { useQuery } from 'react-query';
import axios from 'axios';

import { useAppSelector } from '@/app/hooks';
import { ActivityLog } from '@/models/ActivityLogsByWorkspace';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { AwsConfigApiGateway } from '@/utils/config/api';

import queryKeys from '../../modules/shared/query/queryKeys';

async function getActivityLogsByWorkspace(token: string | undefined, workspaceId: string) {
  const response = await axios.get<any>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/workspaces/${workspaceId}/activity-logs`,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data.data;
}

export function useGetActivityLogsByWorkspace() {
  const { user } = useAuth();
  const workspace = useAppSelector(selectCurrentWorkspace);
  return useQuery<ActivityLog[]>({
    queryKey: [queryKeys.activityLogs, workspace?.id],
    queryFn: () => getActivityLogsByWorkspace(user?.signInUserSession.idToken.jwtToken, workspace?.id ?? ''),
  });
}
