import { useQuery } from 'react-query';
import axios from 'axios';

import { Workspace, WorkspacesByTenant } from '@/models/WorkspacesByTenant';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

import queryKeys from '../../modules/shared/query/queryKeys';

async function getWorkspacesByTenant(token: string | undefined) {
  const response = await axios.get<WorkspacesByTenant>(`${AwsConfigApiGateway.pooledTenantApiUrl}/workspaces`, {
    headers: {
      'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data.data;
}

export function useGetWorkspacesByTenant() {
  const { user } = useAuth();
  return useQuery<Workspace[]>({
    queryKey: [queryKeys.workspaces],
    queryFn: () => getWorkspacesByTenant(user?.signInUserSession?.idToken.jwtToken),
    enabled: !!user?.signInUserSession?.idToken?.jwtToken,
    refetchOnWindowFocus: false,
  });
}
