import { SVGProps } from 'react';

interface CircleCheckIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
  width?: string;
  height?: string;
}

const CircleCheckIcon: React.FC<CircleCheckIconProps> = ({
  color = '#82E6B8',
  width = '18',
  height = '18',
  ...props
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 18" fill="none">
      <path
        d="M11.25 7.5L8.25 10.5L6.75 9M9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CircleCheckIcon;
