import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import AuthLayout from '@/component-library/layouts/AuthLayout/AuthLayout';
import { Button } from '@/component-library/primitives/Button/Button';
import CircleWarningIcon from '@/component-library/primitives/Icons/CircleWarningIcon/CircleWarningIcon';
import Link from '@/component-library/primitives/Link/Link';
import { Input } from '@/component-library/widgets/Input/Input';
import { AppRoutes } from '@/utils/routes/router';

import { useAuth } from '../../hooks/useAuth';

type FormData = {
  password: string;
};

const VerifyEmailPage = () => {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [signInError, setSignInError] = useState('');

  const email = location.state?.email || undefined;

  useEffect(() => {
    console.log('email', email);
    if (!email) {
      navigate(AppRoutes.signIn);
    }
  }, [email, navigate]);

  const schema = useMemo(() => {
    return yup.object().shape({
      password: yup.string().required(t('CommonErrors.passwordRequired')),
    });
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const watchAll = watch();

  const onSubmit = async (data: FormData) => {
    try {
      if (!email) {
        navigate(AppRoutes.signIn);
        return;
      }
      const result = await signIn(email, data.password);
      if (result.isSignInSuccess) {
        navigate(AppRoutes.dashboard);
      } else if (result.message === 'NEW_PASSWORD_REQUIRED') {
        navigate({ pathname: '/create-new-password' });
      } else {
        setSignInError(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthLayout
      title={t('VerifyEmailPage.title')}
      content={t('VerifyEmailPage.subtitle') + email}
      variant="one-column"
      titleIconProps={{ path: '/images/shield.png', classes: 'mb-3' }}
      contentClass="text-center"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full space-y-4">
        <Input
          {...register('password')}
          error={errors.password?.message}
          type="password"
          label={t('VerifyEmailPage.passwordLabel')}
          cy-data="temporaryPassword"
        />
        {signInError && (
          <p className="text-errorRed-500 mt-2 text-xs font-medium flex flex-row items-center gap-2">
            <CircleWarningIcon /> {signInError}
          </p>
        )}
        <Button className="w-full" data-cy="buttonNext">
          {t('VerifyEmailPage.buttonSubmit')}
        </Button>
      </form>
      <div className="flex">
        <p className="inline text-gray-400 text-sm">{t('VerifyEmailPage.resendEmailText')}</p>
        <Link
          className="inline text-primary-400 text-sm"
          to={AppRoutes.signIn}
          label={t('VerifyEmailPage.resendEmail')}
        />
      </div>
    </AuthLayout>
  );
};

export default VerifyEmailPage;
