const WifiSignalIcon = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.13271 8.93958C8.77221 8.93958 9.39359 9.10762 9.94111 9.42804L10.1629 9.55787C10.3311 9.65629 10.3607 9.88665 10.2228 10.0242L8.32873 11.913C8.21239 12.029 8.02376 12.029 7.90742 11.913L6.02551 10.0363C5.88823 9.89939 5.91684 9.67021 6.0836 9.57106L6.30295 9.44064C6.85549 9.1121 7.48477 8.93958 8.13271 8.93958Z"
        fill="#272933"
      />
      <path
        d="M8.13267 5.46985C9.7235 5.46985 11.2509 5.99759 12.4952 6.97491L12.6711 7.11303C12.8113 7.22315 12.8236 7.43064 12.6974 7.55649L11.5666 8.68412C11.462 8.78842 11.2965 8.8004 11.1779 8.71226L11.0401 8.60981C10.1997 7.98523 9.18633 7.6502 8.13267 7.6502C7.07251 7.6502 6.05323 7.9894 5.21005 8.62115L5.07207 8.72453C4.95346 8.81339 4.78737 8.80169 4.68247 8.69709L3.5521 7.56986C3.42618 7.44429 3.43813 7.23732 3.57769 7.127L3.75254 6.98876C4.99979 6.00273 6.53416 5.46985 8.13267 5.46985Z"
        fill="#272933"
      />
      <path
        d="M8.13272 2C10.6574 2 13.0717 2.89057 14.9828 4.52294L15.1459 4.66228C15.2777 4.77488 15.2855 4.97558 15.1627 5.09797L14.0356 6.22195C13.9264 6.33084 13.7519 6.33847 13.6336 6.23952L13.494 6.12283C11.9894 4.86472 10.1035 4.18035 8.13272 4.18035C6.15517 4.18035 4.26327 4.86943 2.75641 6.13541L2.6168 6.2527C2.4985 6.3521 2.32359 6.34466 2.2142 6.23557L1.08726 5.11176C0.964693 4.98954 0.972236 4.78918 1.10365 4.67646L1.26614 4.53708C3.17953 2.89589 5.60056 2 8.13272 2Z"
        fill="#272933"
      />
    </svg>
  );
};

export default WifiSignalIcon;
