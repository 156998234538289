import { useMutation } from 'react-query';
import axios from 'axios';

import { EditCompanyAccountDetails } from '@/models/CompanyAccountDetails';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function updateAccountDetails(accountDetailsData: EditCompanyAccountDetails, token: string | undefined) {
  const response = await axios.put<EditCompanyAccountDetails>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/tenants`,
    accountDetailsData,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useUpdateAccountDetails() {
  const { user } = useAuth();

  return useMutation<EditCompanyAccountDetails, Error, EditCompanyAccountDetails>((accountDetailsData) =>
    updateAccountDetails(accountDetailsData, user?.signInUserSession.idToken.jwtToken),
  );
}
