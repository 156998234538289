import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useCreatePushNotificationConfig } from '@/api/messages/pushNotifications/useCreatePushNotificationConfig';
import { useGetSubmitedConfigs } from '@/api/messages/pushNotifications/useGetSubmittedConfigs';
import { useAppSelector } from '@/app/hooks';
import { Button } from '@/component-library/primitives/Button/Button';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import SimpleFileUpload from '@/component-library/widgets/FileUploadInput/SimpleFileUpload';
import FormSelector from '@/component-library/widgets/FormSelector/FormSelector';
import { CreatePushServiceDetails } from '@/models/PushNotificationDetails';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { NotificationServiceEnum } from '@/modules/shared/enums/enums';
import { getFileName, readFileListFileAsBase64 } from '@/utils/fileUtils';
import { AppRoutes } from '@/utils/routes/router';

const EnableAndroidPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const workspace = useAppSelector(selectCurrentWorkspace);
  const createPushConfigMutation = useCreatePushNotificationConfig();
  const [showFileUpload, setShowFileUpload] = useState(true);
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const { data: submitedPushConfigData } = useGetSubmitedConfigs();

  const availableConfigs = submitedPushConfigData || [];

  const hasFirebaseService = availableConfigs.some((item) => item.service === NotificationServiceEnum.FIREBASE);

  useEffect(() => {
    if (hasFirebaseService) {
      setShowFileUpload(false);
    }
    let timerBeforeNavigation: NodeJS.Timeout;
    if (toastInfo.toastType === 'success') {
      timerBeforeNavigation = setTimeout(() => navigate(AppRoutes.pushNotifications), 2000);
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, toastInfo, hasFirebaseService]);

  const handleShowForm = () => {
    setShowFileUpload(true);
  };

  const schema = useMemo(() => {
    return yup
      .object()
      .shape({
        configFile: yup.mixed().test('fileValidation', t('CommonErrors.fileRequired'), (value) => {
          return value instanceof FileList && value.length > 0;
        }),
      })
      .required();
  }, [t]);

  const onSubmit = async (data: CreatePushServiceDetails) => {
    const fileList = data.configFile;
    const configFileBase64 = await readFileListFileAsBase64(fileList);

    const config = {
      workspaceId: workspace?.id,
      service: NotificationServiceEnum.FIREBASE,
      configType: 'android',
      serverKeyFile: configFileBase64,
      filename: getFileName(data.configFile),
    };

    createPushConfigMutation.mutate(config as CreatePushServiceDetails, {
      onSuccess: () => {
        setToastInfo({ message: t('EnableIosPage.toastSuccess'), toastType: 'success' });
        setOpen(true);
      },
      onError: () => {
        setToastInfo({ message: t('EnableIosPage.toastError'), toastType: 'error' });
        setOpen(true);
      },
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreatePushServiceDetails>({
    resolver: yupResolver(schema),
  });

  return (
    <>
      <div className="flex flex-col items-center mt-5">
        <form className="space-y-6 w-[40rem]" onSubmit={handleSubmit(onSubmit)}>
          <div className="relative mb-8">
            <h1 className="text-white font-medium text-xl mb-2">{t('EnableAndroidPage.title')}</h1>
          </div>
          <section>
            <h2 className="text-xs font-semibold text-yellow-500 mb-4 uppercase">
              {t('EnableAndroidPage.serviceLabel')}
            </h2>
            <div className="flex space-x-6">
              <FormSelector text="Firebase Cloud Messaging" image="/images/fcm.svg" isSelected={true} type="fcm" />
            </div>
          </section>

          {hasFirebaseService && !showFileUpload && (
            <section className="px-6 pt-6 pb-8 space-y-4 shadow rounded-lg bg-darkBlue text-custom-aliceBlue">
              <p className="text-custom-aliceBlue">Looks like you have Firebase already configured.</p>

              {availableConfigs
                .filter((item) => item.service === NotificationServiceEnum.FIREBASE)
                .map((config, index) => (
                  <p className="text-custom-aliceBlue" key={index}>
                    You are using configuration file - {config.filename}
                  </p>
                ))}
              <Button onClick={handleShowForm} size="small">
                Upload new file
              </Button>
            </section>
          )}

          {showFileUpload && (
            <div>
              <div className="mb-4">
                <h2 className="text-xs font-semibold text-yellow-500 uppercase">
                  {t('EnableAndroidPage.importFileLabel')}
                </h2>
                <span className="text-sm text-gray-100 font-medium">
                  {t('EnableAndroidPage.ImportFileDescription')}
                </span>
              </div>
              <SimpleFileUpload
                fileUploadLabel={t('EnableAndroidPage.fileUploadLabel')}
                accept=".json"
                error={errors.configFile?.message}
                {...register('configFile')}
              />
            </div>
          )}

          {showFileUpload && (
            <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
              <div className="flex items-center justify-between 3xl:min-w-[40rem] lg:min-w-[40rem]">
                <Button variant="cancel" onClick={() => navigate(AppRoutes.pushNotifications)}>
                  {t('EnableAndroidPage.cancelBtn')}
                </Button>
                <Button
                  className="px-4 !w-52"
                  isLoading={createPushConfigMutation.isLoading || createPushConfigMutation.isSuccess}
                >
                  {t('EnableAndroidPage.submitBtn')}
                </Button>
              </div>
            </div>
          )}
        </form>
        <ProvideToasts>
          <Toast
            isOpen={open}
            setOpen={setOpen}
            message={toastInfo.message}
            toastType={toastInfo.toastType as ToastType}
          />
        </ProvideToasts>
      </div>
    </>
  );
};

export default EnableAndroidPage;
