import { useMutation } from 'react-query';
import axios from 'axios';

import { PersonalAccountRequest } from '@/models/CompanyAccountDetails';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function updatePersonalData(data: PersonalAccountRequest, token: string | undefined) {
  const response = await axios.post<PersonalAccountRequest>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/settings/user/update-properties`,
    data,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useUpdatePersonalData() {
  const { user } = useAuth();

  return useMutation<PersonalAccountRequest, Error, PersonalAccountRequest>((data) =>
    updatePersonalData(data, user?.signInUserSession.idToken.jwtToken),
  );
}
