import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import parse from 'html-react-parser';
import * as yup from 'yup';

import { useCreateTemplate } from '@/api/templates/useCreateTemplate';
import { Button } from '@/component-library/primitives/Button/Button';
import { ProvideToasts, ToastType } from '@/component-library/primitives/Toast/Toast';
import { Toast } from '@/component-library/primitives/Toast/Toast';
import HtmlEditor from '@/component-library/widgets/HtmlEditor/HtmlEditor';
import { Input } from '@/component-library/widgets/Input/Input';
import { CreateTemplateDetails } from '@/models/TemplateDetails';
import { AppRoutes } from '@/utils/routes/router';

const CreateTemplate = () => {
  const createTemplateMutation = useCreateTemplate();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const { t } = useTranslation();
  const [htmlContent, setHtmlContent] = useState('<!DOCTYPE html>');

  useEffect(() => {
    let timerBeforeNavigation: NodeJS.Timeout;
    if (toastInfo.toastType === 'success') {
      timerBeforeNavigation = setTimeout(() => navigate(AppRoutes.templates), 2000);
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, toastInfo]);

  const schema = useMemo(() => {
    return yup
      .object()
      .shape({
        name: yup.string().trim().required(t('CommonErrors.nameRequired')),
      })
      .required();
  }, [t]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateTemplateDetails>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: CreateTemplateDetails) => {
    const sendData = {
      name: data.name,
      content: htmlContent,
    };
    createTemplateMutation.mutate(sendData as CreateTemplateDetails, {
      onSuccess: () => {
        // TODO: handle success here, like showing a success message
        setToastInfo({ message: t('CreateTemplatePage.toastSuccess'), toastType: 'success' });
        setOpen(true);
      },
      onError: () => {
        // TODO: handle error here, like showing an error message
        setToastInfo({ message: t('CreateTemplatePage.toastError'), toastType: 'error' });
        setOpen(true);
      },
    });
  };

  const handleHTMLChange = (newHtml: string | undefined) => {
    setHtmlContent(newHtml as string);
  };

  return (
    <>
      <div className="flex flex-col items-center mt-5 mb-[6.25rem]">
        <div className="flex flex-col items-center">
          <div className="mb-8 w-[40rem]">
            <h1 className="text-white font-medium text-xl mb-2">{t('CreateTemplatePage.title')}</h1>
            <p className="text-gray-300 text-sm">{t('CreateTemplatePage.subtitle')}</p>
          </div>
          <form className="min-w-[40rem] w-1/2" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-6">
              <Input
                label={t('CreateTemplatePage.nameLabel')}
                placeholder={t('CreateTemplatePage.namePlaceholder')}
                {...register('name')}
                error={errors.name?.message as string}
              />
              <div className="space-y-6 w-[170%] 3xl:-ml-[28%] lg:-ml-[13%]">
                <div className="flex flex-row gap-x-2 mt-2">
                  <div className="3xl:w-[31.063rem] lg:w-[25rem] 3xl:h-[39.5rem] lg:h-[32rem] flex flex-col">
                    <HtmlEditor handleHTMLChange={handleHTMLChange} htmlContentInit={htmlContent} />
                  </div>

                  <div className="3xl:w-[31.063rem] lg:w-[25rem] 3xl:h-[39.5rem] lg:h-[32rem] flex flex-col">
                    <div className="h-full overflow-auto bg-darkBlue rounded-lg p-4 flex flex-col items-center [&::-webkit-scrollbar]:h-1 [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-track]:bg-gray-200 [&::-webkit-scrollbar-thumb]:bg-gray-600">
                      <div className="w-full min-w-fit mt-6 3xl:scale-x-[0.75] lg:scale-x-[0.61] scale-y-[1] editor-preview text-gray-300">
                        {parse(htmlContent)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
              <div className="flex items-center justify-between 3xl:min-w-[40rem] lg:min-w-[35rem]">
                <Button variant="cancel" onClick={() => navigate(AppRoutes.templates)}>
                  {t('CreateTemplatePage.linkCancel')}
                </Button>
                <Button
                  className="px-4 !w-52"
                  isLoading={createTemplateMutation.isLoading || createTemplateMutation.isSuccess}
                >
                  {t('CreateTemplatePage.buttonCreateATemplate')}
                </Button>
              </div>
            </div>
          </form>
        </div>
        <ProvideToasts>
          <Toast
            isOpen={open}
            setOpen={setOpen}
            message={toastInfo.message}
            toastType={toastInfo.toastType as ToastType}
          />
        </ProvideToasts>
      </div>
    </>
  );
};

export default CreateTemplate;
