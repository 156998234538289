import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGetAPICredentials } from '@/api/APICredentials/useGetAPICredentials';
import { Button } from '@/component-library/primitives/Button/Button';
import Loader from '@/component-library/primitives/Loader/Loader';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import NoDataBoard from '@/component-library/widgets/NoDataBoard/NoDataBoard';
import SearchField from '@/component-library/widgets/SearchField/SearchField';
import Table from '@/component-library/widgets/Table/Table';
import { HttpMethodsEnum, PermissionsEnum } from '@/modules/shared/enums/enums';
import usePermissionChecker from '@/modules/shared/hooks/usePermissionChecker';
import { AppRoutes } from '@/utils/routes/router';

import { useAPICredentialsTable } from './useAPICredentialsTable';

const APICredentials = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useGetAPICredentials();
  const { columns } = useAPICredentialsTable();
  const navigate = useNavigate();

  const isActionAllowed = usePermissionChecker(PermissionsEnum.API_CREDENTIALS, HttpMethodsEnum.POST);

  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });

  const handleNavigate = (route: string, isActionAllowed: boolean) => {
    if (!isActionAllowed) {
      setToastInfo({ message: t('CommonErrors.actionNotAllowed'), toastType: 'error' });
      setOpen(true);
      return;
    }
    navigate(route);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row items-center mb-4">
        <div className="flex-grow justify-start">
          <SearchField />
        </div>
        {data && data.length > 0 && (
          <div className="justify-end">
            <Button
              className="!w-[10.5rem]"
              onClick={() => handleNavigate(AppRoutes.createApiCredentials, isActionAllowed)}
            >
              {t('APICredentialsPage.buttonNewAPICredentials')}
            </Button>
          </div>
        )}
      </div>
      {isLoading ? (
        <Loader />
      ) : data && data.length > 0 ? (
        <Table
          columns={columns}
          data={data ?? []}
          className={{
            row: 'h-[3.300rem]',
            cell: 'overflow-visible',
          }}
        />
      ) : (
        <NoDataBoard
          description={t('NoDataBoard.addYourFirstAPICredentials')}
          actionButton={
            <Button
              className="!w-[10.5rem] mt-4"
              onClick={() => handleNavigate(AppRoutes.createApiCredentials, isActionAllowed)}
            >
              {t('APICredentialsPage.buttonNewAPICredentials')}
            </Button>
          }
        />
      )}
      <ProvideToasts>
        <Toast
          isOpen={open}
          setOpen={setOpen}
          message={toastInfo.message}
          toastType={toastInfo.toastType as ToastType}
        />
      </ProvideToasts>
    </div>
  );
};

export default APICredentials;
