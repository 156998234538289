import { SVGProps } from 'react';

interface DialogBubbleIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
  width?: string;
  height?: string;
}

const DialogBubbleIcon: React.FC<DialogBubbleIconProps> = ({
  color = '#065F46',
  width = '42',
  height = '42',
  ...props
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 42 42" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 36.96C31.2062 36.96 39.48 28.6862 39.48 18.48C39.48 8.27378 31.2062 0 21 0C10.7938 0 2.52002 8.27378 2.52002 18.48C2.52002 24.2268 5.14317 29.3609 9.25744 32.7503C9.264 32.7557 9.25816 32.7662 9.25012 32.7635C9.24518 32.7618 9.24003 32.7654 9.24003 32.7707V37.0361C9.24003 39.3127 11.6397 40.7904 13.6726 39.7656L18.3627 37.4014C19.0261 37.0669 19.7694 36.9344 20.5121 36.9537C20.6742 36.9579 20.8369 36.96 21 36.96Z"
        fill={color}
      />
    </svg>
  );
};

export default DialogBubbleIcon;
