import { useMutation } from 'react-query';
import axios from 'axios';

import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function disableTeamMember(teamMemberEmail: string, token: string | undefined) {
  const body = {
    userEmail: teamMemberEmail,
  };
  const response = await axios.post<string>(`${AwsConfigApiGateway.pooledTenantApiUrl}/team-members/disable`, body, {
    headers: {
      'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
}

export function useDisableTeamMember() {
  const { user } = useAuth();
  return useMutation<string, Error, string>((data) =>
    disableTeamMember(data, user?.signInUserSession.idToken.jwtToken),
  );
}
