export const colors = [
  '#9BEBC6',
  '#AEC1FD',
  '#FFBEC0',
  '#B8ABF9',
  '#F5F069',
  '#ffb6c1',
  '#aec6cf',
  '#98fb98',
  '#b19cd9',
  '#ffdab9',
  '#e6e6fa',
  '#98ff98',
  '#f08080',
  '#c8a2c8',
  '#afeeee',
  '#fa8072',
  '#87ceeb',
  '#ffc0cb',
  '#ffb347',
  '#7fffd4',
  '#cdb0e8',
  '#c8385a',
  '#aad5db',
  '#fdd5b1',
  '#ffd700',
  '#b7b35c',
  '#e0b0ff',
  '#ffcc80',
  '#4b0082',
  '#eae0c8',
  '#ffb6d1',
  '#add8e6',
  '#ffec8b',
  '#98fb98',
  '#f08080',
];
