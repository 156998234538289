import React from 'react';

const DashboardSection = ({
  title,
  titleCentered = true,
  contentCentered = false,
  contentEnd = true,
  titleClassName,
  children,
}: {
  title: string | React.ReactNode;
  titleCentered?: boolean;
  contentCentered?: boolean;
  contentEnd?: boolean;
  titleClassName?: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col items-center px-4 pt-4 pb-4 rounded-[10px] bg-darkBlue text-custom-aliceBlue h-[100%]">
      <h3
        className={`text-white font-medium text-lg ${
          titleCentered ? 'text-center' : 'text-start w-[100%]'
        } ${titleClassName}`}
      >
        <div className="max-w-[20rem]">{title}</div>
      </h3>
      <div
        className={`flex justify-center ${contentCentered ? 'items-center' : 'items-start'} ${
          contentEnd ? 'items-end' : ''
        } h-[100%] w-[100%]`}
      >
        {children}
      </div>
    </div>
  );
};

export default DashboardSection;
