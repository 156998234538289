import React from 'react';
import { useTranslation } from 'react-i18next';

import BatteryIcon from '../Icons/BatteryIcon/BatteryIcon';
import DialogBubbleIcon from '../Icons/DialogBubbleicon/DialogBubbleIcon';
import InformationIcon from '../Icons/InformationIcon/InformationIcon';
import NetworkSignalIcon from '../Icons/NetworkSignalIcon/NetworkSignalIcon';
import WifiSignalIcon from '../Icons/WifiSignalIcon/WifiSignalIcon';

interface PhoneDeviceProps {
  isPushNotification?: boolean;
  children?: any;
}
const PhoneDevice: React.FC<PhoneDeviceProps> = ({ isPushNotification = false, children }) => {
  const { t } = useTranslation();
  return (
    <div className="relative flex items-start">
      <img src="/images/iPhone.svg" alt="iPhone" className="w-[22.668rem] h-[42rem]" />

      {isPushNotification && (
        <>
          <div className="absolute w-full top-14 right-12 flex flex-row justify-end gap-x-1 items-center">
            <NetworkSignalIcon />
            <WifiSignalIcon />
            <BatteryIcon />
          </div>
          <div className="absolute w-full top-28 flex flex-row justify-center">
            <p className="font-black text-[72px] text-custom-darkBlue font-['Lato']">11:11</p>
          </div>
        </>
      )}
      {children}
      <div className="absolute bottom-12 left-[3.8rem] flex flex-row items-center gap-x-2">
        <div className="relative">
          <DialogBubbleIcon color="#564EE7" width="29" height="29" />
          <div className="absolute top-1 left-[7px]">
            <InformationIcon />
          </div>
        </div>
        <span className="font-normal text-gray-200 text-sm leading-5 tracking-[0.02em]">
          {t('NewSMSPage.youArePreviewingContentLabel')}
        </span>
      </div>
    </div>
  );
};

export default PhoneDevice;
